<template>
    <div class="vue-json-editor" :style="{height: height||'400px'}" style="position: relative;">
        <div v-if="mode==='preview'"
             style="position: absolute;width: 100%;height: 100%;background-color: var(--el-border-color-extra-light);top:0">
            <p class="color-info" style="font-size: 2.5em;text-align: center;margin: 0"
               :style="{lineHeight: height||'400px'}">{{ disableLabel || '不可编辑' }}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "JsonInput",
    props: {
        mode: String,
        modelValue: Object,
        height: String,
        disableLabel: String,
    },
    data() {
        return {
            json: this.modelValue,
            internalChange: false,
            error: false,
            editor: null,
        }
    },
    methods: {
        init() {
            const self = this;
            const options = {
                mode: self.mode || 'tree',
                onChange() {
                    try {
                        const json = self.editor.get();
                        self.error = false;
                        self.json = json;
                        self.internalChange = true;
                        self.$emit('update:modelValue', json);
                        self.$emit('change', json);
                        self.$emit('status', true);
                        self.$nextTick(_ => {
                            self.internalChange = false;
                        })
                    } catch (e) {
                        self.$emit('status', false);
                        self.error = true;
                    }
                },
                statusBar: false,
            };
            this.editor = new JSONEditor(this.$el, options, this.json);
        },
        refresh() {
            this.editor.set(this.modelValue);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        modelValue(val) {
            if (!this.internalChange) {
                this.editor.set(val);
            }
        },
        mode(val) {
            this.editor.setMode(this.mode);
        }
    }
}
</script>

<style scoped>

</style>

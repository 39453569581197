<template>
    <div>
        <el-table :data="data" v-loading="loading" style="margin-top: 10px">
            <el-table-column label="事件名" width="160" prop="name"></el-table-column>
            <el-table-column label="参数" width="200" prop="params">
                <template #default="scope">
                    <div v-for="p in scope.row.params">{{ p.key }} [{{ p.value_type }}]</div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="120" prop="status">
                <template #default="scope">
                    <el-select v-model="scope.row.status" :disabled="loading" @change="update(scope.row)">
                        <el-option v-for="(v, k) in event_status_enum" :value="parseInt(k)" :label="v"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="启用版本" width="100" prop="version"></el-table-column>
            <el-table-column label="事件描述" width="300" prop="desc"></el-table-column>
            <el-table-column label="触发时机" width="200" prop="trigger"></el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="事件名" prop="name" :rules="[{required: true, message: '请输入事件名'}]">
                <el-input v-model="form.name" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="事件参数" prop="params">
                <div style="width: 100%">
                    <div v-for="param in form.params" style="margin-bottom: 5px;position: relative">
                        <el-select v-model="param.id" :disabled="loading" style="width: 150px"
                                   @change="changeParam(param)">
                            <el-option v-for="p in param_list" :value="p.id" :label="p.key">
                                <span>{{ p.key }}</span>
                                <b> [{{ p.value_type }}] </b>
                                <span style="color: var(--el-text-color-secondary);font-size: 8px">{{ p.desc }}</span>
                            </el-option>
                        </el-select>
                        <el-input v-model="param.value_type" disabled style="width: 60px"></el-input>
                        <el-input v-model="param.desc" disabled style="width: calc(100% - 210px)"></el-input>
                        <el-alert type="warning" show-icon :closable="false" v-if="param.changed">
                            参数 {{ param.key }} 近期有过修改，请确认是否继续使用该参数？
                            <el-button type="success" size="small" @click="changeParam(param)">继续使用</el-button>
                            <el-button type="danger" size="small" @click="removeParam(param)">删除参数</el-button>
                        </el-alert>
                        <el-icon size="20px" @click="removeParam(param)"
                                 style="position: absolute;top: 5px;right: 5px;color: var(--el-color-danger);cursor: pointer;">
                            <CircleCloseFilled/>
                        </el-icon>
                    </div>
                    <div>
                        <el-select v-model="param" :disabled="loading" filterable @change="addParam"
                                   style="width: 150px">
                            <el-option v-for="p in param_list" :value="p" :label="p.key" value-key="id">
                                <span>{{ p.key }}</span>
                                <b> [{{ p.value_type }}] </b>
                                <span style="color: var(--el-text-color-secondary);font-size: 8px">{{ p.desc }}</span>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="启用版本" prop="version" :rules="[{required: true, message: '请选择启用版本'}]">
                <el-select v-model="form.version" :disabled="loading" filterable allow-create>
                    <el-option v-for="v in version_list" :value="v.version"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="事件描述" prop="desc" :rules="[{required: true, message: '请输入事件描述信息'}]">
                <el-input v-model="form.desc" :disabled="loading" type="textarea" :rows="3"></el-input>
            </el-form-item>
            <el-form-item label="触发时机" prop="trigger" :rules="[{required: true, message: '请输入事件触发时机'}]">
                <el-input v-model="form.trigger" :disabled="loading" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {deepcopy, update} from "../../../libs/utils";

export default {
    name: "Event",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, param_list: [], version_list: [],
            event_status_enum: {0: '开发中', 1: '已上线', 2: '已下线'}, param: null,
            form: {
                product_id: '',
                name: '',
                params: [],
                version: '',
                desc: '',
                trigger: '',
                status: null,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/api/v1/event`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.data = res.data.data.eventList;
                }),
                axios.get(`/api/v1/param`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.param_list = res.data.data.paramList;
                }),
                axios.get(`/api/v1/pm/version`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.version_list = res.data.data.versionList;
                })
            ]).then(_ => {
                this.data.forEach(d => {
                    d.params.forEach(param => {
                        param.changed = false;
                        this.param_list.forEach(p => {
                            if (p.id === param.id) {
                                if (p.key !== param.key || p.desc !== param.desc || p.value_type !== param.value_type || JSON.stringify(p.enums) !== JSON.stringify(param.enums)) {
                                    param.changed = true;
                                }
                            }
                        })
                    })
                })
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.params = [];
            this.dialog_opened = this.loading = false;
            this.editing = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/api/v1/event/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        this.form.product_id = this.$route.params.productId;
                        axios.post(`/api/v1/event`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        addParam() {
            if (this.param) {
                this.form.params.push(deepcopy(this.param));
                this.param = null;
            }
        },
        changeParam(param) {
            this.param_list.forEach(p => {
                if (p.id === param.id) {
                    param.value_type = p.value_type;
                    param.desc = p.desc;
                    param.key = p.key;
                    param.enums = p.enums;
                    param.changed = false;
                }
            })
        },
        removeParam(param) {
            this.form.params.splice(this.form.params.indexOf(param), 1);
        },
        update(row) {
            this.loading = true;
            axios.put(`/api/v1/event/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init()
        }
    },
}
</script>

<style scoped>
</style>
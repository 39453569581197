<template>
    <el-switch v-model="form.is_empty" active-text="空跑实验" :disabled="loading" @change="emptyChange"></el-switch>
    <el-table v-loading="loading" :data="form.groups" style="margin-top: 10px">
        <el-table-column label="组名" prop="name" width="150"></el-table-column>
        <el-table-column label="描述" prop="desc" width="250"></el-table-column>
        <el-table-column label="操作" width="300">
            <template #default="scope">
                <el-button  @click="openDialog(scope.row)" :disabled="loading">编辑配置</el-button>
                <el-button  @click="copyDialog(scope.row)" :disabled="loading" type="success">复制
                </el-button>
                <el-button  @click="removeGroup(scope.row)" :disabled="loading" type="danger"
                           v-if="scope.$index > 1">删除
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-button type="primary"  @click="openDialog()" :disabled="form.groups.length > 61"
               style="margin-top: 10px">新建实验组
    </el-button>
    <el-dialog width="80%" v-model="dialog_opened" :before-close="resetForm" :close-on-click-modal="false">
        <el-form :model="inner_form" ref="form" label-width="100px">
            <el-form-item label="名称" prop="name" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="inner_form.name"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="desc">
                <el-input v-model="inner_form.desc" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="Json" prop="data" :rules="[{validator: validator, trigger: 'blur'}]">
                <json-input v-model="inner_form.data" :mode="form.is_empty?'preview':'code'"
                            @status="statusChangeHandle" disable-label="实验空跑，无需参数"></json-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text @click="resetForm" :loading="loading">取消</el-button>
            <el-button type="primary" :disabled="submit_disabled" @click="inner_submitForm" :loading="loading">确定
            </el-button>
        </template>
    </el-dialog>
</template>

<script>
import JsonInput from '../../../base/JsonInput';
import {update, deepcopy} from "../../../libs/utils";
import axios from 'ts-axios-new';

export default {
    name: "ExpEditorStep1",
    props: {
        form: Object,
    },
    components: {JsonInput},
    data() {
        return {
            dialog_opened: false, editing: null, params_map: [], loading: false, submit_disabled: false,
            inner_form: {
                name: '',
                desc: '',
                data: {},
            },
        }
    },
    methods: {
        statusChangeHandle($event) {
            this.submit_disabled = !$event
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.inner_form, item);
                    this.inner_form.data = item.data;
                }
            });
        },
        copyDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.inner_form, item);
                    this.inner_form.data = item.data;
                }
            });
        },
        resetForm() {
            if (this.$refs.form)
                this.$refs.form.resetFields();
            this.dialog_opened = false;
            this.editing = null;
        },
        removeGroup(group) {
            this.$confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.form.groups.splice(this.form.groups.indexOf(group));
            }).catch(_ => {
            })
        },
        inner_submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    // if (this.editing && this.editing === this.form.groups[0]) {
                    //     const isValid = [];
                    //     const layers = [];
                    //     for (let k in this.form.user_layers) {
                    //         if (this.form.user_layers[k].length) {
                    //             this.form.user_layers[k].forEach(u => {
                    //                 layers.push(u);
                    //             })
                    //         }
                    //     }
                    //     for (let key in this.inner_form.data) {
                    //         this.params_map.forEach(p => {
                    //             const p_layers = [];
                    //             for (let k in p.boundary.user_layers) {
                    //                 if (p.boundary.user_layers[k].length) {
                    //                     p.boundary.user_layers[k].forEach(u => {
                    //                         p_layers.push(u);
                    //                     })
                    //                 }
                    //             }
                    //             if (p.event !== 'exp_published' && JSON.stringify(p.boundary.country.sort()) === JSON.stringify(this.form.country.sort()) && JSON.stringify(layers.sort()) === JSON.stringify(p_layers.sort()) && key === p.key && p.value[0].value !== this.inner_form.data[key]) {
                    //                 isValid.push({default_value: p.value[0].value, key: key})
                    //             }
                    //         })
                    //     }
                    //     if (isValid.length) {
                    //         let title = '';
                    //         isValid.forEach(i => {
                    //             title += '<p><b>' + i.key + '</b>: ' + i.default_value + '</p>'
                    //         });
                    //         this.$alert('<p>以下key在本域中有默认值：</p>' + title + '<p>在对照组中，这些key的值需与默认值保持一致！</p>', '创建失败', {
                    //             type: 'error',
                    //             dangerouslyUseHTMLString: true,
                    //         });
                    //         return
                    //     }
                    // }
                    this.loading = true;
                    axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/params/check', {
                        data: this.inner_form.data,
                        product_id: this.form.product_id,
                        version: this.form.version,
                        boundary: {
                            country: this.form.country,
                            user_layers: this.form.user_layers,
                            device_category: this.form.device_category,
                            af_status: this.form.af_status,
                            media_source: this.form.media_source,
                            campaign_id: this.form.campaign_id,
                        },
                        exp_id: '1234',
                        index: this.form.groups.indexOf(this.editing),
                    }).then(res => {
                        this.loading = false;
                        const invalid = [];
                        for (const key in res.data.data) {
                            if (!res.data.data[key]) {
                                invalid.push(key);
                            }
                        }
                        if (!invalid.length) {
                            for (let key in this.inner_form.data) {
                                this.form.groups.forEach(g => {
                                    if (g.data[key] === undefined) {
                                        g.data[key] = deepcopy(this.inner_form.data[key]);
                                    }
                                })
                            }
                            if (this.editing) {
                                update(this.editing, this.inner_form);
                                this.editing.data = this.inner_form.data;
                                this.resetForm();
                            } else {
                                this.form.groups.push(deepcopy(this.inner_form));
                                this.resetForm();
                            }
                        } else {
                            this.$alert('<p>参数 ' + invalid.join(',') + ' 无法创建，需满足以下条件：</p><p>1、同域下不可以有正在做实验的参数；</p><p>2、若参数已存在则数据类型需要保持一致</p>', '创建失败', {
                                type: 'error',
                                dangerouslyUseHTMLString: true,
                            })
                        }
                    });
                }
            })
        },
        init() {
            this.loading = true;
            axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/params', {params: {product_id: this.$route.params.productId}}).then(res => {
                update(this.params_map, res.data.data.paramsList);
                this.loading = false;
            })
        },
        validator(rule, value, callback) {
            if (this.form.is_empty) {
                callback()
            } else if (typeof value === 'object' && !Array.isArray(value)) {
                if (JSON.stringify(value) !== '{}') {
                    callback()
                } else {
                    callback('请至少输入一个参数')
                }
            } else {
                callback('Json不合法')
            }
        },
        submitForm(callback) {
            if (this.form.is_empty) {
                callback();
                return
            }
            if (JSON.stringify(this.form.groups[0].data) === '{}') {
                this.$alert('对照组配置不能为空！');
            } else {
                let valid = true;
                this.form.groups.forEach(g => {
                    for (let key in g.data) {
                        this.form.groups.forEach(gg => {
                            if (gg.data[key] === undefined) {
                                valid = false;
                            }
                        })
                    }
                });
                if (valid) {
                    callback();
                } else {
                    this.$alert('各实验组参数不一致！');
                }
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <el-form :model="filters" inline ref="form">
            <el-form-item label="对比版本" prop="versions">
                <app-version-picker v-model="filters.versions" :disabled="loading" :current="modelValue.version"
                                     :limit="4" width="250px"></app-version-picker>
            </el-form-item>
            <el-form-item label="国家" prop="country">
                <country-selector v-model="filters.country" :disabled="loading"  wanted="ip_name"
                                  width="200px"></country-selector>
            </el-form-item>
            <el-button type="primary"  :loading="loading" style="margin-top: 6px" @click="batchFetch()">
                更新
            </el-button>
        </el-form>
    </div>
    <el-row v-if="metric_list.length">
        <el-col :xs="24" :sm="24" :lg="12" v-for="(mid, mi) in modelValue.metrics" :key="mid">
            <el-card shadow="never" style="margin: 2px; position: relative" v-loading="loading"
                     body-style="padding: 10px">
                <el-icon @click="deleteMetric(mi)" style="cursor: pointer; position: absolute; right: 10px;top: 5px;">
                    <CloseBold/>
                </el-icon>
                <VersionMetricChart :start="date_range[0]" :end="date_range[1]" :metric="metricMap[mid]"
                                    :versions="versions" :countries="filters.country" :ref="mid">
                </VersionMetricChart>
            </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :lg="12">
            <el-card shadow="never" v-loading="loading"
                     style="text-align: center; margin: 2px; position: relative"
                     body-style="padding: 10px; height: 270px">
                <el-icon @click="$refs.metric.openDialog()" style="cursor:pointer; margin-top: 128px"
                         v-loading="loading"><Plus/></el-icon>
            </el-card>
        </el-col>
    </el-row>
    <ExpReportMetric ref="metric" v-model="form.metrics" :metrics="metric_list" :need_dau="true"
                     @change="updateMetrics"></ExpReportMetric>
    <metric-editor ref="editor" @add="afterCreateMetric"></metric-editor>
</template>

<script>
import AppVersionPicker from "../../../base/AppVersionPicker";
import ExpReportMetric from "../abtest/ExpReportMetric";
import VersionMetricChart from "./VersionMetricChart";
import NoDataChart from "./NoDataChart";
import CountrySelector from "../../../base/CountrySelector";
import axios from 'ts-axios-new';
import {timestampToDate, generateDateRange} from "../../../libs/utils";
import MetricEditor from "../metric/MetricEditor";

export default {
    name: "VersionTrendPanel",
    props: {
        modelValue: Object,
        metric_list: Array,
    },
    components: {
        AppVersionPicker,
        MetricEditor,
        CountrySelector,
        VersionMetricChart,
        NoDataChart,
        ExpReportMetric
    },
    data() {
        let metricMap = {};
        this.metric_list.forEach(m => {
            metricMap[m.id] = m;
        });
        return {
            data: [], loading: false, headers: [], xAxis: [],
            metricMap: metricMap, form: {metrics: this.modelValue.metrics},
            tableMetrics: [
                {value: 'date', label: '日期', width: '150px', sortable: true},
                {value: 'app_version', label: '版本', width: '120px', sortable: true},
            ],
            filters: {
                country: [], versions: [`< ${this.modelValue.version}`],
                // start 和 end 默认是发版后的两周内
            }
        }
    },
    methods: {
        initAxis() {
            const now = new Date();
            this.xAxis = generateDateRange(
                timestampToDate(this.modelValue.c_time),
                timestampToDate(Math.min(
                    parseInt(now / 1000 - this.$root.t * 24 * 3600),
                    this.modelValue.c_time + 14 * 3600 * 24)
                )
            );
        },
        deleteMetric(ind) {
            if (this.form.metrics.length < 2) {
                this.$message.warning("再删指标就没了!");
                return;
            }
            this.$confirm(`确定要删除指标(${this.metricMap[this.form.metrics[ind]].name})吗？`, '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.form.metrics.splice(ind, 1);
                const version_api = `/api/v1/pm/version/${this.$route.params.versionId}`;
                axios.put(version_api, this.form).then(resp => {
                    this.modelValue.metrics = resp.data.data.metrics;
                }).catch(err => {
                    console.log(err);
                })
            }).catch(_ => {
            })
        },
        afterCreateMetric(newMetric) {
            this.form.metrics.push(newMetric.id);
            this.metricMap[newMetric.id] = newMetric;
            this.metric_list.push(newMetric);
            this.$nextTick(_ => {
                this.$refs[newMetric.id].fetchData();
            });
        },
        updateMetrics() {
            const version_api = `/api/v1/pm/version/${this.$route.params.versionId}`;
            if (this.form.metrics.length === 0) {
                this.$message.warning("指标不能为空");
                return
            }
            axios.put(version_api, this.form).then(resp => {
                this.modelValue.metrics = resp.data.data.metrics;
                // this.fetchReport();
            }).catch(err => {
                console.log(err);
            })
        },
        batchFetch() {
            this.modelValue.metrics.forEach(mid => {
                this.$refs[mid].fetchData();
            })
        },
    },
    mounted() {
        this.initAxis();
        this.metric_list.forEach(m => {
            this.metricMap[m.id] = m;
        });
        this.batchFetch();
    },
    computed: {
        versions() {
            const versions = [this.modelValue.version];
            this.filters.versions.forEach(v => {
                if (!versions.includes(v)) {
                    versions.push(v);
                }
            });
            return versions;
        },
        date_range() {
            return [
                timestampToDate(this.modelValue.c_time),
                timestampToDate(this.modelValue.c_time + 14 * 3600 * 24)
            ];
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div>
        <h4 style="margin: 5px">{{data.name}}</h4>
        <el-divider></el-divider>
        <el-row style="margin-top: 5px">
            <b>类型:</b> {{metricMap[data.category] ? metricMap[data.category] : data.category}}
            <span v-if="['af_retain', 'retain'].includes(data.category)">
                {{data.interval}}留
            </span>
        </el-row>
        <el-row v-for="(e, i) in data.events" :key="i" style="margin-top: 5px">
            <b>事件{{['A', 'B'][i]}}:</b> {{e.name}}
            <el-row v-for="item in e.items" style="margin-left: 40px" :key="item.key">
                <p style="margin: 5px"><b>key:</b> {{item.key}}</p>
                <p v-if="item.value" style="margin: 5px"><b>value:</b> {{item.value}}</p>
            </el-row>
        </el-row>
        <el-row style="margin-top: 5px">
            <b>描述:</b> {{data.description}}
        </el-row>
    </div>
</template>

<script>
import {MetricCategoryList} from './data';

export default {
    props: {data: Object},
    name: "DWMetricPreviewer",
    data() {
        let metricMap = {};
        MetricCategoryList.forEach(m => {
            metricMap[m.value] = m.name;
        })
        return {
            metricMap: metricMap,
        }
    },
};
</script>

<style scoped>
.el-divider.el-divider--horizontal {
    margin: 0;
}
</style>
<template>
    <div style="display: flex;justify-content: space-between;margin: 20px">
        <div>
            <el-button type="primary" :disabled="loading" @click="openDialog()">新建实验</el-button>
            <el-button type="success" :disabled="loading">测试请求</el-button>
        </div>
        <el-switch v-model="domain" inactive-text="测试环境" active-text="正式环境" inactive-value="elastic-test"
                   active-value="elastic" @change="$router.push({query: {domain}})"></el-switch>
    </div>
    <el-table :data="data" v-loading="loading">
        <el-table-column label="实验名称" width="150" prop="name"></el-table-column>
        <el-table-column label="启用" width="100" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="最小版本" width="100" prop="app_version"></el-table-column>
        <el-table-column label="国家" width="150" prop="country" show-overflow-tooltip>
            <template #default="scope">
                {{ scope.row.country.join('、') || '全球' }}
            </template>
        </el-table-column>
        <el-table-column label="流量" width="100" prop="percent">
            <template #default="scope">{{ scope.row.percent }}%</template>
        </el-table-column>
        <el-table-column label="实验组" width="100" prop="data">
            <template #default="scope">
                {{ scope.row.data.length }}
            </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button type="success" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened">
        <el-form :model="form" ref="form" label-width="100px">
            <div v-show="step===0">
                <el-form-item label="实验名称" prop="name" :rules="[{required: true, message: '请输入实验名称'}]">
                    <el-input v-model="form.name" :disabled="loading"></el-input>
                </el-form-item>
                <el-form-item label="流量占比" prop="percent">
                    <el-slider :disabled="loading" v-model="form.percent" :max="100" :min="1"
                               :marks="marks"></el-slider>
                </el-form-item>
                <el-form-item label="最小版本" prop="app_version"
                              :rules="[{required: true, message: '请选择最小版本'}, {validator: versionValidator}]">
                    <el-select v-model="form.app_version" filterable allow-create style="width: 300px">
                        <el-option :disabled="loading" v-for="v in version_list" :key="v.id"
                                   :value="v.version"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="生命周期" prop="living_days"
                              :rules="[{required: true, message: '请输入最小生命周期'}, {type: 'number', message: '必须是自然数'}]">
                    <el-input-number :disabled="loading" v-model="form.living_days" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="国家" prop="country">
                    <country-selector v-model="form.country"></country-selector>
                </el-form-item>
            </div>
            <div v-show="step===1">
                <el-table :data="form.data">
                    <el-table-column label="实验组" width="100">
                        <template #default="scope">
                            {{ scope.$index ? `实验组${scope.$index}` : '对照组' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="包含广告组" width="100">
                        <template #default="scope">
                            {{ scope.row._count_groups }}个
                        </template>
                    </el-table-column>
                    <el-table-column label="包含广告位" width="100">
                        <template #default="scope">
                            {{ scope.row._count_placement }}个
                        </template>
                    </el-table-column>
                    <el-table-column label="包含聚合平台" width="120">
                        <template #default="scope">
                            {{ scope.row._count_mediation }}个
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                        <template #default="scope">
                            <el-button size="small" type="success" @click="$refs.expGroup.edit(scope.row)"
                                       :disabled="loading">编辑
                            </el-button>
                            <el-button size="small" type="danger" @click="removeExpGroup(scope.row)"
                                       :disabled="loading">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button type="success" @click="addExpGroup" :disabled="loading">添加实验组</el-button>
            </div>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="next" v-if="step===0">下一步</el-button>
            <el-button type="primary" :loading="loading" @click="submit" v-else-if="step===1">确定</el-button>
        </template>
    </el-dialog>
    <sdk-exp-group ref="expGroup"></sdk-exp-group>
</template>

<script>
import axios from 'ts-axios-new';
import {update, versionValidator} from "../../libs/utils";
import CountrySelector from "../../base/CountrySelector";
import SdkExpGroup from "./SdkExpGroup";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Sdk",
    components: {SdkExpGroup, CountrySelector},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null, version_list: [], step: 0,
            marks: {10: '10%', 30: '30%', 50: '50%', 70: '70%', 90: '90%'},
            domain: this.$route.query.domain || 'elastic-test', mediation_list: ['Max'],
            form: {
                product_id: this.$route.params.productId,
                name: '',
                percent: 100,
                app_version: '',
                living_days: 0,
                country: [],
                data: [],
                active: false,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`${this.$root.$getElasticDomain()}/cms/v1/mediation`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.data = res.data.data.mediationList;
                    this.loading = false;
                }), axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                    update(this.version_list, res.data.data.versionList.filter(row => row['version'] && row['version'].length > 1));
                }),
            ])
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.step = 0;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.$refs.form.resetFields();
            this.form.data = [];
            this.form.country = [];
            this.step = 0;
        },
        versionValidator(rules, value, callback) {
            return versionValidator(rules, value, callback)
        },
        next() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.step = 1;
                }
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`${this.$root.$getElasticDomain()}/cms/v1/mediation/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`${this.$root.$getElasticDomain()}/cms/v1/mediation`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`${this.$root.$getElasticDomain()}/cms/v1/mediation/${row.id}`, row).then(_ => {
                this.loading = false;
            })
        },
        addExpGroup() {
            const exp = {
                mediations: [], groups: [],
                _count_placement: 0, _count_mediation: 0, _count_groups: 0,
            };
            this.form.data.push(exp);
            this.$refs.expGroup.edit(exp);
        },
        removeExpGroup(row) {
            ElMessageBox.confirm('确定要删除该实验组吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(_ => {
                this.form.data.splice(this.form.data.indexOf(row), 1);
            }).catch(_ => {
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该配置吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'error',
            }).then(_ => {
                this.loading = true;
                axios.delete(`${this.$root.$getElasticDomain()}/cms/v1/mediation/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
    },
    mounted() {
        if (this.$route.query.domain) {
            this.init();
        } else {
            this.$router.push({query: {domain: 'elastic-test'}});
        }
    },
    watch: {
        $route() {
            if (this.$route.name === 'IAASdk' && this.$route.query.domain) {
                this.domain = this.$route.query.domain;
                this.form.product_id = this.$route.params.productId;
                this.init();
            }
        },
    }
}
</script>

<style scoped>

</style>
<template>
    <el-dialog v-model="dialog_opened" title="发布新版本" width="300px">
        <el-form ref="form" :model="versionForm">
            <el-form-item prop="version" label="版本号"
                          :rules="[{required: true, message: '请输入版本号'}, {validator: versionValidator}]">
                <el-input v-model="versionForm.version"  style="width: 120px;"
                          :disabled="editing"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="resetForm" type="primary" text>取消</el-button>
            <el-button type="primary" @click="submitForm" >继续</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {versionValidator} from "../../../libs/utils";

export default {
    name: "VersionEditor",
    data() {
        return {
            saving: false, editing: false, dialog_opened: false,
            versionForm: {
                product_id: "",
                version: "",
            },
        }
    },
    methods: {
        openDialog() {
            this.dialog_opened = true;
        },
        resetForm() {
            this.dialog_opened = false;
            this.saving = false;
            this.editing = false;
            this.versionForm = {
                product_id: "",
                percent: 0,
                version: "",
            }
        },
        submitForm() {
            this.versionForm.product_id = this.$route.params.productId;
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.saving = true;
                    axios.post('/api/v1/pm/version', this.versionForm).then(res => {
                        this.$router.push({
                            name: 'ArchiveVersionPanel',
                            params: {productId: this.$route.params.productId, versionId: res.data.data.id}
                        })
                        this.resetForm();
                    }).catch(err => {
                        this.$message.error(err.response.data.status.message);
                    })
                }
            })
        },
        versionValidator(rules, value, callback) {
            return versionValidator(rules, value, callback);
        }
    }

}
</script>

<style scoped>

</style>

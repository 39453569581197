<template>
    <el-main>
        <div style="padding: 30px">
            <h3 style="=;margin: 0 10px">产品列表</h3>
            <el-row>
                <el-col :xs="24" :sm="12" :lg="8" style="padding: 10px">
                    <el-input v-model="query" prefix-icon="Search"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="{span: 8, offset: 8}" style="text-align: right;font-size: 14px">
                    <b>隐藏已下架产品</b>
                    <el-switch v-model="hide_off" active-color="var(--el-color-success)"
                               style="margin: 20px"></el-switch>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8" v-for="p in prodFilter" :key="p.id" :xs="24" :sm="12" :lg="8">
                    <el-card class="prod prod-item" :body-style="{padding: 0}" style="position: relative"
                             shadow="never">
                        <el-tooltip content="加入书签的产品将被置顶" placement="top">
                            <el-icon size="20px" style="margin: 3px;color: var(--el-color-error);position: absolute;top: 0;">
                                <Checked v-if="p.bookmark" @click="updateBookMark(p)"/>
                                <CollectionTag v-else @click="updateBookMark(p)"/>
                            </el-icon>
                        </el-tooltip>
                        <div class="prod prod-inner"
                             @click="$router.push({name: 'PMGuide', params: {productId: p.id}})">
                            <el-row>
                                <img :src="p.avatar" style="height: 50px;border-radius: 5px">
                                <el-icon style="font-size: 20px;margin-top: 20px">
                                    <Apple v-if="p.platform==='ios'"/>
                                </el-icon>
                            </el-row>
                            <h4>{{ p.name }}</h4>
                            <p>{{ p.nickname }} ({{ p.platform === 'ios' ? 'iOS' : 'Android' }})</p>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </el-main>
</template>

<script>
import {setLocalObj} from "./libs/storage";

export default {
    name: "Index",
    data() {
        return {
            query: '', hide_off: true,
        }
    },
    methods: {
        updateBookMark(p) {
            p.bookmark = !p.bookmark;
            const bookmark = [];
            this.$root.product_list.forEach(pp => {
                if (pp.bookmark) {
                    bookmark.push(pp.id);
                }
            });
            setLocalObj('bookmark', bookmark);
            this.$root.product_list.sort(function (a, b) {
                if (a.bookmark ^ b.bookmark) {
                    return a.bookmark ? -1 : 1;
                } else {
                    return a.id > b.id ? -1 : 1;
                }
            });
        }
    },
    computed: {
        prodFilter() {
            return this.$root.product_list.filter(d => {
                return (!this.query
                        || d.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
                        || d.id.indexOf(this.query.toLowerCase()) > -1
                        || (d.nickname || '').toLowerCase().indexOf(this.query.toLowerCase()) > -1)
                    && (!this.hide_off || !d.off)
            })
        }
    }
}
</script>

<style scoped>
.prod.prod-item {
    margin: 10px;
    height: 200px;
}

.prod.prod-item:hover {
    background-color: var(--el-border-color-light);
    cursor: pointer;
}

.prod ::v-deep(.el-card__body) {
    height: 100%;
}

.prod.prod-inner {
    height: calc(100% - 40px);
    padding: 20px;
}
</style>
<template>
    <div>
        <el-table :data="data" v-loading="loading" style="margin-top: 10px">
            <el-table-column label="参数名" prop="key" width="200"></el-table-column>
            <el-table-column label="描述" prop="desc" width="400"></el-table-column>
            <el-table-column label="类型" prop="type" width="100">
                <template #default="scope">
                    {{ value_type_enums[scope.row.value_type] }}
                </template>
            </el-table-column>
            <el-table-column label="枚举值" prop="enums" width="200">
                <template #default="scope">
                    <el-tag v-for="e in scope.row.enums">{{ e }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="100px" :model="form">
            <el-form-item label="参数名" prop="key" :rules="[{required: true, message: '请输入参数名'}]">
                <el-input v-model="form.key" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="desc" :rules="[{required: true, message: '请输入参数描述'}]">
                <el-input v-model="form.desc" :disabled="loading" type="textarea" :rows="5"></el-input>
            </el-form-item>
            <el-form-item label="类型" prop="value_type" :rules="[{required: true, message: '请选择类型'}]">
                <el-radio-group v-model="form.value_type" :disabled="loading" @change="form.enums=[]">
                    <el-radio v-for="(v, k) in value_type_enums" :label="k">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="枚举值" prop="enums">
                <el-tag v-for="(e, i) in form.enums" closable @close="form.enums.splice(i, 1)">{{ e }}</el-tag>
                <input-box :type="form.value_type" v-if="form.value_type" @submit="addValue"></input-box>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../../libs/utils";
import InputBox from "../../../base/InputBox";

export default {
    name: "Param",
    components: {InputBox},
    data() {
        return {
            loading: false, data: [], value_type_enums: {string: '字符串型', int: '整型', double: '浮点型'},
            dialog_opened: false, editing: null,
            form: {
                product_id: '',
                key: '',
                desc: '',
                value_type: null,
                enums: [],
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/api/v1/param', {params: {product_id: this.$route.params.productId}}).then(res => {
                this.data = res.data.data.paramList;
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.enums = [];
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/api/v1/param/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        this.form.product_id = this.$route.params.productId;
                        axios.post(`/api/v1/param`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        addValue(value) {
            this.form.enums.push(value);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init()
        }
    },
}
</script>

<style scoped>
.el-tag + .el-tag {
    margin-left: 5px;
}
</style>
<template>
    <el-dialog v-model="dialog_opened">
        <json-input v-model="data" mode="code" v-loading="loading"></json-input>
        <template #footer>
            <el-button type="primary" @click="dialog_opened=false" :loading="loading">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import JsonInput from "../../../base/JsonInput";
import axios from 'ts-axios-new';

export default {
    name: "ExpJson",
    components: {JsonInput},
    data() {
        return {
            dialog_opened: false, loading: false, data: {},
        }
    },
    methods: {
        openDialog() {
            this.dialog_opened = this.loading = true;
            axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/params/export', {params: {product_id: this.$route.params.productId}}).then(res => {
                this.data = res.data.data;
                this.loading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>

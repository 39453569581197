import axios from 'ts-axios-new'

export function initProduct(callback) {
    return axios.get('/api/v1/product').then(res => {
        if (callback) {
            callback(res);
        }
    })
}

export function initCountry(callback) {
    return axios.get('/api/v1/country').then(res => {
        if (callback) {
            callback(res);
        }
    })
}

export function initUser(callback) {
    return axios.get('/api/v1/user').then(res => {
        if (callback) {
            callback(res);
        }
    })
}

export function initUAChannel(callback) {
    return axios.get('/api/v1/ua/channel').then(res => {
        if (callback) {
            callback(res);
        }
    })
}

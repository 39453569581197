<template>
    <div v-loading="loading">
        <div>指标:
            <el-tag v-for="mid in form.stat.metrics" :key="mid" style="margin-left: 2px">{{metricMap[mid]}}</el-tag>
        </div>
        <el-card style="margin: 10px" shadow="never" v-if="!disabled">
            <template v-for="c in categoryList" :key="c.value">
                <el-row style="padding: 5px"
                        v-if="metricList.filter(m => m.category === c.value).length > 0">
                    <el-col :span="2"><p style="margin: 0">{{c.name}}: </p></el-col>
                    <el-col :span="20">
                        <el-button v-for="metric in metricList.filter(m => m.category === c.value)"
                                   :key="metric.id" border style="margin: 2px" @click="clickMetric(metric.id)"
                                   :type="form.stat.metrics.includes(metric.id) ? 'primary' : 'plain'">
                            <el-popover placement="top" width="300" trigger="hover">
                                <template #reference>{{metric.name}}{{metric.init ? '' : '(未初始化)'}}</template>
                            </el-popover>
                        </el-button>
                    </el-col>
                </el-row>
            </template>
            <el-button type="success"  @click="$refs.editor.openDialog()">新增指标</el-button>
        </el-card>
        <metric-editor ref="editor" @add="afterCreateMetric"></metric-editor>
    </div>
</template>

<script>
import {MetricCategoryList} from "../metric/data";
import axios from 'ts-axios-new';
import MetricEditor from "../metric/MetricEditor";

export default {
    name: "ExpMetricEditor",
    components: {MetricEditor},
    props: {
        form: Object,
        disabled: Boolean,
    },
    data() {
        const ignore = ['dau', 'af_dau', 'first_open', 'af_first_open', 'af_retain', 'combination', 'sum_value', 'event_count', 'user_count'];
        const categoryList = MetricCategoryList.filter(c => !ignore.includes(c.value));
        return {
            metricMap: {}, loading: false, metricList: [],
            categoryList: categoryList,
        }
    },
    methods: {
        fetchMetrics() {
            let metric_params = {
                contain_preset: 1,
                product_id: this.$route.params.productId,
            };
            this.loading = true;
            axios.get( `/api/v1/metric`, {params: metric_params}).then(res => {
                this.metricList = res.data.data.models;
                this.metricList.forEach(m => {
                    this.metricMap[m.id] = m.name;
                })
                this.loading = false;
            }).catch(err => {
                this.loading = false;
            })
        },
        submitForm(callback) {
            if (this.form.stat.metrics.length > 0) {
                callback()
            } else {
                this.$alert('请至少添加一个指标！');
            }
        },
        clickMetric(mid) {
            if (this.disabled)
                return
            if (this.form.stat.metrics.includes(mid)) {
                this.form.stat.metrics.splice(this.form.stat.metrics.indexOf(mid), 1);
            } else {
                this.form.stat.metrics.push(mid);
            }
        },
        afterCreateMetric(newMetric) {
            this.metricMap[newMetric.id] = newMetric.name;
            this.metricList.push(newMetric);
            this.form.stat.metrics.push(newMetric.id);
        },
    },
    mounted() {
        this.fetchMetrics();
    }
}
</script>

<style scoped>

</style>

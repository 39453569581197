<template>
    <div id="button"></div>
</template>

<script>
export default {
    name: "Login",
    mounted() {
        google.accounts.id.renderButton(
            document.getElementById('button'),
            {type: 'standard', theme: 'outline'}
        )
    }
}
</script>

<style scoped>

</style>
<template>
    <div style="padding: 0 30px">
        <cohort-form :loading="loading" ref="form" @submit="init" min-installs dimensions
                     :previous="30"></cohort-form>
        <div style="display: flex;justify-content: flex-end">
            <sql-runtime ref="timer" :stat="timer_stat" style="float: right;margin-right: 20px"></sql-runtime>
        </div>
        <el-card shadow="never" :body-style="{padding: '0'}">
            <div style="padding: 10px">
                <el-tooltip content="下载表格" placement="top">
                    <el-icon style="margin-left: 10px;cursor: pointer" @click="downloadTable">
                        <Download/>
                    </el-icon>
                </el-tooltip>
            </div>
            <el-table title="留存报告" :data="data" v-loading="loading" class="retention-table" ref="table"
                      @sort-change="sortChange">
                <el-table-column v-for="(d, i) in dims" :prop="d" width="160" fixed :label="d" sortable
                                 show-overflow-tooltip align="center">
                    <template #default="scope">
                        <div :style="{backgroundColor: scope.row[d]?'var(--el-fill-color)':'#E6A23C'}" style="padding: 25px 0"
                             class="inner-cell">
                            <p style="line-height: 24px">{{ scope.row[d] || '未能归因' }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="installs" width="128" sortable align="center" label="新增">
                    <template #default="scope">
                        <div class="inner-cell">
                            <p>{{ (scope.row.r0 / scope.row.installs * 100).toFixed(2) }}%</p>
                            <p style="font-size: 12px;">{{ formatInt(scope.row.installs) }}</p>
                        </div>
                    </template>
                </el-table-column>
                <template v-for="(h, i) in headers" :key="h">
                    <el-table-column :label="'Day ' + (i - dims.length - 1)" :prop="h" v-if="i > dims.length + 1"
                                     width="128" align="center">
                        <template #default="scope">
                            <div class="inner-cell"
                                 :style="{backgroundColor: blue[parseInt((scope.row[h] / scope.row.r0) * 10)], color: scope.row[h] / scope.row.r0>=0.5?'#fff':''}">
                                <p>{{ (scope.row[h] / scope.row.r0 * 100).toFixed(2) }}%</p>
                                <p style="font-size: 12px;">{{ formatInt(scope.row[h]) }}</p>
                            </div>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
            <el-table title="留存报告" :data="data" v-loading="loading" ref="hidden_table" style="display: none">
                <el-table-column v-for="(d, i) in dims" :prop="d" width="160" :label="d" sortable
                                 show-overflow-tooltip align="center">
                    <template #default="scope">
                        <p>{{ scope.row[d] || '未能归因' }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="r0" width="128" sortable align="center" label="新增">
                    <template #default="scope">
                        <p style="font-size: 12px;">{{ formatInt(scope.row.installs) }}</p>
                    </template>
                </el-table-column>
                <template v-for="(h, i) in headers" :key="h">
                    <el-table-column :label="'Day ' + (i - dims.length - 1)" :prop="h" v-if="i > dims.length + 1"
                                     width="128" align="center">
                        <template #default="scope">
                            <p style="font-size: 12px;">{{ formatInt(scope.row[h]) }}</p>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import SqlRuntime from "../../base/SqlRuntime";
import DownloadableTable from "../../base/DownloadableTable";
import {update, formatInt} from "../../libs/utils";
import axios from 'ts-axios-new';

export default {
    name: "Retention",
    components: {DownloadableTable, SqlRuntime, CohortForm},
    data() {
        return {
            loading: false, data: [], headers: [], dims: [], title: '留存报告',
            timer_stat: {
                total_bytes_processed: 0, duration: 0, message: null, sql: ''
            },
            blue: [
                'var(--el-fill-color)',
                'var(--el-color-primary-light-9)',
                'var(--el-color-primary-light-8)',
                'var(--el-color-primary-light-7)',
                'var(--el-color-primary-light-5)',
                'var(--el-color-primary-light-3)',
                'var(--el-color-primary)',
                'var(--el-color-primary-dark-2)',
                'var(--el-color-primary-dark-2)',
                'var(--el-color-primary-dark-2)',
                'var(--el-color-primary-dark-2)',
            ],
        }
    },
    methods: {
        init() {
            this.loading = true;
            this.$refs.timer.start();
            this.dims = [];
            const form = this.$refs.form.getData();
            axios.post(`/api/v1/retention/${this.$route.params.productId}/report`, form).then(res => {
                this.headers = res.data.data.headers;
                this.dims = form.dimensions;
                update(this.timer_stat, res.data.data);
                this.loading = false;
                this.$refs.timer.success();
                this.data = [];
                res.data.data.data.forEach(d => {
                    let valid = true;
                    // this.dims.forEach(dim => {
                    //     if (!d[dim]) {
                    //         valid = false;
                    //     }
                    // })
                    if (valid) {
                        this.data.push(d);
                    }
                });
                this.$nextTick(_ => {
                    if (this.dims.indexOf('date') > -1) {
                        this.$refs.table.sort('date', 'ascending');
                    } else {
                        this.$refs.table.sort('installs', 'descending');
                    }
                })
            }).catch(err => {
                this.loading = false;
                this.$refs.timer.error(err.response.data.status.message);
            })
        },
        formatInt(value) {
            return formatInt(value);
        },
        downloadTable() {
            if (!this.data || this.data.length < 1)
                return
            this.$root.$downloadTable(this.$refs.hidden_table.$refs.bodyWrapper.parentElement, this.title);
        },
        shareTable() {
            if (!this.data || this.data.length < 1)
                return
            this.$root.$shareToGSheet(this.$refs.hidden_table.$refs.bodyWrapper.parentElement, this.title);
        },
        copyTable() {
            if (!this.data || this.data.length < 1)
                return
            this.$root.$copyTable(this.$refs.hidden_table.$refs.bodyWrapper.parentElement);
        },
        sortChange({column, prop, order}) {
            this.$refs.hidden_table.sort(prop, order);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init()
        }
    }
}
</script>

<style scoped>
.retention-table ::v-deep(td) {
    border-bottom: 0;
    padding: 0;
}

.retention-table ::v-deep(th), .retention-table ::v-deep(.install) {
    background-color: var(--el-fill-color);
}

.retention-table ::v-deep(.cell) {
    padding: 0;
}

.retention-table ::v-deep(.inner-cell) {
    padding: 14px 0;
    color: var(--el-text-color-regular);
}

.retention-table ::v-deep(p) {
    margin: 0;
}
</style>
<template>
    <div ref="world" :style="{width, height}" v-loading="loading" style="position: relative"></div>
</template>

<script>

export default {
    name: "WorldMap",
    props: {
        title: String,
        width: String,
        height: String,
        loading: Boolean,
        series: Array,
    },
    data() {
        return {
            chart: null,
        }
    },
    methods: {
        init() {
            this.chart = new google.maps.Map(this.$refs.world, {
                center: {lat: 0, lng: -50},
                zoom: 2,
                minZoom: 2,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                gestureHandling: 'cooperative',
                scrollwheel: false,
                styles: [
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [{
                            "color": "#F8F9FA",
                        }]
                    }, {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [{
                            "color": "#DADCDF"
                        }]
                    }, {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#F8F9FA"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }
                ],
            })
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        series() {
            this.init();
        }
    }
}
</script>

<style scoped>
</style>

<template>
    <el-select v-model="selected"
               multiple
               placeholder="请选择产品版本"
               :multiple-limit="limit || 0"
               :size="size || 'large'"
               filterable
               clearable
               :style="{width: width || '100%'}"
               :collapse-tags="collapseTags"
               :loading="loading"
               :disabled="disabled"
               :allow-create="allowCreate"
               @change="selectVersion">
        <el-option v-if="current" :value="`< ${current}`"></el-option>
        <el-option v-for="p in versions" :value="p.version" :disabled="p.version===current"></el-option>
    </el-select>
</template>

<script>
import {timestampToDate, update} from "../libs/utils";
import axios from 'ts-axios-new';

export default {
    name: "AppVersionPicker",
    props: {
        modelValue: Array,
        wanted: String,
        limit: Number,
        size: String,
        width: String,
        start: String,
        end: String,
        collapseTags: Boolean,
        disabled: Boolean,
        allowCreate: Boolean,
        current: String,
    },
    data() {
        return {
            versions: [], selected: [], loading: false,
        }
    },
    methods: {
        queryVersions() {
            this.loading = true;
            axios.get(`api/v1/pm/version`, {params: {product_id: this.$route.params.productId}}).then(resp => {
                this.loading = false;
                this.versions = resp.data.data.versionList;
            }).catch(_ => {
                this.loading = false;
            });

        },
        selectVersion() {
            update(this.modelValue, this.selected);
            this.$emit("change");
        }
    },
    mounted() {
        this.queryVersions();
        update(this.selected, this.modelValue);
    },
    watch: {
        $route(val) {
            this.versions.splice(0, this.versions.length);
            this.queryVersions();
        },
        start(newVal) {
            this.queryVersions();
        },
        end(newVal) {
            this.queryVersions();
        }
    }
}
</script>

<style scoped>

</style>


<template>
    <div style="padding: 0 30px">
        <cohort-form :loading="loading" ref="cohort" @submit="init" :previous="30" dimensions></cohort-form>
        <div style="margin-top: 20px" v-loading="loading">
            <el-card shadow="never">
                <div id="chart" style="height: 400px;width: 100%;"></div>
            </el-card>
            <el-table :data="data" v-loading="loading">
                <el-table-column v-for="(d, i) in dims" :prop="d" width="160" :label="d" sortable
                                 show-overflow-tooltip align="center">
                    <template #default="scope">
                        <p>{{ scope.row[d] || '未能归因' }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="真实LT30" prop="lt30_real" width="100"></el-table-column>
                <el-table-column label="预测LT30" prop="lt30_predict" width="100"></el-table-column>
                <el-table-column label="真实LT60" prop="lt60_real" width="100"></el-table-column>
                <el-table-column label="预测LT60" prop="lt60_predict" width="100"></el-table-column>
                <el-table-column label="真实LT90" prop="lt90_real" width="100"></el-table-column>
                <el-table-column label="预测LT90" prop="lt90_predict" width="100"></el-table-column>
                <el-table-column label="真实LT120" prop="lt120_real" width="100"></el-table-column>
                <el-table-column label="预测LT120" prop="lt120_predict" width="100"></el-table-column>
                <el-table-column label="真实LT180" prop="lt180_real" width="100"></el-table-column>
                <el-table-column label="预测LT180" prop="lt180_predict" width="100"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import axios from 'ts-axios-new';

export default {
    name: "LTPredict",
    components: {CohortForm},
    data() {
        return {
            loading: false, data: [], dims: [],
        }
    },
    methods: {
        init() {
            this.loading = true;
            const form = this.$refs.cohort.getData();
            form.product_id = this.$route.params.productId;
            const x = [], series = [];
            for (let i = 1; i < 60; i++) {
                x.push(i);
            }
            axios.post(`/api/v1/lt/predict`, form).then(res => {
                this.data = res.data.data.data;
                this.dims = form.dimensions;
                res.data.data.data.forEach(d => {
                    let name = '';
                    form.dimensions.forEach(dim => {
                        name += d[dim] + ' ';
                    });
                    const real = [], predict = [];
                    let real_lt = 0, predict_lt = 0;
                    d.real.forEach((v, i) => {
                        real_lt += v;
                        if (i)
                            real.push(parseFloat((v * 100).toFixed(2)));
                        if (i === 30) {
                            d.lt30_real = real_lt.toFixed(2);
                        } else if (i === 60) {
                            d.lt60_real = real_lt.toFixed(2);
                        } else if (i === 90) {
                            d.lt90_real = real_lt.toFixed(2);
                        } else if (i === 120) {
                            d.lt120_real = real_lt.toFixed(2);
                        } else if (i === 180) {
                            d.lt180_real = real_lt.toFixed(2);
                        }
                    });
                    d.predict.forEach((v, i) => {
                        predict_lt += v;
                        if (i)
                            predict.push(parseFloat((v * 100).toFixed(2)));
                        if (i === 30) {
                            d.lt30_predict = predict_lt.toFixed(2);
                        } else if (i === 60) {
                            d.lt60_predict = predict_lt.toFixed(2);
                        } else if (i === 90) {
                            d.lt90_predict = predict_lt.toFixed(2);
                        } else if (i === 120) {
                            d.lt120_predict = predict_lt.toFixed(2);
                        } else if (i === 180) {
                            d.lt180_predict = predict_lt.toFixed(2);
                        }
                    });
                    series.push({
                        name: name + '真实留存',
                        type: 'line',
                        data: real,
                    });
                    series.push({
                        name: name + '预测留存',
                        type: 'line',
                        data: predict,
                    });
                });
                echarts.init(document.getElementById('chart')).setOption({
                    xAxis: {
                        type: 'category',
                        data: x,
                    },
                    yAxis: {
                        type: 'value',
                    },
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                    },
                    series
                }, true)
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
<template>
    <el-dialog v-model="dialog_opened" width="80%" :before-close="reset" :close-on-click-modal="false" title="指标组件">
        <el-form :model="form" label-width="80px" ref="form" label-position="top">
            <el-row>
                <el-col :span="8">
                    <el-card shadow="never">
                        <el-form-item label="组件名称" prop="name" :rules="[{required: true, message: '请输入组件名称'}]">
                            <el-input v-model="form.name" :disabled="loading"></el-input>
                        </el-form-item>
                        <el-form-item label="选择指标" prop="metric_id" :rules="[{required: true, message: '请选择指标'}]">
                            <metric-selector v-model="metric" :metric-list="metricList" :disabled="loading"
                                             width="100%" value-key="id"
                                             @change="changeMetric"></metric-selector>
                        </el-form-item>
                        <p style="margin: 0;font-size: 12px;line-height: 12px">没有找到需要的指标？快来
                            <el-link :disabled="loading" @click="$refs.editor.openDialog()"
                                     style="font-size: 12px;line-height: 12px">新建
                            </el-link>
                            一个！
                        </p>
                        <el-divider></el-divider>
                        <el-form-item label="分组维度" prop="groupings">
                            <el-select style="width: 100%" :disabled="loading" v-model="form.groupings"
                                       multiple filterable @change="changeGroupings">
                                <el-option label="国家" value="country"></el-option>
                                <el-option label="安装来源" value="media_source"></el-option>
                                <el-option label="App版本" value="app_version"></el-option>
                            </el-select>
                        </el-form-item>
                        <!--groupings选中的字段必须要有过滤条件!-->
                        <el-form-item label="过滤条件" props="filters">
                            <div v-for="(f, i) in form.filters" style="display: flex">
                                <el-select v-model="f.key" style="width: 100px" filterable
                                           @change="changeKey(f)" :disabled="loading||f.fixed">
                                    <el-option label="国家" value="country"></el-option>
                                    <el-option label="安装来源" value="media_source"></el-option>
                                    <el-option label="App版本" value="app_version"></el-option>
                                    <el-option label="生命周期" value="living_days"></el-option>
                                    <el-option label="AB测Tag" value="abtest_tag"></el-option>
                                    <el-option label="设备类型" value="device_category"></el-option>
                                </el-select>
                                <el-select v-model="f.operator" style="width: 100px" :disabled="loading">
                                    <el-option label="IN" value="in"
                                               :disabled="['living_days', 'abtest_tag'].includes(f.key)"></el-option>
                                    <el-option label="NOT IN" value="not in"
                                               :disabled="['living_days', 'abtest_tag'].includes(f.key)"></el-option>
                                    <el-option label="LIKE" value="like"
                                               :disabled="f.key !== 'abtest_tag'"></el-option>
                                    <el-option value=">"
                                               :disabled="!['living_days', 'app_version'].includes(f.key)"></el-option>
                                    <el-option value=">="
                                               :disabled="!['living_days', 'app_version'].includes(f.key)"></el-option>
                                    <el-option value="="
                                               :disabled="!['living_days', 'app_version'].includes(f.key)"></el-option>
                                    <el-option value="<="
                                               :disabled="!['living_days', 'app_version'].includes(f.key)"></el-option>
                                    <el-option value="<"
                                               :disabled="!['living_days', 'app_version'].includes(f.key)"></el-option>
                                </el-select>
                                <number-input int-only v-model="f.value" :prop="`filters.${i}.value`"
                                              v-if="f.key==='living_days'" style="width: calc(100% - 220px)"
                                              width="100%" :disabled="loading" :required="f.fixed"></number-input>
                                <el-input v-model="f.value" :prop="`filters.${i}.value`"
                                          v-else-if="f.key==='abtest_tag'" style="width: calc(100% - 220px)"
                                          width="100%" :disabled="loading" :required="f.fixed"></el-input>
                                <el-form-item v-else style="width: calc(100% - 220px)"
                                              :rules="[{required: f.fixed, message: ' '}]"
                                              label-width="0" :prop="`filters.${i}.values`">
                                    <country-selector width="100%" v-model="f.values"
                                                      v-if="f.key==='country'" collapse-tags wanted="ip_name"
                                                      :disabled="loading"></country-selector>
                                    <el-select v-model="f.values" filterable :disabled="loading"
                                               style="width: 100%" v-else multiple collapse-tags>
                                        <el-option v-for="i in filters[f.key]" :value="i"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-icon style="cursor: pointer;margin: 8px;" @click="removeFilter(i)" v-if="!f.fixed">
                                    <Delete/>
                                </el-icon>
                            </div>
                        </el-form-item>
                        <el-button @click="addFilter" :disabled="loading">添加过滤条件</el-button>
                    </el-card>
                </el-col>
                <el-col :span="16">
                    <el-card shadow="never" style="margin-left: 20px;height: 100%">
                        <div style="display: flex;justify-content: space-between">
                            <div>
                                <div>
                                    <el-select v-model="form.latest_days" :disabled="loading"
                                               @change="getDateRange">
                                        <el-option :value="7" label="最近7天"></el-option>
                                        <el-option :value="14" label="最近14天"></el-option>
                                        <el-option :value="30" label="最近30天"></el-option>
                                        <el-option :value="60" label="最近60天"></el-option>
                                    </el-select>
                                    <el-button type="primary" @click="preview" :loading="loading"
                                               style="margin-left: 5px" :disabled="!metric">预览
                                    </el-button>
                                </div>
                                <p style="font-size: 12px;margin-left: 20px" class="color-info">
                                    {{ start }} ~ {{ end }}
                                </p>
                            </div>
                            <div>
                                <div style="line-height: 28px;display: flex;font-size: 12px">
                                    <span>图例</span>
                                    <el-radio-group v-model="form.category" :disabled="loading" @change="preview">
                                        <el-radio label="line">
                                            <el-icon>
                                                <TrendCharts/>
                                            </el-icon>
                                        </el-radio>
                                        <el-radio label="bar">
                                            <el-icon>
                                                <Histogram/>
                                            </el-icon>
                                        </el-radio>
                                        <el-radio label="number" v-if="form.groupings.length < 1">
                                            <el-icon>
                                                <SemiSelect/>
                                            </el-icon>
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                                <p style="font-size: 12px;margin-left: 40px;line-height: 12px" class="color-info">
                                    {{ {line: '折线图', bar: '柱状图', number: '数字'}[form.category] }}
                                </p>
                            </div>
                        </div>
                        <el-divider></el-divider>
                        <div v-loading="loading"
                             :style="{height: form.category === 'number'?'108px':'400px', width: form.category === 'number'?'300px': '100%'}">
                            <component-chart :component="form" :metric="metric" :value="value" :value_date="value_date"
                                             :dod="dod" :wow="wow" ref="chart"></component-chart>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">{{ is_view ? '关闭' : '取消' }}</el-button>
            <el-button type="primary" :loading="loading" @click="submit" v-if="!is_view">确定</el-button>
        </template>
    </el-dialog>
    <metric-editor ref="editor" @add="addMetric"></metric-editor>
</template>

<script>
import {timestampToDate, update, deepcopy} from "../../../libs/utils";
import ComponentChart from "./ComponentChart";
import MetricSelector from "../../../base/MetricSelector";
import CountrySelector from "../../../base/CountrySelector";
import NumberInput from "../../../base/NumberInput";
import axios from 'ts-axios-new';
import MetricEditor from "../metric/MetricEditor";

export default {
    name: "ComponentEditor",
    components: {MetricEditor, NumberInput, CountrySelector, MetricSelector, ComponentChart},
    props: {
        metricList: Array,
    },
    emits: ['submit', 'change'],
    data() {
        return {
            loading: false, dialog_opened: false, editing: null, metric: null, success: false,
            value: null, value_date: null, dod: null, wow: null, start: '', end: '', is_view: false,
            form: {
                name: "",
                metric_id: "",
                dashboard_id: "",
                groupings: [],  //  多个分组维度
                latest_days: 7,
                category: "line",
                limit: 10,
                check_anomaly: false,
                filters: [],  // 加一个限制，必须包含groupings中选中的字段
            },
            filters: {
                app_version: [],
                media_source: [],
                device_category: ['mobile', 'tablet'],
            }
        }
    },
    methods: {
        addMetric(row) {
            this.metricList.push(row);
            this.metric = row;
        },
        getDateRange() {
            const now = Date.now();
            let interval = 0;
            if (this.metric && this.metric.category.indexOf('retain') > -1) {
                interval = this.metric.events[0].interval;
            }
            this.start = timestampToDate(now - (this.$root.t + this.form.latest_days + interval) * 24 * 3600 * 1000);
            this.end = timestampToDate(now - (this.$root.t + interval) * 24 * 3600 * 1000);
        },
        changeMetric() {
            this.form.metric_id = this.metric.id;
            this.getDateRange();
        },
        changeKey(f) {
            if (f.key === 'living_days') {
                f.operator = '=';
                f.value = null;
                delete f.values;
            } else if (f.key === 'abtest_tag') {
                f.operator = 'like';
                f.value = null;
                delete f.values;
            } else {
                f.operator = 'in';
                f.values = [];
                delete f.value;
            }
        },
        addFilter() {
            this.form.filters.push({key: '', operator: 'in', values: []})
        },
        removeFilter(i) {
            this.form.filters.splice(i, 1);
        },
        open(item, is_view) {
            this.dialog_opened = true;
            this.is_view = !!is_view;
            this.getDateRange();
            axios.all([
                axios.get('/api/v1/ua/channel').then(res => {
                    this.filters.media_source = ['Organic'];
                    res.data.data.channelList.forEach(c => {
                        if (c.media_source) {
                            this.filters.media_source.push(c.media_source);
                        }
                    })
                }), axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.filters.app_version = [];
                    res.data.data.versionList.forEach(v => {
                        this.filters.app_version.push(v.version);
                    })
                })
            ])
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    this.metricList.forEach(m => {
                        if (m.id === item.metric_id) {
                            this.metric = m;
                        }
                    })
                    update(this.form, item);
                    this.getDateRange();
                    this.changeGroupings();
                    if (this.metric) {
                        this.preview();
                    } else {
                        this.form.metric_id = null;
                    }
                } else {
                    this.form.dashboard_id = this.$route.query.dashboardId;
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.metric = null;
            this.form = {
                name: "",
                metric_id: "",
                dashboard_id: "",
                groupings: [],  //  多个分组维度
                latest_days: 7,
                category: "line",
                size: "small",
                limit: 10,
                check_anomaly: false,
                filters: [],
            }
            this.value = this.value_date = this.dod = this.wow = null;
            this.$refs.chart.clear();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.form.product_id = this.$route.params.productId;
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/api/v1/growth/component/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, this.form);
                            this.$emit('change', this.editing);
                            this.reset();
                        })
                    } else {
                        axios.post(`/api/v1/growth/component`, this.form).then(res => {
                            this.$emit('submit', deepcopy(res.data.data));
                            this.reset();
                        });
                    }
                }
            })
        },
        changeGroupings() {
            this.form.groupings.forEach(g => {
                let exists = false;
                this.form.filters.forEach(f => {
                    if (f.key === g && !exists) {
                        exists = true;
                        f.fixed = true;
                    }
                });
                if (!exists) {
                    const f = {key: g, fixed: true};
                    this.changeKey(f);
                    this.form.filters.push(f);
                }
            });
            this.form.filters.forEach(f => {
                if (f.fixed && this.form.groupings.indexOf(f.key) < 0) {
                    delete f.fixed;
                }
            });
            if (this.form.groupings.length && this.form.category === 'number') {
                this.form.category = 'line';
            }
        },
        preview() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.value = this.subtitle = this.dod = this.wow = this.value_date = null
                    this.loading = true;
                    axios.post(`/api/v1/growth/data`, {
                        product_id: this.$route.params.productId,
                        start: this.start,
                        end: this.end,
                        component: {
                            metric_id: this.form.metric_id,
                            groupings: this.form.groupings,
                            filters: this.form.filters,
                        }
                    }).then(res => {
                        if (res.data.data.data.length < 1) {
                            this.loading = false;
                            return;
                        }
                        if (this.form.category === 'number') {
                            this.subtitle = this.value_date = res.data.data.data[res.data.data.data.length - 1].date.substring(5);
                            this.value = res.data.data.data[res.data.data.data.length - 1].val;
                            if (res.data.data.data.length > 1)
                                this.dod = (this.value - res.data.data.data[res.data.data.data.length - 2].val) / res.data.data.data[res.data.data.data.length - 2].val * 100
                            if (res.data.data.data.length > 7)
                                this.wow = (this.value - res.data.data.data[res.data.data.data.length - 8].val) / res.data.data.data[res.data.data.data.length - 8].val * 100
                        } else {
                            this.subtitle = `${res.data.data.data[0].date.substring(5)} ~ ${res.data.data.data[res.data.data.data.length - 1].date.substring(5)}`
                            if (!this.form.groupings.length) {
                                this.value_date = res.data.data.data[res.data.data.data.length - 1].date.substring(5);
                                this.value = res.data.data.data[res.data.data.data.length - 1].val;
                                if (res.data.data.data.length > 1)
                                    this.dod = (this.value - res.data.data.data[res.data.data.data.length - 2].val) / res.data.data.data[res.data.data.data.length - 2].val * 100
                                if (res.data.data.data.length > 7)
                                    this.wow = (this.value - res.data.data.data[res.data.data.data.length - 8].val) / res.data.data.data[res.data.data.data.length - 8].val * 100
                            }
                        }
                        const x = [];
                        const series = [];
                        const group_data = {};
                        res.data.data.data.forEach(d => {
                            if (x.indexOf(d.date) < 0) {
                                x.push(d.date);
                            }
                            const groupings = [];
                            this.form.groupings.forEach(g => {
                                groupings.push(d[g]);
                            });
                            const key = groupings.join('-');
                            group_data[key] ? group_data[key].push([d.date, d.val]) : group_data[key] = [[d.date, d.val]];
                        });
                        for (let k in group_data) {
                            series.push({type: this.form.category, name: k || this.form.name, data: group_data[k]});
                            if (series.length === this.form.limit)
                                break;
                        }
                        if (this.form.category !== 'number') {
                            this.$refs.chart.draw(x, series);
                        }
                        this.loading = false;
                    })
                }
            });
        }
    },
}
</script>

<style scoped>
.el-radio {
    margin-right: 0;
    line-height: 28px;
    height: 28px;
}

.el-radio-group ::v-deep(.el-radio__inner) {
    display: none;
}
</style>

<template>
    <div style="font-size: 12px">
        <template v-for="(a, i) in model.atomic">
            <p v-if="a.name">
                {{['A', 'B'][i]}}：{{a.name}}
            </p>
        </template>
        <span v-if="model.category === 'dau_retain'">
            $$ f = \frac{留存人数}{活跃人数} * 100 \% $$
        </span>
        <span v-else-if="model.category.indexOf('retain') > -1">
            $$ f = \frac{留存人数}{安装人数} * 100 \% $$
        </span>
        <span v-else-if="model.category === 'duration'">
            $$ f = \frac{\sum 单日时长}{活跃人数} $$
        </span>
        <span v-else-if="model.category === 'user_rate'">
            $$ f(A, B) = \frac{发生事件A的人数}{发生事件B的人数} {{model.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
        </span>
        <span v-else-if="model.category === 'event_rate'">
            $$ f(A, B) = \frac{事件A的发生次数}{事件B的发生次数} {{model.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
        </span>
        <span v-else-if="model.category === 'user_head_rate'">
            $$ f(A) = \frac{发生事件A的人数}{日活} {{model.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
        </span>
        <span v-else-if="model.category === 'event_per_head'">
            $$ f(A) = \frac{事件A的发生次数}{日活} $$
        </span>
        <span v-else-if="model.category === 'sum_value_per_head'">
            $$ f(A) = \frac{\sum 事件A聚合key对应的value}{日活} $$
        </span>
        <span v-else-if="model.category === 'event_per_user'">
            $$ f(A, B) = \frac{事件A的发生次数}{发生事件B的人数} $$
        </span>
        <span v-else-if="model.category === 'sum_value_per_user'">
            $$ f(A, B) = \frac{\sum 事件A聚合key对应的value}{发生事件B的人数} $$
        </span>
        <span v-else-if="model.category === 'sum_value_per_event'">
            $$ f(A, B) = \frac{\sum 事件A聚合key对应的value}{事件B的发生次数} $$
        </span>
    </div>
</template>

<script>
export default {
    name: "MetricFormula",
    props: {
        model: Object,
    },
    methods: {
        init() {
            MathJax.typeset();
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
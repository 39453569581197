<template>
    <div style="padding: 0 30px">
        <cohort-form :date="false" ref="form" :loading="loading" @submit="init"></cohort-form>
        <div style="margin-top: 10px;background-color: #FFF" v-loading="loading">
            <el-form label-width="100px" style="padding-top: 10px">
                <el-form-item label="每日新增" size="small">
                    <el-input size="small" v-model="delta" style="width: 100px" @change="updateActiveUser"></el-input>
                </el-form-item>
            </el-form>
            <div id="chart" style="height: 400px"></div>
        </div>
        <downloadable-table :data="data" title="日活模拟">
            <el-table-column label="日期" prop="date" width="120"></el-table-column>
            <el-table-column label="日活" prop="active" width="100"></el-table-column>
        </downloadable-table>
    </div>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import axios from 'ts-axios-new';
import {timestampToDate, deepcopy} from "../../libs/utils";
import DownloadableTable from "../../base/DownloadableTable";

export default {
    name: "UASimulate",
    components: {DownloadableTable, CohortForm},
    data() {
        const now = Date.now();
        return {
            loading: false, retention: [], init_data: [], data: [], delta: 0,
            form: {
                start: timestampToDate(now - 3 * 24 * 3600 * 1000),
                end: timestampToDate(now + 365 * 24 * 3600 * 1000),
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            const form = this.$refs.form.getData();
            axios.post(`/api/v1/ua/${this.$route.params.productId}/simulate`, form).then(res => {
                this.retention = res.data.data.retention;
                for (let i = 180; i < 365; i++) {
                    this.retention[i + 1] = this.retention[i] * 0.99;
                }
                this.init_data = res.data.data.init;
                this.updateActiveUser();
                this.loading = false;
            })
        },
        updateActiveUser() {
            const active_partition = deepcopy(this.init_data);
            if (active_partition.length > 0) {
                this.data.splice(0, this.data.length);
                let date = this.form.start;
                this.insertActiveUser(date, active_partition);
                for (let i = 1; i <= (new Date(this.form.end) - new Date(this.form.start)) / 1000 / 3600 / 24; i++) {
                    active_partition.forEach(a => {
                        a.user = this.retention.length > a.day + 1 ? (this.retention[a.day] ? a.user / this.retention[a.day] * this.retention[a.day + 1] : 0) : a.user;
                        a.day++;
                    });
                    active_partition.push({day: 0, user: this.delta || 0});
                    date = timestampToDate(new Date(date) - -24 * 3600 * 1000);
                    this.insertActiveUser(date, active_partition);
                }
                this.draw();
            }
        },
        insertActiveUser(date, active_partition) {
            let active = 0;
            active_partition.forEach(a => {
                active += Math.round(a.user);
            });
            active = parseInt(active);
            this.data.push({date, active});
        },
        draw() {
            const x = [], y = [];
            this.data.forEach(d => {
                x.push(d.date);
                y.push(d.active);
            });
            this.$nextTick(_ => {
                echarts.init(document.getElementById('chart')).setOption({
                    legend: {
                        show: true,
                    },
                    tooltip: {
                        formatter: '{b}: {c}'
                    },
                    xAxis: {
                        type: 'category',
                        data: x,
                    },
                    yAxis: [{type: 'value'}, {type: 'value'}],
                    series: [{name: '日活', type: 'line', data: y}, {
                        name: '留存',
                        type: 'line',
                        data: this.retention.slice(1),
                        yAxisIndex: 1
                    }],
                }, true);
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init();
        }
    }
}
</script>

<style scoped>

</style>
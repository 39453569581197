<template>
    <el-input v-if="hide" v-model="text" :disabled="disabled" :style="{width}" @blur="submit" ref="input"></el-input>
    <el-button :size="size" @click="focus" :icon="Plus" :style="{width}" v-else :disabled="disabled">添加</el-button>
</template>

<script>
import {Plus} from '@element-plus/icons-vue';

export default {
    name: "InputBox",
    props: {
        width: {
            type: String,
            default: '80px'
        },
        type: {
            type: String,
            default: 'string'
        },
        size: {
            type: String,
            default: "default",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hide: false, text: '', Plus,
        }
    },
    methods: {
        focus() {
            this.hide = true;
            this.$nextTick(_ => {
                this.$refs.input.focus();
            });
        },
        submit() {
            if (this.text) {
                if (this.type === 'int') {
                    const value = parseInt(this.text);
                    if (!isNaN(value)) {
                        this.$emit('submit', value);
                    }
                } else if (this.type === 'double') {
                    const value = parseFloat(this.text);
                    if (!isNaN(value)) {
                        this.$emit('submit', value);
                    }
                } else {
                    this.$emit('submit', this.text);
                }
            }
            this.hide = false;
            this.text = '';
        },
    }
}
</script>

<style scoped>

</style>
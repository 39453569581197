<template>
    <el-dialog v-model="dialog_opened" width="80%" :close-on-click-modal="false" :title="`${exp.name} 实验报告`"
               :before-close="reset">
        <h3 style="margin: 0 0 5px 0">实验详情</h3>
        <el-table :data="[exp]" ref="exp">
            <el-table-column label="实验状态" width="100" align="center">
                <template #default="scope">
                    {{ {pending: '未发布', published: '已发布', finished: '已应用', terminated: '已关闭'}[scope.row.status] }}
                </template>
            </el-table-column>
            <el-table-column label="开始时间" width="120" align="center">
                <template #default="scope">
                    {{ scope.row.publish_time ? timestampToDate(scope.row.publish_time) : '未开始' }}
                </template>
            </el-table-column>
            <el-table-column label="结束时间" width="120" align="center">
                <template #default="scope">
                    {{ scope.row.finish_time ? timestampToDate(scope.row.finish_time) : '未结束' }}
                </template>
            </el-table-column>
            <el-table-column label="所在域" align="center">
                <el-table-column label="版本" prop="version" width="100" align="center">
                    <template #default="scope">>={{ scope.row.version }}</template>
                </el-table-column>
                <el-table-column label="国家" prop="country" width="150" align="center" show-overflow-tooltip>
                    <template #default="scope">{{ scope.row.country.join('、') || '全球' }}</template>
                </el-table-column>
                <el-table-column label="渠道" prop="media_source" width="150" align="center">
                    <template #default="scope">
                        {{
                            scope.row.media_source.join('、') || '全渠道'
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="设备类型" prop="device_category" width="100" align="center">
                    <template #default="scope">
                        {{ {tablet: '平板', mobile: '手机'}[scope.row.device_category] || '不限' }}
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
        <h3 style="margin: 20px 0 5px 0">报告设定</h3>
        <el-alert effect="dark" type="warning" title="实验尚未发布！" :closable="false"
                  v-if="exp.status === 'pending'"></el-alert>
        <el-form ref="form" label-width="100px" :model="form" label-position="top" v-else>
            <el-card shadow="never" style="min-width: 1000px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="时间范围">
                            <date-range-picker  v-model="form" :disabled="loading"></date-range-picker>
                        </el-form-item>
                        <el-form-item label="过滤条件">
                            <div v-for="(f, i) in form.filters">
                                <el-select  :disabled="loading" filterable style="width: 160px"
                                           v-model="f.key"
                                           @change="changeFilter(f)">
                                    <el-option label="国家" value="country"></el-option>
                                    <el-option label="App版本" value="app_version"></el-option>
                                    <el-option label="安装来源" value="media_source"></el-option>
                                    <el-option label="生命周期" value="living_days"></el-option>
                                </el-select>
                                <el-select  :disabled="loading" style="width: 100px" v-model="f.operator">
                                    <el-option-group v-if="f.key !== 'app_version' && f.key !== 'living_days'">
                                        <el-option value="in" label="IN"></el-option>
                                        <el-option value="not in" label="NOT IN"></el-option>
                                    </el-option-group>
                                    <el-option-group v-else>
                                        <el-option value=">"></el-option>
                                        <el-option value=">="></el-option>
                                        <el-option value="="></el-option>
                                        <el-option value="<="></el-option>
                                        <el-option value="<"></el-option>
                                    </el-option-group>
                                </el-select>
                                <media-source-selector v-model="f.values"  :disabled="loading" collapse-tags
                                                       v-if="f.key==='media_source'"
                                                       width="140px"></media-source-selector>
                                <country-selector v-model="f.values"  :disabled="loading" collapse-tags
                                                  width="140px" v-else-if="f.key==='country'"
                                                  wanted="ip_name"></country-selector>
                                <number-input v-model="f.value"  :disabled="loading"
                                              style="width: 140px;display: inline-block;" :prop="`filters.${i}.value`"
                                              int-only v-else-if="f.key==='living_days'"></number-input>
                                <el-select v-else-if="f.key==='app_version'"  :disabled="loading"
                                           v-model="f.value" filterable style="width: 140px">
                                    <el-option v-for="o in filters[f.key]" :value="o"></el-option>
                                </el-select>
                                <el-select v-else  :disabled="loading" v-model="f.values" multiple filterable
                                           style="width: 140px">
                                    <el-option v-for="o in filters[f.key]" :value="o"></el-option>
                                </el-select>
                                <el-icon style="margin: 8px">
                                    <Loading v-if="loading"/>
                                    <Delete v-else @click="removeFilter(f)" style="cursor: pointer;" />
                                </el-icon>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="统计指标">
                            <metric-selector-hyper v-model="form.metrics" size="small"
                                                   :disabled="loading"></metric-selector-hyper>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="12">
                        <el-button icon="Plus" @click="addFilter" :loading="loading"> 添加更多过滤项</el-button>
                    </el-col>
                    <el-col :span="12" style="text-align: right;">
                        <el-button :loading="loading" @click="download" :disabled="$route.query.domain !== 'elastic'">
                            下载报告
                        </el-button>
                        <el-button type="primary" :loading="loading" @click="submit"
                                   :disabled="$route.query.domain !== 'elastic'">生成报告
                        </el-button>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <h3 style="margin: 20px 0 5px 0">实验报告</h3>
        <el-alert effect="dark" type="error" title="测试环境无法产生实验报告！" :closable="false"
                  v-if="$route.query.domain !== 'elastic'"></el-alert>
        <el-card shadow="never" v-else>
            <el-table :data="data">
                <el-table-column label="" width="200" align="center">
                    <template #default="scope">
                        <el-button  :loading="loading" :disabled="exp.status !== 'published'"
                                   @click="apply(scope.$index)">{{ exp.apply === scope.$index ? '已' : '' }}应用
                        </el-button>
                        <el-button  :loading="loading" @click="$refs.json.openDialog(scope.$index)">参数
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="实验组名称" width="100" prop="group.name" align="center"></el-table-column>
                <el-table-column v-for="m in form.metrics" width="160" align="center" :label="m.name"
                                 :class-name="m.id===selected?'selected pointer': 'pointer'">
                    <template #header>
                        <div @click="metric_loading[m.id]?null:selected=m.id" style="padding: 12px 0">{{ m.name }}</div>
                    </template>
                    <template #default="scope">
                        <div @click="metric_loading[m.id]?null:selected=m.id" style="padding: 12px 0"
                             v-loading="metric_loading[m.id]">
                            <p style="margin: 0" v-if="scope.row[m.id]">
                                {{ scope.row[m.id].avg }}{{ m.metric_type === 'percent' ? '%' : '' }}</p>
                            <p v-if="scope.row[m.id] && scope.row[m.id].ci && scope.$index > 0" style="margin: 0"
                               :style="{color: scope.row[m.id] && scope.row[m.id].is_significant === 0 ? 'var(--el-color-info)' : parseFloat(scope.row[m.id].ci[1]) < 0 ? 'var(--el-color-error)' : 'var(--el-color-success)'}">
                                {{ scope.row[m.id].ci[1] + ' ~ ' + scope.row[m.id].ci[0] }}
                            </p>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <h4 style="margin: 30px 0 0 0" v-if="selected">详细数据</h4>
            <el-table :data="data" v-if="selected">
                <el-table-column label="实验组" width="100" align="center" prop="group.name"></el-table-column>
                <el-table-column label="样本数" width="100" align="center" prop="sample_size">
                    <template #default="scope">
                        {{ scope.row[selected].sample_size }}
                    </template>
                </el-table-column>
                <template v-for="m in form.metrics">
                    <el-table-column v-if="m.id===selected" width="100" align="center" :label="m.name">
                        <template #default="scope">
                            {{ scope.row[selected].avg }}{{ m.metric_type === 'percent' ? '%' : '' }}
                        </template>
                    </el-table-column>
                </template>
                <el-table-column label="绝对差" width="100" prop="abs_avg">
                    <template #default="scope">
                        <span v-if="scope.$index">{{ scope.row[selected].abs_avg.toFixed(4) }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="相对差" width="100" prop="rel_avg">
                    <template #default="scope">
                        <span v-if="scope.$index">{{ scope.row[selected].rel_avg.toFixed(4) }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="显著性（P值）" width="200" prop="pvalue">
                    <template #default="scope">
                        <span v-if="scope.$index">{{ scope.row[selected].pvalue }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="置信区间" width="200">
                    <template #default="scope">
                        <span v-if="scope.$index">{{
                                scope.row[selected].ci[1] + ' ~ ' + scope.row[selected].ci[0]
                            }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="MDE" width="200">
                    <template #default="scope">
                        <span v-if="scope.$index">{{ scope.row[selected].delta }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table>
            <div id="chart" style="width: 100%;height: 300px;margin-top: 20px" v-if="selected"></div>
            <el-table :data="table" v-if="table.length > 0" ref="实验">
                <el-table-column label="实验名称" prop="name" fixed="left" width="100" align="center"></el-table-column>
                <el-table-column v-for="date in x" width="160" :label="date" align="center">
                    <template #default="scope">
                        <p style="margin: 0">
                            {{
                                scope.row[date] ? scope.row[date].category === 'duration' ? formatDuration(scope.row[date].value) : scope.row[date].category.indexOf('retain') < 0 && scope.row[date].metric_type === 'percent' ? (scope.row[date].value * 100).toFixed(2) : formatInt(scope.row[date].value) : '-'
                            }}{{
                                scope.row[date] && scope.row[date].value && scope.row[date].metric_type === 'percent' ? '%' : ''
                            }}
                        </p>
                        <p style="margin: 0; color: var(--el-color-info)">
                            {{ scope.row[date] ? scope.row[date].sample_size : '-' }}</p>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-table :data="summary" ref="summary" style="display: none">
            <el-table-column label="实验标签" prop="tag" width="100"></el-table-column>
            <el-table-column label="实验效果" prop="metric" width="100"></el-table-column>
            <el-table-column v-for="m in form.metrics" :label="m.name" width="160" :prop="m.id"></el-table-column>
        </el-table>
        <el-table :data="detail" ref="detail" style="display: none">
            <el-table-column label="指标">
                <el-table-column label="实验标签">
                    <el-table-column label="-" width="120" prop="date"></el-table-column>
                </el-table-column>
            </el-table-column>
            <el-table-column :label="m.name" v-for="m in form.metrics">
                <el-table-column v-for="tag in form.abtest_tags" :label="tag">
                    <el-table-column label="样本数" width="100" :prop="`${m.id}.${tag}.sample_size`"></el-table-column>
                    <el-table-column label="value" width="100" :prop="`${m.id}.${tag}.value`"></el-table-column>
                </el-table-column>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" @click="reset" :loading="loading">关闭</el-button>
        </template>
    </el-dialog>
    <ExpGroupHistory ref="json" :form="exp"></ExpGroupHistory>
</template>

<script>
import axios from 'ts-axios-new';
import FileSaver from 'file-saver';
import {deepcopy, timestampToDate, formatDuration, formatInt, generateDateRange, update} from "../../../libs/utils";
import DateRangePicker from "../../../base/DateRangePicker";
import MediaSourceSelector from "../../../base/MediaSourceSelector";
import CountrySelector from "../../../base/CountrySelector";
import NumberInput from "../../../base/NumberInput";
import ExpGroupHistory from "./ExpGroupHistory";
import MetricSelectorHyper from "../metric/MetricSelectorHyper";

export default {
    name: "ExpStat",
    components: {
        MetricSelectorHyper,
        ExpGroupHistory, NumberInput, CountrySelector, MediaSourceSelector, DateRangePicker
    },
    data() {
        return {
            dialog_opened: false, loading: false, exp: {}, data: [], metric_loading: {}, selected: null,
            x: [], table: [], summary: [], detail: [],
            form: {
                start: '',
                end: '',
                abtest_tags: [],
                filters: [],
                metric_ids: [],
                max_date: null,
            },
            filters: {
                app_version: [],
            }
        }
    },
    inject: ['metricList'],
    methods: {
        open(item) {
            const query = deepcopy(this.$route.query);
            query.expId = item.id;
            this.$router.push({query});
            this.dialog_opened = true;
            this.exp = item;
            this.form.filters.push({key: 'app_version', operator: '>=', value: item.version});
            if (item.country.length) {
                const country = [];
                item.country.forEach(c => {
                    this.$root.country_list.forEach(cc => {
                        if (c === cc.code) {
                            country.push(cc.ip_name);
                        }
                    })
                })
                this.form.filters.push({key: 'country', operator: 'in', values: country});
            }
            if (item.media_source.length) {
                this.form.filters.push({key: 'media_source', operator: 'in', values: deepcopy(item.media_source)});
            }
            this.form.end = item.finish_time ? timestampToDate(item.finish_time) : timestampToDate(Date.now() - 24 * 3600 * 1000);
            this.form.start = timestampToDate(new Date(this.form.end) - 15 * 24 * 3600 * 1000);
            if (this.form.start < timestampToDate(item.publish_time)) {
                this.form.start = timestampToDate(item.publish_time);
            }
            this.form.max_date = item.finish_time ? timestampToDate(item.finish_time) : null
            this.form.abtest_tags = [];
            this.data = [];
            this.summary = [];
            this.detail = [];
            item.groups.forEach(g => {
                this.form.abtest_tags.push(g.tag);
                this.summary.push(
                    {tag: g.tag, metric: '样本数'},
                    {tag: g.tag, metric: '总均值'},
                    {tag: g.tag, metric: '绝对差'},
                    {tag: g.tag, metric: '相对差'},
                    {tag: g.tag, metric: '标准差'},
                    {tag: g.tag, metric: 'P值'},
                    {tag: g.tag, metric: '显著性'},
                )
                this.data.push({group: g});
            });
            generateDateRange(this.form.start, this.form.end).forEach(date => {
                this.detail.push({date})
            });
            this.form.metrics = [];
            this.metric_loading = {};
            item.stat.metrics.forEach(m => {
                this.metricList.forEach(metric => {
                    if (metric.id === m && metric.init) {
                        this.form.metrics.push(metric);
                        this.metric_loading[m] = false;
                    }
                })
            })
            axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                this.filters.app_version = [];
                res.data.data.versionList.forEach(v => {
                    this.filters.app_version.push(v.version);
                });
            });
            this.$nextTick(_ => {
                this.submit();
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.form = {
                start: '',
                end: '',
                abtest_tags: [],
                filters: [],
                metrics: [],
                max_date: null,
            }
            this.dialog_opened = this.loading = false;
            this.selected = null;
            const query = deepcopy(this.$route.query);
            delete query.expId;
            this.$router.push({query});
            this.data = [];
            this.summary = [];
            this.detail = [];
            this.table = [];
            this.x = [];
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp);
        },
        formatInt(value) {
            return formatInt(value);
        },
        formatDuration(duration) {
            return formatDuration(duration);
        },
        submit() {
            if (this.$route.query.domain !== 'elastic')
                return;
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.form.product_id = this.$route.params.productId;
                    const requests = [];
                    this.selected = null;
                    this.form.metrics.forEach(m => {
                        let valid = false;
                        this.metricList.forEach(real_metric => {
                            if (m.id === real_metric.id && m.init) {
                                valid = true;
                            }
                        })
                        if (!valid) {
                            return
                        }
                        const form = deepcopy(this.form);
                        delete form.metrics;
                        form.metric_ids = [m.id];
                        this.metric_loading[m.id] = true;
                        requests.push(axios.post(`/api/v1/abtest/report`, form).then(res => {
                            if (res.data.data[m.id]) {
                                res.data.data[m.id].forEach((d, i) => {
                                    if (m.category === 'duration') {
                                        d.avg /= 1000 * 60;
                                        // d.abs_avg /= 1000 * 60;
                                        // d.delta /= 1000 * 60;
                                        // d.std /= 1000 * 60;
                                        d.ci = [(parseFloat(d.ci[0]) / 1000 / 60).toFixed(4), (parseFloat(d.ci[1]) / 1000 / 60).toFixed(4)];
                                    }
                                    if (m.metric_type === 'percent' && m.category.indexOf('retain') < 0) {
                                        d.avg *= 100;
                                    }
                                    d.avg = d.avg ? d.avg.toFixed(4) : d.avg;
                                    this.data[i][m.id] = d;
                                    this.summary[i * 7][m.id] = d.sample_size;
                                    this.summary[i * 7 + 1][m.id] = d.avg;
                                    this.summary[i * 7 + 2][m.id] = d.abs_avg;
                                    this.summary[i * 7 + 3][m.id] = d.rel_avg;
                                    this.summary[i * 7 + 4][m.id] = d.std;
                                    this.summary[i * 7 + 5][m.id] = d.pvalue;
                                    this.summary[i * 7 + 6][m.id] = d.is_significant;

                                    d.daily.forEach(daily => {
                                        let value = daily.value;
                                        if (m.category === 'duration') {
                                            value /= 1000 * 60;
                                        }
                                        if (m.metric_type === 'percent' && m.category.indexOf('retain') < 0) {
                                            value *= 100;
                                        }
                                        value = value ? value.toFixed(4) : value;
                                        let ii = 0;
                                        this.detail.forEach((x, xi) => {
                                            if (x.date === daily.date) {
                                                ii = xi;
                                            }
                                        })
                                        if (!this.detail[ii][m.id]) {
                                            this.detail[ii][m.id] = {};
                                        }
                                        this.detail[ii][m.id][d.abtest_tags] = {
                                            sample_size: daily.sample_size,
                                            value,
                                        };
                                    });
                                });
                                if (!this.selected) {
                                    this.selected = m.id;
                                }
                            }
                            this.metric_loading[m.id] = false;
                        }))
                    })
                    return axios.all(requests).then(_ => {
                        this.loading = false;
                    });
                }
            })
        },
        draw() {
            this.x = generateDateRange(this.form.start, this.form.end);
            this.table = [];
            let metric = null;
            this.metricList.forEach(m => {
                if (this.selected === m.id) {
                    metric = m;
                }
            });
            const series = [];
            this.data.forEach(d => {
                const data = [], row = {name: d.group.name};
                d[this.selected].daily.forEach(dd => {
                    data.push([dd.date, parseFloat(dd.value.toFixed(4))]);
                    row[dd.date] = {
                        value: dd.value,
                        sample_size: dd.sample_size,
                        category: metric.category,
                        metric_type: metric.metric_type
                    };
                });
                series.push({name: d.group.name, type: 'line', data});
                this.table.push(row);
            });
            echarts.init(document.getElementById('chart')).setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        axis: 'x',
                        type: "shadow",
                    },
                    formatter: params => {
                        let ret = params[0].name + '<br>';
                        const series = [];
                        params.forEach(p => {
                            series.push(p.marker + p.seriesName + '&nbsp;&nbsp;' + (metric.category === 'duration' ? formatDuration(p.data[1]) : formatInt(p.data[1])));
                        });
                        return ret + series.join('<br/>')
                    }
                },
                legend: {},
                xAxis: {
                    type: 'category',
                    data: this.x,
                },
                yAxis: {
                    type: 'value',
                    min: function (value) {
                        return value.min * 1.5 - value.max * 0.5 < 0 ? 0 : Math.floor(value.min * 1.5 - value.max * 0.5);
                    },
                    max: function (value) {
                        return Math.ceil(value.max * 1.5 - value.min * 0.5);
                    },
                    axisLabel: {
                        formatter: value => {
                            if (metric.category === 'duration') {
                                return formatDuration(value);
                            } else {
                                if (value >= 10 ** 9) {
                                    return (value / 10 ** 9).toFixed(1) + 'b';
                                } else if (value >= 10 ** 6) {
                                    return (value / 10 ** 6).toFixed(1) + 'm';
                                } else if (value >= 10 ** 3) {
                                    return (value / 10 ** 3).toFixed(1) + 'k';
                                } else {
                                    return value
                                }
                            }
                        }
                    }
                },
                series,
            }, true);
        },
        addFilter() {
            this.form.filters.push({key: '', operator: 'in', values: []})
        },
        removeFilter(f) {
            this.form.filters.splice(this.form.filters.indexOf(f), 1);
        },
        changeFilter(f) {
            if (f.key === 'living_days' || f.key === 'app_version') {
                f.value = null;
                f.operator = '=';
                delete f.values;
            } else {
                f.values = [];
                f.operator = 'in';
                delete f.value;
            }
        },
        apply(index) {
            this.$confirm('确定要应用该组吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.post(`${this.$root.$getElasticDomain()}/cms/v1/abtest/exp/${this.exp.id}`, {
                    status: 'finished',
                    apply: index
                }).then(res => {
                    update(this.exp, res.data.data);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        download() {
            const sheet0 = XLSX.utils.table_to_sheet(this.$refs.exp.$refs.bodyWrapper.parentElement);
            const sheet1 = XLSX.utils.table_to_sheet(this.$refs.summary.$refs.bodyWrapper.parentElement);
            const sheet2 = XLSX.utils.table_to_sheet(this.$refs.detail.$refs.bodyWrapper.parentElement);
            const wb = {SheetNames: ['实验', '总览', '明细'], Sheets: {'总览': sheet1, '实验': sheet0, '明细': sheet2}};
            const wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'array'});
            const blob = new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `${this.exp.name}实验报告.xlsx`);
        },
    },
    watch: {
        selected(val) {
            if (val) {
                this.$nextTick(_ => {
                    this.draw();
                })
            }
        }
    }
}
</script>

<style scoped>
.el-table ::v-deep(.selected) {
    font-weight: bold;
    background-color: #f0f9eb;
}

.el-table ::v-deep(.pointer) {
    cursor: pointer;
    padding: 0;
}

</style>
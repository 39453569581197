<template>
    <div style="position: relative" :style="{width: width, height: height}">
        <div :id="id" :style="{width: width, height: innerHeight || height}" v-loading="loading">
        </div>
        <div style="position: absolute; left: 40%;top: 100px;"><p style="color: grey">没有数据</p></div>
    </div>
</template>

<script>
export default {
    name: "NoDataChart",
    props: {
        title: String,
        xAxis: Array,
        width: String,
        height: String,
        id: String,
        loading: Boolean,
        innerHeight: String,
    },
    methods: {
        doChart() {
            const myChart = echarts.init(document.getElementById(this.id));
            let option = {
                title: {
                    show: true,
                    text: this.title,
                },
            };
            option.xAxis = {
                type: "category",
                data: this.xAxis,
            };
            option.yAxis = {
                type: 'value',
                min: 0,
                max: 1,
                interval: 0.2
            };
            option.series = [
                {type: "line", data: []},
            ];
            myChart.setOption(option, true);
        }
    },
    mounted() {
        this.doChart();
    },
    watch: {
        title(val) {
            this.doChart();
        },
        xAxis(val) {
            this.doChart();
        },
    },
}
</script>

<style scoped>

</style>

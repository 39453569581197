<template>
    <el-select v-model="metric" :size="size" :value-key="valueKey" :disabled="disabled" :style="{width}" filterable
               @change="change" :multiple="multiple">
        <el-option v-for="m in metricList" :label="m.name" :value="valueKey?m:m.id">
            <div style="display: flex;justify-content: space-between">
                <span>{{m.name}}</span>
                <el-popover placement="right-start" trigger="hover" @show="init" ref="tooltip" width="250px">
                    <template #reference>
                        <el-icon class="color-info" style="margin: 10px;cursor: pointer;">
                            <QuestionFilled/>
                        </el-icon>
                    </template>
                    <div style="font-size: 12px">
                        <span v-if="m.category === 'first_open'">
                            $$ f = 首次打开的人数 $$
                        </span>
                        <span v-else-if="m.category === 'dau'">
                            $$ f = 触发user\_engagement事件的人数 $$
                        </span>
                        <span v-else-if="m.category === 'dau_retain'">
                            $$ f = \frac{留存人数}{活跃人数} * 100 \% $$
                        </span>
                        <span v-else-if="m.category.indexOf('retain') > -1">
                            $$ f = \frac{留存人数}{安装人数} * 100 \% $$
                        </span>
                        <span v-else-if="m.category === 'duration'">
                            $$ f = \frac{\sum 单日时长}{活跃人数} $$
                        </span>
                        <span v-else-if="m.category === 'user_rate'">
                            $$ f = \frac{发生{{m.atomic[0].name.replace(/_/g, '\\_')}}的人数}{发生{{m.atomic[1].name.replace(/_/g, '\\_')}}的人数} {{m.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
                        </span>
                        <span v-else-if="m.category === 'event_rate'">
                            $$ f = \frac{ {{m.atomic[0].name.replace(/_/g, '\\_')}}的发生次数}{ {{m.atomic[1].name.replace(/_/g, '\\_')}}的发生次数} {{m.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
                        </span>
                        <span v-else-if="m.category === 'user_head_rate'">
                            $$ f = \frac{发生{{m.atomic[0].name.replace(/_/g, '\\_')}}的人数}{日活} {{m.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
                        </span>
                        <span v-else-if="m.category === 'event_per_head'">
                            $$ f = \frac{ {{m.atomic[0].name.replace(/_/g, '\\_')}}的发生次数}{日活} $$
                        </span>
                        <span v-else-if="m.category === 'sum_value_per_head'">
                            $$ f = \frac{\sum {{m.atomic[0].name.replace(/_/g, '\\_')}}聚合key对应的value}{日活} $$
                        </span>
                        <span v-else-if="m.category === 'event_per_user'">
                            $$ f = \frac{ {{m.atomic[0].name.replace(/_/g, '\\_')}}的发生次数}{发生{{m.atomic[1].name.replace(/_/g, '\\_')}}的人数} $$
                        </span>
                        <span v-else-if="m.category === 'sum_value_per_user'">
                            $$ f = \frac{\sum {{m.atomic[0].name.replace(/_/g, '\\_')}}聚合key对应的value}{发生{{m.atomic[1].name.replace(/_/g, '\\_')}}的人数} $$
                        </span>
                        <span v-else-if="m.category === 'sum_value_per_event'">
                            $$ f = \frac{\sum {{m.atomic[0].name.replace(/_/g, '\\_')}}聚合key对应的value}{ {{m.atomic[1].name.replace(/_/g, '\\_')}}的发生次数} $$
                        </span>
                    </div>
                </el-popover>
            </div>
        </el-option>
    </el-select>
</template>

<script>
import {deepcopy} from "../libs/utils";

export default {
    name: "MetricSelector",
    data() {
        return {metric: null}
    },
    props: {
        metricList: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: [String, Array, Object],
        },
        valueKey: String,
        size: String,
        disabled: Boolean,
        width: String,
        multiple: Boolean,
    },
    methods: {
        change() {
            this.$emit('update:modelValue', this.metric);
            // this.$emit('change');
        },
        init() {
            MathJax.typeset();
        },
    },
    mounted() {
        document.querySelectorAll('.el-popover.is-light').forEach(item => {
            item.classList.replace('is-light', 'is-dark');
        });
        this.metric = deepcopy(this.modelValue);
    },
    watch: {
        modelValue(val) {
            this.metric = deepcopy(val);
        },
    },
}
</script>

<style scoped>

</style>
<template>
    <aside-nav ref="nav">
        <aside-group title="产品数据">
            <aside-item index="PMDashboard" icon="DataAnalysis" title="默认看板"></aside-item>
        </aside-group>
    </aside-nav>
    <el-main style="padding: 0">
        <h3 style="margin: 16px 30px" v-if="hasHeader">{{ title }}</h3>
        <el-divider style="margin: 8px 0" v-if="hasHeader"></el-divider>
        <el-scrollbar class="main" :style="{height: hasHeader?'calc(100% - 69px)':'100%'}">
            <router-view></router-view>
        </el-scrollbar>
    </el-main>
</template>

<script>
import AsideNav from "../../base/AsideNav";
import AsideGroup from "../../base/AsideGroup";
import AsideItem from "../../base/AsideItem";
import AsideMenu from "../../base/AsideMenu";
import axios from 'ts-axios-new';
import GrowthGuide from "./GrowthGuide";
import {getLocalObj} from "../../libs/storage";
import {update} from "../../libs/utils";
import AsideWrapper from "../../base/AsideWrapper";
import AsideSubMenu from "../../base/AsideSubMenu";

export default {
    name: "PMSimpleGuide",
    components: {AsideSubMenu, AsideWrapper, GrowthGuide, AsideNav, AsideMenu, AsideItem, AsideGroup},
    provide() {
        return {
            growth: this.growth, event_list: this.event_list,
        }
    },
    data() {
        const growth = {label: 'growth-guide', index: 'PMGrowthDashboard', loading: false, data: []};
        return {
            growth, title: document.title, hasHeader: true, event_list: [],
            no_header: ['SqlIndex', 'PMDashboard', 'UADashboard', 'IAADashboard', 'IAASdk', 'IAAPlacement', 'Waterfall'],
        }
    },
    methods: {
        init() {
            this.growth.loading = true;
            axios.get(`/api/v1/growth/dashboard`, {params: {product_id: this.$route.params.productId}}).then(res => {
                this.growth.data = res.data.data.dashboardList;
                const sort = getLocalObj(`/growthGuide/sort/${this.$route.params.productId}`);
                if (sort)
                    this.growth.data.sort(function (a, b) {
                        return (sort.indexOf(a.id) < 0 ? 99 : sort.indexOf(a.id)) - (sort.indexOf(b.id) < 0 ? 99 : sort.indexOf(b.id));
                    });
                this.growth.loading = false;
                if (this.$route.name === 'PMGrowthDashboard') {
                    if (this.growth.data.length) {
                        let exists = false;
                        this.growth.data.forEach(g => {
                            if (this.$route.query.dashboardId === g.id) {
                                exists = true;
                            }
                        });
                        if (!exists) {
                            this.$router.push({query: {dashboardId: this.growth.data[0].id}});
                        }
                    } else {
                        this.$router.push({name: 'PMDashboard'})
                    }
                }
            });
            axios.get(`/api/v1/event`, {params: {product_id: this.$route.params.productId}}).then(res => {
                update(this.event_list, res.data.data.eventList);
            });
        },
    },
    mounted() {
        this.hasHeader = this.no_header.indexOf(this.$route.name) < 0;
        this.init();
        if (this.$route.name === 'PMGuide') {
            // 自动进入第一个导航
            this.$router.push({name: 'PMDashboard'})
        }
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
            }
        },
        "$route.name"() {
            this.hasHeader = this.no_header.indexOf(this.$route.name) < 0;
            this.title = document.title;
        },
        // "$route.query.dashboardId"() {
        //     this.init();
        // },
        collapse(val) {
            if (val) {
                this.$refs.growthMenu.collapse = val;
            }
        },
    }
}
</script>

<style scoped>
</style>
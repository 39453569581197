export function getLocalObj(key, default_value) {
    const value = localStorage.getItem(key);
    if (value) {
        try {
            return JSON.parse(value);
        } catch (e) {
            return null;
        }
    } else {
        return default_value;
    }
}

export function setLocalObj(key, obj) {
    const value = JSON.stringify(obj);
    localStorage.setItem(key, value);
}

<template>
    <div style="padding: 0 30px">
        <div style="display: inline-flex">
            <date-range-picker  v-model="form"></date-range-picker>
            <el-button  type="primary" @click="$refs.editor.openDialog()" style="margin-left: 10px">创建新版本
            </el-button>
        </div>
        <el-table :data="filterData" v-loading="loading" style="width: 1100px;margin-top: 10px">
            <el-table-column prop="version" width="100" label="版本"></el-table-column>
            <el-table-column prop="user_id" width="120" label="创建人">
                <template #default="scope">
                    <user v-model="scope.row.user_id" avatar></user>
                </template>
            </el-table-column>
            <el-table-column prop="c_time" width="150" label="创建时间">
                <template #default="scope">
                    {{timestampToDate(scope.row.c_time)}}
                </template>
            </el-table-column>
            <el-table-column prop="percent" width="100" label="进度">
                <template #default="scope">
                    <el-progress :percentage="scope.row.percent" type="dashboard" :width="50"
                                 :color="colors"></el-progress>
                </template>
            </el-table-column>
            <el-table-column label="版本记录" width="350" prop="content">
                <template #default="scope">
                    <div style="font-size: 12px">
                        <pre>{{scope.row.content}}</pre>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="全量时间" width="150" prop="finish_time">
                <template #default="scope">
                    <span v-if="scope.row.finish_time">{{timestampToDate(scope.row.finish_time)}}</span>
                    <span v-else-if="scope.row.terminated_time" class="color-error">停止发布</span>
                </template>
            </el-table-column>
            <el-table-column width="150" label="操作">
                <template #default="scope">
                    <el-button type="success" size="small"
                               @click="$router.push({name: 'ArchiveVersionPanel', params: {productId: $route.params.productId, versionId: scope.row.id}})">
                        查看
                    </el-button>
                    <el-button type="danger"  @click="remove(scope.row)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <version-editor ref="editor"></version-editor>
</template>

<script>
import User from "../../../base/User";
import axios from 'ts-axios-new';
import {timestampToDate} from "../../../libs/utils";
import DateRangePicker from "../../../base/DateRangePicker";
import VersionEditor from "./VersionEditor";

export default {
    name: "ArchiveIndex",
    components: {VersionEditor, DateRangePicker, User},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [],
            form: {
                start: '2021-09-01',
                end: timestampToDate(now),
            },
            colors: [
                {percentage: 60, color: 'var(--el-color-error)'},
                {percentage: 100, color: 'var(--el-color-warning)'},
                {percentage: 100, color: '#5cb87a'}
            ],
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                if (this.$route.query.version) {
                    res.data.data.versionList.forEach(v => {
                        if (v.version === this.$route.query.version) {
                            this.$router.push({
                                name: 'ArchiveVersionPanel',
                                params: {productId: this.$route.params.productId, versionId: v.id}
                            });
                        }
                    })
                }
                this.data = res.data.data.versionList;
                this.loading = false;
            });
        },
        remove(item) {
            this.$confirm('确定要删除该版本记录吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete('/api/v1/pm/version/' + item.id).then(_ => {
                    this.data.splice(this.data.indexOf(item), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init();
        }
    },
    computed: {
        filterData() {
            return this.data.filter(row => timestampToDate(row.c_time) >= this.form.start && timestampToDate(row.c_time <= this.form.end));
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <el-form :model="form" ref="form" label-width="100px">
        <el-form-item label="实验名称" prop="name"
                      :rules="[{required: true, message: '请输入名称'}, {validator: validator1}]">
            <el-input v-model="form.name" :disabled="loading"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="desc">
            <el-input v-model="form.desc" type="textarea" :disabled="loading"></el-input>
        </el-form-item>
        <el-form-item label="所属层" prop="layer_id" :rules="[{required: true, message: '请选择层'}]"
                      style="position: relative;">
            <el-select v-model="form.layer_id" :loading="inner_loading" style="width: 300px">
                <el-option v-for="l in layer_list" :key="l.id" :label="l.name" :value="l.id">
                    <span style="float: left">{{ l.name }}</span>
                    <span style="float: left; font-size: 13px; margin-left: 20px"
                          :style="{color: layerLeftColor(l)}">{{ layerLeftFilter(l) }}</span>
                </el-option>
            </el-select>
            <el-progress :percentage="percent" type="circle" :width="50" v-if="form.layer_id"
                         style="position: absolute;left: 305px" color="var(--el-color-error)"></el-progress>
            <el-button type="primary" text v-if="form.layer_id" :loading="loading" @click="dialog_opened=true"
                       style="margin-left: 60px">查看同层实验
            </el-button>
        </el-form-item>
        <el-form-item label="占比" prop="percent" v-if="form.layer_id" :rules="[{validator: validator}]">
            <el-slider v-model="form.percent" :max="100 - percent_left" :format-tooltip="formatTooltip" :step="1"
                       :disabled="inner_loading" :style="{width: 100 - percent_left + '%'}"
                       @change="changeRate"></el-slider>
        </el-form-item>
        <el-row>
            <el-col :span="12">
                <el-form-item label="最小版本" prop="version"
                              :rules="[{required: true, message: '请选择最小版本'}, {validator: versionValidator}]">
                    <el-select v-model="form.version" filterable allow-create style="width: 300px">
                        <el-option v-for="v in version_list" :key="v.id" :value="v.version"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="国家" prop="country">
                    <country-selector v-model="form.country"></country-selector>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="设备类型" prop="device_category">
            <el-select multiple v-model="form.device_category" style="width: 300px" :multiple-limit="1" clearable>
                <el-option label="手机" value="mobile"></el-option>
                <el-option label="平板" value="tablet"></el-option>
            </el-select>
        </el-form-item>
        <el-row>
            <el-col :span="12">
                <el-form-item label="获客来源" prop="af_status">
                    <el-select v-model="form.af_status" multiple style="width: 300px" :multiple-limit="1" clearable
                               @change="changeAfStatus">
                        <el-option label="自然量" value="Organic"></el-option>
                        <el-option label="非自然量" value="Non-organic"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.af_status.indexOf('Non-organic') > -1">
                <el-form-item label="媒体渠道" prop="media_source">
                    <el-select v-model="form.media_source" multiple collapse-tags style="width: 300px">
                        <template v-for="c in media_source" :key="c.id">
                            <el-option :value="c.media_source"
                                       v-if="c.media_source"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="Campaign ID" v-if="form.af_status.indexOf('Non-organic') > -1">
            <el-select v-model="form.campaign_id" multiple filterable style="width: 300px" allow-create></el-select>
        </el-form-item>
        <el-form-item label="用户分层标签" prop="user_groups" v-if="user_layer_define.length > 0">
            <div v-for="(_, k) in form.user_layers" :key="k">
                <template v-for="l in user_layer_define">
                    <span v-if="k===l.id">
                        {{ l.name }}
                        <el-tag effect="plain" size="medium" v-for="(tag, i) in form.user_layers[k]" :key="i"
                                style="margin-left: 10px">
                            <template v-for="ll in l.labels">
                                <span v-if="tag===ll.id">{{ ll.name }}</span>
                            </template>
                        </el-tag>
                    </span>
                </template>
            </div>
            <el-button type="primary" @click="openUserLayerEditor">设置</el-button>
        </el-form-item>
    </el-form>
    <user-layer-editor ref="user_layer_editor" v-model="form.user_layers"
                       :user_layer_define="user_layer_define"></user-layer-editor>
    <el-dialog width="50%" v-model="dialog_opened" title="同层实验">
        <el-table :data="exp_list">
            <el-table-column label="名称" prop="name" width="150"></el-table-column>
            <el-table-column label="描述" prop="desc" width="200"></el-table-column>
            <el-table-column label="流量占比" prop="percent" width="100">
                <template #default="scope">
                    {{ scope.row.percent }}%
                </template>
            </el-table-column>
            <el-table-column label="流量锁定" prop="locked" width="100">
                <template #default="scope">
                    <el-icon v-if="scope.row.locked" style="cursor: pointer" @click="unlock(scope.row)">
                        <Lock/>
                    </el-icon>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" @click="dialog_opened=false">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import UserLayerEditor from "./UserLayerEditor";
import CountrySelector from "../../../base/CountrySelector";
import axios from 'ts-axios-new';
import {update, versionValidator} from "../../../libs/utils";

export default {
    name: "ExpEditorStep0",
    components: {CountrySelector, UserLayerEditor},
    props: {
        form: Object,
        loading: Boolean,
        exp_id: String,
    },
    data() {
        return {
            percent: 0, percent_left: 0, exp_list: [], inner_loading: false, user_layer_define: [], layer_list: [],
            dialog_opened: false, all_exp: [], version_list: [], media_source: [],
        }
    },
    methods: {
        versionValidator(rules, value, callback) {
            return versionValidator(rules, value, callback)
        },
        init() {
            this.inner_loading = true;
            axios.all([
                axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer', {params: {product_id: this.$route.params.productId}}).then(res => {
                    update(this.layer_list, res.data.data.layerList);
                }), axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp', {params: {product_id: this.$route.params.productId}}).then(res => {
                    update(this.all_exp, res.data.data.expList);
                }), axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                    update(this.version_list, res.data.data.versionList.filter(row => row['version'] && row['version'].length > 1));
                }), axios.get('/api/v1/ua/channel').then(res => {
                    update(this.media_source, res.data.data.channelList);
                })
            ]).then(_ => {
                this.inner_loading = false;
                if (this.form.layer_id) {
                    this.getLayerExp();
                }
            })
        },
        changeAfStatus() {
            this.form.media_source.splice(0, this.form.media_source.length);
            this.form.campaign_id.splice(0, this.form.campaign_id.length);
        },
        validator(rule, value, callback) {
            if (value > 0) {
                callback()
            } else {
                callback('占比必须大于0')
            }
        },
        formatTooltip(value) {
            return value + '%'
        },
        getLayerExp() {
            this.inner_loading = true;
            axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp', {params: {layer_id: this.form.layer_id}}).then(res => {
                this.percent = this.percent_left = 0;
                update(this.exp_list, res.data.data.expList);
                this.exp_list.forEach(e => {
                    if (!this.exp_id || e.id !== this.exp_id)
                        this.percent_left += e.percent;
                });
                if (this.percent_left + this.form.percent > 100) {
                    this.form.percent = 100 - this.percent_left;
                }
                this.percent = this.form.percent + this.percent_left;
                this.inner_loading = false;
            })
        },
        unlock(exp) {
            this.$confirm('确定要释放该实验所占流量吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(_ => {
                this.inner_loading = true;
                axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + exp.id + '/unlock').then(_ => {
                    exp.locked = false;
                    this.inner_loading = false;
                    this.getLayerExp();
                });
            }).catch(_ => {
            });
        },
        layerLeftColor(val) {
            let left = 0;
            val.buckets.forEach(item => {
                if (!item) left++
            });
            return left ? 'var(--el-color-success)' : 'var(--el-color-info)'
        },
        changeRate() {
            this.percent = this.percent_left + this.form.percent;
        },
        submitForm(callback) {
            this.$refs.form.validate(valid => {
                if (valid) {
                    callback();
                }
            })
        },
        resetForm() {
            this.$refs.form.resetFields();
        },
        openUserLayerEditor() {
            this.$refs.user_layer_editor.openDialog();
        },
        validator1(rule, value, callback) {
            let valid = true;
            if (!this.form.id) {
                this.all_exp.forEach(d => {
                    if (d.name === value) {
                        valid = false;
                    }
                })
            }
            if (valid) {
                callback()
            } else {
                callback('存在同名实验！');
            }
        },
        layerLeftFilter(val) {
            let left = 0;
            val.buckets.forEach(item => {
                if (!item) left++
            });
            return `(空闲 ${left}%)`
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "form.layer_id"(val) {
            if (val) {
                this.getLayerExp();
            }
        }
    }
}
</script>

<style scoped>

</style>

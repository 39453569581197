<template>
    <el-form-item :size="size" :prop="prop + '.name'" :rules="[{required: true, message: '请选择事件名', trigger: 'blur'}]"
                  :label="label" style="width: 100%">
        <el-select :size="size" filterable v-model="data.name" :disabled="disabled" style="width: 160px" allow-create
                   @change="eventChange">
            <!--            <el-option value="first_open">-->
            <!--                first_open-->
            <!--                <span style="font-size: 10px;" class="color-info"> (原生事件，首次打开)</span>-->
            <!--            </el-option>-->
            <!--            <el-option value="user_engagement">-->
            <!--                user_engagement-->
            <!--                <span style="font-size: 10px;" class="color-info"> (原生事件，有效活跃)</span>-->
            <!--            </el-option>-->
            <!--            <el-option value="session_start">-->
            <!--                session_start-->
            <!--                <span style="font-size: 10px;" class="color-info"> (原生事件，开启会话)</span>-->
            <!--            </el-option>-->
            <el-option-group label="应用内事件">
                <el-option v-for="e in event_list" :value="e.name">
                    {{ e.name }}
                    <span style="font-size: 10px;color: var(--el-text-color-secondary)"> ({{ e.desc }})</span>
                </el-option>
            </el-option-group>
            <el-option-group label="广告事件">
                <el-option v-for="e in adsdk_list" :value="e.name">
                    {{ e.name }}
                    <span style="font-size: 10px;color: var(--el-text-color-secondary)"> ({{ e.desc }})</span>
                </el-option>
            </el-option-group>
            <el-option-group label="内购事件">
                <el-option v-for="e in purchase_list" :value="e.name">
                    {{ e.name }}
                    <span style="font-size: 10px;color: var(--el-text-color-secondary)"> ({{ e.desc }})</span>
                </el-option>
            </el-option-group>
        </el-select>
        <el-button :size="size" type="primary" @click="addEventItem" :disabled="disabled" style="margin-left: 5px">
            添加参数
        </el-button>
    </el-form-item>
    <div style="display: flex;flex-wrap: wrap">
        <div v-for="(item, i) in data.items" style="flex: 0 0 auto;position: relative">
            <el-form-item label="参数名" :prop="prop + '.items.' + i + '.key'" :size="size"
                          :rules="[{message: '请选择key', required: true}]">
                <el-select v-model="item.key" :size="size" :disabled="disabled" allow-create filterable
                           @change="keyChange(item)" style="width: 160px">
                    <el-option v-for="p in event_params" :value="p.key">
                        <span>{{ p.key }}</span>
                        <span style="color: var(--el-text-color-secondary);font-size: 8px"> ({{ p.desc }})</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="操作符" :prop="prop + '.items.' + i + '.operator'" :size="size">
                <el-select :size="size" v-model="item.operator" :disabled="disabled" style="width: 160px"
                           @change="operatorChange(item)">
                    <el-option value=">" :disabled="item.type === 'string'"></el-option>
                    <el-option value=">=" :disabled="item.type === 'string'"></el-option>
                    <el-option value="="></el-option>
                    <el-option value="<=" :disabled="item.type === 'string'"></el-option>
                    <el-option value="<" :disabled="item.type === 'string'"></el-option>
                    <el-option value="in" label="IN" :disabled="item.type !== 'string'"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="参数值" :prop="prop + '.items.' + i + '.value'" :size="size"
                          v-if="item.type === 'string'">
                <el-select :size="size" v-model="item.values" placeholder="请选择参数值" filterable style="width: 160px"
                           v-if="item.operator === 'in'" multiple allow-create :disabled="disabled">
                    <el-option v-for="v in values" :value="v"></el-option>
                </el-select>
                <el-select :size="size" v-model="item.value" placeholder="请选择参数值" filterable style="width: 160px"
                           v-else-if="values.length > 0" allow-create :disabled="disabled">
                    <el-option v-for="v in values" :value="v"></el-option>
                </el-select>
                <el-input v-else :size="size" v-model="item.value" :disabled="disabled" style="width: 160px"></el-input>
            </el-form-item>
            <number-input v-else :size="size" label="参数值" :int-only="item.type === 'int'" :disabled="disabled"
                          :prop="prop + '.items.' + i + '.value'" v-model="item.value"></number-input>
            <el-icon @click="removeEventItem(i)" v-if="!disabled" size="18px"
                     style="position: absolute;right: -20px; top: 0;cursor: pointer;">
                <CloseBold/>
            </el-icon>
        </div>
    </div>
    <el-form-item label="聚合Key" :prop="prop + '.dimension_key'" v-if="dimensionKey"
                  :rules="[{required: true, message: '请选择要聚合的key', trigger: 'blur'}]">
        <el-select v-model="data.dimension_key" @change="dimensionKeyChange" :disabled="disabled" filterable
                   style="width: 160px" allow-create>
            <el-option v-for="p in event_params" :value="p.key">
                <span>{{ p.key }}</span>
                <span style="color: var(--el-text-color-secondary);font-size: 8px"> ({{ p.desc }})</span>
            </el-option>
        </el-select>
        <el-select v-model="data.dimension_type" :disabled="disabled || data.dimension_fixed" style="width: 100px">
            <el-option value="string"></el-option>
            <el-option value="int"></el-option>
            <el-option value="float"></el-option>
        </el-select>
    </el-form-item>
</template>

<script>
import NumberInput from "./NumberInput";

export default {
    name: "EventSelector",
    components: {NumberInput},
    props: {
        size: String,
        disabled: Boolean,
        data: Object,
        prop: String,
        label: String,
        labelWidth: String,
        dimensionKey: Boolean,
    },
    inject: ['event_list'],
    data() {
        return {
            event_params: [], value_type: '', values: [],
            adsdk_list: [{
                name: 'adsdk_init', params: [
                    {key: 'isNew', value_type: 'int', desc: '是否使用了最新的广告配置', enums: [0, 1]}
                ], desc: '广告初始化', trigger: 'app决定初始化时机，通常在应用启动时', version: "2.0.0", status: 1,
            }, {
                name: 'adsdk_request', params: [
                    {key: 'placementId', value_type: 'string', desc: '聚合平台的广告id', enums: []},
                    {key: 'format', value_type: 'string', desc: '广告格式', enums: ['interstitial', 'banner', 'reward']},
                ], desc: '广告请求', trigger: '初始化完成后或广告成功展示后', version: '2.0.0', status: 1,
            }, {
                name: 'adsdk_filled', params: [
                    {key: 'placementId', value_type: 'string', desc: '聚合平台的广告id', enums: []},
                    {key: 'unitId', value_type: 'string', desc: '第三方平台的广告id', enums: []},
                    {key: 'format', value_type: 'string', desc: '广告格式', enums: ['interstitial', 'banner', 'reward']},
                ], desc: '广告填充', trigger: '拉取到广告的回调', version: '2.0.0', status: 1,
            }, {
                name: 'adsdk_show', params: [
                    {key: 'format', value_type: 'string', desc: '广告格式', enums: ['interstitial', 'banner', 'reward']},
                    {key: 'placementName', value_type: 'string', desc: 'app内的广告位', enums: []}
                ], desc: '广告尝试展示', trigger: '由app逻辑决定，需要展示广告的时机', version: '2.0.0', status: 1,
            }, {
                name: 'adsdk_displayed', params: [
                    {key: 'placementId', value_type: 'string', desc: '聚合平台的广告id', enums: []},
                    {key: 'unitId', value_type: 'string', desc: '第三方平台的广告id', enums: []},
                    {key: 'format', value_type: 'string', desc: '广告格式', enums: ['interstitial', 'banner', 'reward']},
                    {key: 'placementName', value_type: 'string', desc: 'app内的广告位', enums: []}
                ], desc: '广告展示成功', trigger: '广告展示成功的回调', version: '2.0.0', status: 1,
            }, {
                name: 'adsdk_revenue', params: [
                    {key: 'placementId', value_type: 'string', desc: '聚合平台的广告id', enums: []},
                    {key: 'unitId', value_type: 'string', desc: '第三方平台的广告id', enums: []},
                    {key: 'format', value_type: 'string', desc: '广告格式', enums: ['interstitial', 'banner', 'reward']},
                    {key: 'placementName', value_type: 'string', desc: 'app内的广告位', enums: []},
                    {key: 'revenue', value_type: 'double', desc: '收益的美元', enums: []},
                ], desc: '广告估算收益', trigger: '广告产生收益的回调', version: '2.0.0', status: 1,
            }],
            purchase_list: [{
                name: 'purchase_conn', params: [
                    {key: 'success', value_type: 'int', desc: '是否成功', enums: [0, 1]},
                    {key: 'reconnect', value_type: 'int', desc: '重连次数', enums: []},
                ], desc: '内购请求连接', trigger: '尝试调起内购会话', version: '1.0.0', status: 1,
            }, {
                name: 'purchase_query', params: [
                    {key: 'success', value_type: 'int', desc: '是否成功', enums: [0, 1]},
                    {key: 'cost', value_type: 'int', desc: '金额', enums: [0, 1]},
                    {key: 'sku', value_type: 'string', desc: '商品sku', enums: []},
                ], desc: '内购内容查询', trigger: '调起内购会话框', version: '1.0.0', status: 1,
            }, {
                name: 'purchase_launch', params: [
                    {key: 'success', value_type: 'int', desc: '是否成功', enums: [0, 1]},
                    {key: 'sku', value_type: 'string', desc: '商品sku', enums: []},
                ], desc: '内购支付', trigger: '尝试发起支付', version: '1.0.0', status: 1,
            }, {
                name: 'purchase_result', params: [
                    {key: 'success', value_type: 'int', desc: '是否成功', enums: [0, 1]},
                    {key: 'sku', value_type: 'string', desc: '商品sku', enums: []},
                ], desc: '内购结果', trigger: '内购结果回调', version: '1.0.0', status: 1,
            }, {
                name: 'purchase_confirm', params: [
                    {key: 'sku', value_type: 'string', desc: '商品sku', enums: []},
                ], desc: '内购结果确认', trigger: '内购成功后主动向商店发起确认', version: '1.0.0', status: 1,
            }],
        }
    },
    methods: {
        addEventItem() {
            this.data.items.push({
                key: '',
                value: null,
                operator: '=',
                type: 'string',  // string/int/double
            })
        },
        removeEventItem(i) {
            this.data.items.splice(i, 1);
        },
        eventChange() {
            this.event_params = [];
            this.event_list.forEach(e => {
                if (this.data.name === e.name) {
                    this.event_params = e.params;
                }
            });
            this.adsdk_list.forEach(e => {
                if (this.data.name === e.name) {
                    this.event_params = e.params;
                }
            });
        },
        operatorChange(item) {
            if (item.operator === 'in') {
                item.values = [];
                delete item.value;
            } else {
                item.value = item.value || null;
                delete item.values;
            }
        },
        keyChange(item) {
            this.values = [];
            this.event_params.forEach(p => {
                if (p.key === item.key) {
                    item.type = p.value_type;
                    item.value = null;
                    item.operator = '=';
                    this.values = p.enums;
                }
            });
        },
        dimensionKeyChange() {
            this.data.dimension_fixed = false;
            this.data.dimension_type = 'int';
            this.event_params.forEach(p => {
                if (p.key === this.data.dimension_key) {
                    this.data.dimension_type = p.value_type;
                    this.data.dimension_fixed = true;
                }
            })
        }
    },
}
</script>

<style scoped>

</style>
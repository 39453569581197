<template>
    <el-dialog v-model="dialog_opened" width="30%" title="应用实验分组">
        <el-form label-width="100px">
            <el-form-item label="实验名称">
                {{exp.name}}
            </el-form-item>
            <el-form-item label="选择分组" :rules="[{required: true, message: '请选择实验分组'}]">
                <el-radio-group v-model="selected" :disabled="loading" >
                    <el-radio v-for="(g, i) in exp.groups" :key="i" :label="i">{{`${g.name}(${g.tag})`}}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <el-row style="text-align: right; margin-top: 10px">
            <el-button @click="reset()"  :loading="loading">取消</el-button>
            <el-button @click="apply()"  type="primary" :loading="loading">确认</el-button>
        </el-row>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';

export default {
    name: "ExpApplyDialog",
    data() {
        return {
            dialog_opened: false, loading: false, exp: {}, selected: null,
        }
    },
    methods: {
        openDialog(exp) {
            this.exp = exp;
            this.dialog_opened = true;
        },
        reset() {
            this.dialog_opened = false;
            this.loading = false;
            this.selected = null;
        },
        apply() {
            if (this.selected === null) {
                this.message.warning("请选择实验分组!");
                return
            }
            const api = this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + this.exp.id + '/status';
            this.$confirm('确定要应用该组吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.post(api, {status: 'finished', apply: this.selected}).then(res => {
                    this.$message.success("应用成功!")
                    this.$emit("afterSave");
                    this.reset();
                })
            }).catch(_ => {
            })
        },
    }
};
</script>

<style scoped>

</style>
<template>
    <el-button  type="success" @click="openDialog()" :disabled="data.length >= 16">创建层</el-button>
    <el-row style="margin-top: 10px">
        <el-table :data="data" v-loading="loading">
            <el-table-column label="名称" prop="name" width="150"></el-table-column>
            <el-table-column label="描述" prop="desc" width="250"></el-table-column>
            <el-table-column label="创建时间" width="200">
                <template #default="scope">
                    {{timestampToDate(scope.row.c_time)}}
                </template>
            </el-table-column>
            <el-table-column label="创建人" width="100">
                <template #default="scope">
                    <user v-model="scope.row.user_id" avatar></user>
                </template>
            </el-table-column>
            <el-table-column label="包含实验" width="300">
                <template #default="scope">
                    <el-button  @click="$refs.editor.openDialog(e.id)"
                               v-for="e in scope.row.exps" :key="e.id" type="primary" text
                               :style="{color: e.status === 'published' ? 'var(--el-color-success)' : e.status==='pending' ? '#409EFF' : 'var(--el-color-info)'}">
                        {{e.name}}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="" width="200">
                <template #default="scope">
                    <el-button  @click="openDialog(scope.row)">编辑</el-button>
                    <el-button  @click="removeItem(scope.row)" type="danger"
                               :disabled="scope.row.busy">禁用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-row>
    <el-dialog v-model="dialog_opened" :before-close="resetForm" width="80%"
               :close-on-click-modal="false">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="名称" prop="name" :rules="[{required: true, message: '请输入名称'}, {validator}]">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="desc">
                <el-input v-model="form.desc" type="textarea" :rows="4"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="resetForm">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submitForm">确定</el-button>
        </template>
    </el-dialog>
    <exp-editor ref="editor"></exp-editor>
</template>

<script>
import ExpEditor from "./ExpEditor";
import User from "../../../base/User";
import axios from 'ts-axios-new';
import {update, timestampToDate} from "../../../libs/utils";

export default {
    name: "LayerList",
    components: {User, ExpEditor},
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            form: {
                name: '',
                desc: '',
                product_id: this.$route.params.productId,
                user_id: this.$root.user.user_id,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer', {
                params: {
                    product_id: this.$route.params.productId,
                    hyper: true,
                }
            }).then(res => {
                res.data.data.layerList.forEach(l => {
                    let busy = false;
                    l.exps.forEach(e => {
                        if (e.status === 'published' || e.status === 'pending') {
                            busy = true;
                        }
                    });
                    l.busy = busy;
                });
                update(this.data, res.data.data.layerList);
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.editing = item;
                update(this.form, item);
            })
        },
        resetForm() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer/' + this.editing.id, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.resetForm();
                        })
                    } else {
                        axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer', this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.resetForm();
                        })
                    }
                }
            })
        },
        removeItem(item) {
            this.$confirm('确定删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                axios.delete(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer/' + item.id).then(res => {
                    this.data.splice(this.data.indexOf(item), 1);
                })
            }).catch(_ => {
            })
        },
        validator(rule, value, callback) {
            let valid = true;
            if (!this.editing) {
                this.data.forEach(d => {
                    if (d.name === value) {
                        valid = false;
                    }
                })
            }
            if (valid) {
                callback()
            } else {
                callback('存在同名层！');
            }
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp)
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.init();
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <span>
        <template v-for="u in $root.user_list" :key="u.user_id">
        <span v-if="modelValue===u.user_id">
            <img :src="u.avatar" style="width: 25px;vertical-align: middle" v-if="avatar"> {{u.nickname || u.name}}
        </span>
        </template>
    </span>
</template>

<script>
export default {
    name: "User",
    props: {
        modelValue: String,
        avatar: Boolean,
    },
}
</script>

<style scoped>

</style>

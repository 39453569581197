<template>
    <div class="aside-submenu" :class="className" @click="goto">
        <div style="width: 24px;text-align: center">
            <el-icon size="14px" style="vertical-align: middle">
                <component :is="icon"/>
            </el-icon>
        </div>
        <div>{{ title }}</div>
    </div>
</template>

<script>
export default {
    name: "AsideSubMenu",
    props: {
        title: String,
        disabled: Boolean,
        icon: String,
        index: String,
        query: Object,
    },
    methods: {
        goto() {
            this.$router.push({name: this.index, query: this.query});
        },
    },
    computed: {
        className() {
            if (this.disabled) {
                return 'disabled'
            } else {
                return this.$route.name === this.index && JSON.stringify(this.query || {}) === JSON.stringify(this.$route.query) ? 'is-active' : '';
            }
        }
    }
}
</script>

<style scoped>
.aside-submenu {
    font-size: 12px;
    color: var(--el-text-color-regular);
    padding-left: 50px;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    white-space: nowrap;
    position: relative;
    display: flex;
    line-height: 32px;
}

.aside-submenu:hover {
    background-color: var(--el-border-color-light);
    cursor: pointer;
    color: var(--el-text-color-primary);
}

.aside-submenu.disabled {
    cursor: not-allowed;
    color: var(--el-text-color-placeholder);
}

.aside-submenu.is-active {
    background-color: var(--el-border-color-lighter);
    color: var(--el-color-primary);
}
</style>
<template>
    <el-card shadow="never" style="min-width: 1000px">
        <template #header>报告设定</template>
        <el-form :model="form" label-width="0">
            <el-row>
                <el-col :span="6">
                    <el-form-item prop="filters.1.values" style="margin: 5px">
                        <p style="margin: 3px;font-size: 14px;color: #39434E;line-height: 15px">媒体渠道</p>
                        <el-select v-model="form.filters[1].values" multiple filterable
                                   style="width: 100%" placeholder="媒体渠道" :disabled="loading">
                            <el-option v-for="m in filters.media_source" :value="m" :key="m"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item prop="filters.0.values" style="margin: 5px;">
                        <p style="margin: 3px;font-size: 14px;color: #39434E;line-height: 15px">国家/地区</p>
                        <country-selector v-model="form.filters[0].values" width="100%"
                                          :disabled="loading" :wanted="countryWanted"></country-selector>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="date">
                    <el-form-item style="margin: 5px;float: right">
                        <p style="margin: 3px;font-size: 12px;color: #39434E;line-height: 15px">日期范围</p>
                        <date-range-picker v-model="form" :disabled="loading"
                                           :disabled-date="disabledDate"></date-range-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="dimensions">
                <el-col :span="6">
                    <el-form-item prop="groupings" style="margin: 5px;">
                        <p style="margin: 3px;font-size: 14px;color: #39434E;line-height: 15px">分组维度</p>
                        <el-select v-model="form.dimensions" multiple collapse-tags
                                   style="width: 100%" placeholder="分组维度" :disabled="loading">
                            <el-option-group label="地理位置（依据IP地址）">
                                <el-option label="国家/地区" value="country"></el-option>
                                <el-option label="城市" value="city"></el-option>
                            </el-option-group>
                            <el-option-group label="安装信息">
                                <el-option label="激活日期" value="date"></el-option>
                                <el-option label="app版本" value="app_version"></el-option>
                            </el-option-group>
                            <el-option-group label="投放信息（依据AF归因）">
                                <el-option label="媒体渠道" value="media_source"></el-option>
                                <el-option label="Campaign ID" value="campaign_id"></el-option>
                                <el-option label="Campaign名称" value="campaign_name"></el-option>
                                <el-option label="AdSet ID" value="adset_id"></el-option>
                                <el-option label="Ad ID" value="ad_id"></el-option>
                                <el-option label="Site ID" value="site_id"></el-option>
                            </el-option-group>
                            <el-option-group label="设备信息">
                                <el-option label="手机/平板" value="device_category"></el-option>
                                <el-option label="品牌" value="device_brand_name"></el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6" v-if="minInstalls">
                    <el-form-item prop="min_installs" style="margin: 5px"
                                  :rules="[{required: true, type: 'number', message: '请填写最低激活数量（正整数）'}]">
                        <p style="margin: 3px;font-size: 14px;color: #39434E;line-height: 15px">最低激活数量</p>
                        <el-input v-model.number="form.min_installs" style="width: 100px"
                                  :disabled="loading"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider style="margin: 15px"></el-divider>
            <el-row v-for="(f, i) in form.filters">
                <template v-if="i > 1">
                    <el-row style="margin-bottom: 20px">
                        <el-select v-model="f.key" :disabled="loading" @change="filterChange(f)"
                                   style="width: 160px;">
                            <el-option-group label="用户信息">
                                <el-option label="app版本" value="app_version"></el-option>
                                <el-option label="生命周期" value="living_days"></el-option>
                                <el-option label="用户群组" value="cohort"></el-option>
                                <el-option label="AB测Tag" value="abtest_tag"></el-option>
                            </el-option-group>
                            <el-option-group label="投放信息（依据AF归因）">
                                <el-option label="Campaign ID" value="campaign_id"></el-option>
                                <el-option label="Campaign名称" value="campaign_name"></el-option>
                                <el-option label="AdSet ID" value="adset_id"></el-option>
                                <el-option label="AdSet" value="adset_name"></el-option>
                                <el-option label="Ad ID" value="ad_id"></el-option>
                                <el-option label="Ad" value="ad_name"></el-option>
                                <el-option label="Site ID" value="site_id"></el-option>
                            </el-option-group>
                            <el-option-group label="设备信息">
                                <el-option label="手机/平板" value="device_category"></el-option>
                            </el-option-group>
                        </el-select>
                        <el-select v-model="f.operator" :disabled="loading"
                                   style="width: 100px;margin-left: 10px">
                            <el-option-group v-if="f.key === 'living_days'">
                                <el-option value=">"></el-option>
                                <el-option value=">="></el-option>
                                <el-option value="="></el-option>
                                <el-option value="<="></el-option>
                                <el-option value="<"></el-option>
                            </el-option-group>
                            <el-option-group v-else-if="f.key === 'abtest_tag'">
                                <el-option label="LIKE" value="like"></el-option>
                            </el-option-group>
                            <el-option-group v-else>
                                <el-option label="IN" value="in"></el-option>
                                <el-option label="NOT IN" value="not in"></el-option>
                            </el-option-group>
                        </el-select>
                        <el-card shadow="never" :body-style="{padding: '2px', display: 'flex'}" v-if="f.key==='cohort'"
                                 style="width: 300px;margin-left: 10px">
                            <div style="width: 270px;min-height: 22px">
                                <el-tag type="info" closable v-for="v in f.values"
                                        @close="f.values.splice(v, 1)" @click="openCohort(f, v)"
                                        style="vertical-align: middle;cursor: pointer">{{ v.label }}
                                </el-tag>
                            </div>
                            <el-icon style="cursor: pointer;" @click="openCohort(f)" class="color-info" size="22px">
                                <Plus/>
                            </el-icon>
                        </el-card>
                        <el-input v-model.number="f.value" :disabled="loading"
                                  style="width: 300px;margin-left: 10px" v-else-if="f.key==='living_days'"></el-input>
                        <el-input v-model="f.value" :prop="`filters.${i}.value`"
                                  v-else-if="f.key==='abtest_tag'" style="width: 300px;margin-left: 10px"
                                  :disabled="loading" :required="f.fixed"></el-input>
                        <el-select v-model="f.values" :disabled="loading" multiple filterable
                                   style="width: 300px;margin-left: 10px" v-else>
                            <el-option v-for="ii in filters[f.key]" :value="ii"></el-option>
                        </el-select>
                        <el-icon @click="removeFilter(i)" style="cursor: pointer;margin: 8px;">
                            <Delete/>
                        </el-icon>
                    </el-row>
                </template>
            </el-row>
            <div>
                <el-button icon="Plus" :disabled="loading" @click="addFilter"> 添加更多过滤项</el-button>
                <el-button icon="Filter" style="float: right" type="primary" @click="submit" :loading="loading" size="default">
                    运行
                </el-button>
            </div>
        </el-form>
    </el-card>
    <el-dialog v-model="dialog_opened" :before-close="cancelCohort">
        <el-form :model="cohort" ref="cohort" label-width="100px">
            <el-form-item label="如何划分用户" prop="category">
                <el-radio-group v-model="cohort.category">
                    <el-radio-button label="metric">指标条件</el-radio-button>
                    <el-radio-button label="event">事件事件</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <template v-if="cohort.category === 'metric'">
                <el-form-item label="指标名"  :rules="[{required: true, message: '请选择一个指标'}]"
                              prop="metric.id">
                    <el-select v-model="cohort.metric.id"  :disabled="loading" style="width: 210px">
                        <el-option v-for="m in metric_list" :value="m.id" :label="m.name">
                            {{ m.name }} <span style="font-size: 10px;" class="color-info">({{ m.description }})</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
            <template v-else>
                <el-form-item label="统计方式" prop="event.category" >
                    <el-select  :disabled="loading" v-model="cohort.event.category">
                        <el-option value="event_count" label="事件发生次数"></el-option>
                        <el-option value="value_sum" label="事件Value求和"></el-option>
                    </el-select>
                </el-form-item>
                <event-selector  :disabled="loading" :data="cohort.event"
                                prop="event" :dimension-key="cohort.event.category === 'value_sum'"
                                label="事件名"></event-selector>
            </template>
            <template v-for="(c, i) in cohort[cohort.category].value_filters">
                <number-input  v-model.number="c.value" width="100px"
                              :prop="`${cohort.category}.value_filters.${i}.value`" :disabled="loading" label="取值">
                    <template #prefix>
                        <el-select v-model="c.operator"  style="width: 100px;margin-right: 10px"
                                   :disabled="loading">
                            <el-option value=">="></el-option>
                            <el-option value=">"></el-option>
                            <el-option value="="></el-option>
                            <el-option value="<"></el-option>
                            <el-option value="<="></el-option>
                        </el-select>
                    </template>
                    <template #append>
                        <el-button type="danger"  @click="removeValueFilter(i)"
                                   style="margin-left: 10px">删除
                        </el-button>
                    </template>
                </number-input>
            </template>
            <el-form-item  label=" ">
                <el-button  type="primary" v-if="cohort[cohort.category].value_filters.length < 2"
                           @click="addValueFilter">添加条件
                </el-button>
            </el-form-item>
            <el-divider></el-divider>
            <template v-for="(f, i) in cohort.filters">
                <el-form-item label="过滤项">
                    <div style="display: flex;justify-content: flex-start">
                        <el-select v-model="f.key" :disabled="loading" @change="f.value=null"
                                   style="width: 160px;">
                            <el-option label="生命周期" value="living_days"></el-option>
                            <el-option label="版本" value="app_version"></el-option>
                        </el-select>
                        <el-select v-model="f.operator" :disabled="loading"
                                   style="width: 100px;margin-left: 10px">
                            <el-option value=">"></el-option>
                            <el-option value=">="></el-option>
                            <el-option value="="></el-option>
                            <el-option value="<="></el-option>
                            <el-option value="<"></el-option>
                        </el-select>
                        <number-input :disabled="loading" v-model="f.value" int-only label-width="0"
                                      v-if="f.key==='living_days'" :prop="'filters.' + i + '.value'"
                                      style="width: 120px;flex: 0 0 auto;margin-left: 10px"></number-input>
                        <el-input  v-model="f.value" :prop="`filters.${i}.value`"
                                  v-else-if="f.key==='abtest_tag'" style="width: calc(100% - 220px)"
                                  width="100%" :disabled="loading" :required="f.fixed"></el-input>
                        <el-form-item label-width="0" :rules="[{validator: versionValidator}]"
                                      style="width: 120px;flex: 0 0 auto;margin-left: 10px"
                                      v-else-if="f.key === 'app_version'" :prop="'filters.' + i + '.value'">
                            <el-input v-model="f.value"></el-input>
                        </el-form-item>
                        <el-input v-model="f.value" style="width: 120px;margin-left: 10px"
                                  v-else></el-input>
                        <el-icon @click="removeCohortFilter(i)" style="cursor: pointer;margin: 8px">
                            <Delete/>
                        </el-icon>
                    </div>
                </el-form-item>
            </template>
            <el-form-item label=" ">
                <el-button icon="Plus" :disabled="loading" @click="addCohortFilter"> 添加更多过滤项</el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text @click="cancelCohort">取消</el-button>
            <el-button type="primary" @click="submitCohort">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {timestampToDate, update, deepcopy, generateUuid, versionValidator} from "../libs/utils";
import CountrySelector from "./CountrySelector";
import axios from 'ts-axios-new';
import DateRangePicker from "./DateRangePicker";
import NumberInput from "./NumberInput";
import EventSelector from "./EventSelector";

export default {
    name: "CohortForm",
    components: {EventSelector, NumberInput, DateRangePicker, CountrySelector},
    props: {
        loading: Boolean,
        minInstalls: Boolean,
        dimensions: Boolean,
        countryWanted: {
            type: String,
            default: 'ip_name',
        },
        date: {
            type: Boolean,
            default: true,
        },
        previous: {
            type: Number,
            default: 2,
        },
    },
    emits: ['submit'],
    data() {
        const now = Date.now();
        return {
            inner_loading: false, dialog_opened: false, editing_filter: null, metric_list: [], editing_cohort: null,
            form: {
                start: timestampToDate(now - (this.previous + 6) * 24 * 3600 * 1000),
                end: timestampToDate(now - this.previous * 24 * 3600 * 1000),
                filters: [
                    {key: 'country', operator: 'in', values: []},
                    {key: 'media_source', operator: 'in', values: []},
                ],
                dimensions: ['media_source'],
                min_installs: 100,
                source: 'bq',
            },
            filters: {
                app_version: [],
                media_source: [],
                campaign_id: [],
                campaign_name: [],
                adset_id: [],
                adset_name: [],
                ad_id: [],
                ad_name: [],
                site_id: [],
                device_category: ['mobile', 'tablet'],
                cohort: [],
            },
            cohort: {
                filters: [],  // living_days / app_version
                metric: {
                    id: '',
                    value_filters: [{operator: '>', value: 0}],
                },
                event: {
                    name: '',
                    items: [],
                    dimension_key: '',
                    dimension_type: '',
                    category: 'event_count',  // event_count / value_sum
                    value_filters: [{operator: '>', value: 0}],
                },
                category: 'metric',  // metric / event
            },
            disabledDate(time) {
                return time.getTime() < new Date('2021-09-01 GMT+0800') || time.getTime() > Date.now() - 24 * 3600 * 1000;
            },
        }
    },
    methods: {
        filterChange(f) {
            if (f.key === 'living_days') {
                f.operator = '>';
                f.value = 0;
                delete f.values;
            } else if (f.key === 'abtest_tag') {
                f.operator = 'like';
                f.value = null;
                delete f.values;
            } else {
                f.operator = 'in';
                delete f.value;
                f.values = [];
            }
        },
        versionValidator(rules, value, callback) {
            return versionValidator(rules, value, callback);
        },
        resetCohort() {
            this.$refs.cohort.resetFields();
            this.editing_filter = this.editing_cohort = null;
            this.cohort = {
                filters: [],  // living_days / app_version
                metric: {
                    id: '',
                    value_filters: [{operator: '>', value: 0}],
                },
                event: {
                    name: '',
                    items: [],
                    dimension_key: '',
                    dimension_type: '',
                    category: 'event_count',  // event_count / value_sum
                    value_filters: [{operator: '>', value: 0}],
                },
                category: 'metric',  // metric / event
            }
        },
        cancelCohort() {
            this.resetCohort();
            this.dialog_opened = false;
        },
        openCohort(filter, item) {
            this.editing_filter = filter;
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing_cohort = item;
                    update(this.cohort, item);
                }
            })
        },
        submitCohort() {
            this.$refs.cohort.validate(valid => {
                if (valid) {
                    const cohort = deepcopy(this.cohort);
                    cohort.id = generateUuid();
                    if (cohort.category === 'metric') {
                        this.metric_list.forEach(m => {
                            if (m.id === cohort.metric.id) {
                                cohort.label = m.name + cohort.metric.value_filters[0].operator + cohort.metric.value_filters[0].value;
                            }
                        })
                    } else {
                        cohort.label = `${cohort.event.name}${{
                            event_count: '事件计数',
                            value_sum: '键值求和'
                        }[cohort.event.category]}${cohort.event.value_filters[0].operator}${cohort.event.value_filters[0].value}`;
                    }
                    if (this.editing_cohort) {
                        update(this.editing_cohort, cohort);
                    } else {
                        this.editing_filter.values.push(cohort);
                    }
                    this.resetCohort();
                    this.dialog_opened = false;
                }
            })
        },
        init() {
            this.inner_loading = true;
            const now = Date.now();
            axios.all([
                axios.get(`/api/v1/media_source/options/${this.$route.params.productId}`, {
                    params: {
                        start: timestampToDate(now - 7 * 24 * 3600 * 1000),
                        end: timestampToDate(now),
                    }
                }).then(res => {
                    update(this.filters, res.data.data);
                }), axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.filters.app_version = [];
                    res.data.data.versionList.forEach(v => {
                        this.filters.app_version.push(v.version);
                    })
                }), axios.get('/api/v1/metric', {
                    params: {
                        product_id: this.$route.params.productId,
                        contain_preset: 1
                    }
                }).then(res => {
                    this.metric_list = res.data.data.models.filter(m => {
                        return ['event_count', 'event_rate', 'event_value_sum'].indexOf(m.category) > -1 && !m.deleted
                    });
                }),
            ]).then(_ => {
                this.inner_loading = false;
            })
        },
        submit() {
            this.$emit('submit');
        },
        getData() {
            return this.form;
        },
        addFilter() {
            this.form.filters.push({key: '', operator: 'in', values: []});
        },
        removeFilter(i) {
            this.form.filters.splice(i, 1);
        },
        removeValueFilter(i) {
            this.cohort[this.cohort.category].value_filters.splice(i, 1);
        },
        addValueFilter() {
            this.cohort[this.cohort.category].value_filters.push({operator: '<', value: 1});
        },
        addCohortFilter() {
            this.cohort.filters.push({key: '', operator: '=', value: null})
        },
        removeCohortFilter(i) {
            this.cohort.filters.splice(i, 1);
        },
    },
    mounted() {
        if (this.form.start < '2021-09-01') {
            this.form.start = '2021-09-01';
            this.form.end = '2021-09-07';
        }
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init();
        },
    }
}
</script>

<style scoped>
.el-switch ::v-deep(.el-switch__label--left) {
    color: var(--el-text-color-placeholder)
}

.el-switch ::v-deep(.el-switch__label--right) {
    color: var(--el-text-color-placeholder)
}

.el-switch ::v-deep(.el-switch__label--left.is-active) {
    color: #13ce66
}

.el-switch ::v-deep(.el-switch__label--right.is-active) {
    color: #409EFF
}
.el-form-item ::v-deep(.el-form-item__content) {
    display: block;
}
</style>

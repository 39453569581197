<template>
    <div style="padding: 0 30px">
        <cohort-form :loading="loading" dimensions ref="form" @submit="init"></cohort-form>
        <div style="display: flex;justify-content: flex-end">
            <sql-runtime ref="timer" :stat="timer_stat" style="float: right;margin-right: 20px"></sql-runtime>
        </div>
        <el-card shadow="never">
            <downloadable-table :loading="loading" :data="data" title="用户时长数据">
                <el-table-column v-for="h in dims" width="200" :label="h" :prop="h" sortable></el-table-column>
                <el-table-column label="日人均session" prop="session_count" width="200">
                    <template #default="scope">
                        {{(scope.row.session_count / scope.row.dau).toFixed(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="日人均时长" prop="duration_seconds" width="200">
                    <template #default="scope">
                        {{formatDuration(scope.row.duration_seconds / scope.row.dau * 1000)}}
                    </template>
                </el-table-column>
                <el-table-column label="平均session时长" width="200">
                    <template #default="scope">
                        {{formatDuration(scope.row.duration_seconds / scope.row.session_count * 1000)}}
                    </template>
                </el-table-column>
            </downloadable-table>
        </el-card>
    </div>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import SqlRuntime from "../../base/SqlRuntime";
import DownloadableTable from "../../base/DownloadableTable";
import axios from 'ts-axios-new';
import {update, formatDuration, formatInt} from "../../libs/utils";

export default {
    name: "Engagement",
    components: {DownloadableTable, SqlRuntime, CohortForm},
    data() {
        return {
            loading: false, data: [], headers: [], dims: [], timer_stat: {
                total_bytes_processed: 0, duration: 0, message: null, sql: ''
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            this.$refs.timer.start();
            const form = this.$refs.form.getData();
            this.dims = [];
            axios.post(`/api/v1/engagement/${this.$route.params.productId}/report`, form).then(res => {
                this.headers = res.data.data.headers;
                this.dims = form.dimensions;
                update(this.timer_stat, res.data.data);
                this.loading = false;
                this.$refs.timer.success();
                this.data = [];
                res.data.data.data.forEach(d => {
                    let valid = true;
                    this.dims.forEach(dim => {
                        if (!d[dim]) {
                            valid = false;
                        }
                    })
                    if (valid) {
                        this.data.push(d);
                    }
                });
            }).catch(err => {
                this.loading = false;
                this.$refs.timer.error(err.response.data.status.message);
            })
        },
        formatDuration(duration) {
            return formatDuration(duration)
        },
        formatInt(number) {
            return formatInt(number);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId)
                this.init()
        }
    }
}
</script>

<style scoped>

</style>
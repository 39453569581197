<template>
    <div style="padding: 0 30px">
      <el-table :data="data" v-loading="loading" style="width: 1100px;margin-top: 10px">
        <el-table-column prop="product_id" width="250" label="产品ID"></el-table-column>
<!--        <el-table-column prop="product_name" width="100" label="产品名"></el-table-column>-->
        <el-table-column prop="version" width="200" label="当前最新版本">
          <el-input v-model="form.version" placeholder="输入版本号" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
        <el-table-column prop="min_version" width="200" label="最小版本">
          <el-input v-model="form.min_version" placeholder="输入版本号" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
        <el-table-column prop="show_interval" width="200" label="提示间隔">
          <el-input v-model="form.show_interval" @input="validateNumber" placeholder="间隔时间" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
        <el-table-column prop="force_interval" width="200" label="强制提示时间">
          <el-input v-model="form.force_interval" @input="validateNumber" placeholder="间隔时间" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
        <el-table-column v-if="this.$route.params.productId === '6672810e24ab13ac3f3cb17b'" prop="infi_ads_open" width="200" label="Infi广告开关">
          <el-select style="width: 80px" v-model="form.infi_ads_open" @change="submit">
            <el-option label="打开" :value="true"></el-option>
            <el-option label="关闭" :value="false"></el-option>
          </el-select>
        </el-table-column>
        <el-table-column v-if="this.$route.params.productId === '6672810e24ab13ac3f3cb17b'" prop="infi_ads_days" width="200" label="Infi用户天数">
          <el-input v-model="form.infi_ads_days" placeholder="输入天数" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
        <el-table-column v-if="this.$route.params.productId === '6672810e24ab13ac3f3cb17b'" prop="infi_ads_level" width="200" label="Infi开启关数">
          <el-input v-model="form.infi_ads_level" placeholder="输入关数" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
        <el-table-column v-if="this.$route.params.productId === '6672810e24ab13ac3f3cb17b'" prop="infi_ads_conf_version" width="200" label="Infi配置版本">
          <el-input v-model="form.infi_ads_conf_version" placeholder="输入版本" style="width: 150px" @change="submit()"></el-input>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";

export default {
    name: "Update",
    components: {},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [],
            form: {
              product_id: this.$route.params.productId,
              version: "1.0.0",
              min_version: null,
              show_interval: null,
              force_interval: null,
              infi_ads_open: true,
              infi_ads_level: 5,
              infi_ads_days: 3,
              infi_ads_conf_version: 1,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`${this.$root.$getElasticDomain()}/cms/v1/update/${this.$route.params.productId}`).then(res => {
              const result = res.data.data;
              if (result != null) {
                update(this.form, result)
              }
              this.data = [this.form]
              this.loading = false;
            })
        },
        submit() {
          this.loading = true;
          axios.post(`${this.$root.$getElasticDomain()}/cms/v1/update/${this.$route.params.productId}`, this.form).then(_ => {
            this.loading = false;
          })
        },
        validateNumber() {
          if(this.form.show_interval){
              this.form.show_interval = this.form.show_interval.replace(/\D/g, '');
          }
          if(this.form.force_interval) {
              this.form.force_interval = this.form.force_interval.replace(/\D/g, '');
          }
          if(this.form.infi_ads_days) {
              this.form.infi_ads_days = this.form.infi_ads_days.replace(/\D/g, '');
          }
          if(this.form.infi_ads_level) {
            this.form.infi_ads_level = this.form.infi_ads_level.replace(/\D/g, '');
          }
          if(this.form.infi_ads_conf_version) {
            this.form.infi_ads_conf_version = this.form.infi_ads_conf_version.replace(/\D/g, '');
          }
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>

</style>
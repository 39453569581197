<template>
    <div v-loading="loading">
        <div v-if="data.length > 0" :id="metric.id" style="position: relative; width: 100%; height: 270px"></div>
        <NoDataChart v-else :title="metric ? metric.name : 'Unknown'" :id="metric.id" width="100%"
                     height="270px"></NoDataChart>
    </div>
</template>

<script>
import NoDataChart from "./NoDataChart";
import axios from 'ts-axios-new';
import {formatDuration} from "../../../libs/utils";

export default {
    name: "VersionMetricChart",
    props: ['versions', 'countries', 'start', 'end', 'metric'],
    components: {NoDataChart},
    data() {
        return {
            data: [], y_values: [], loading: false, headers: [],
        }
    },
    methods: {
        formatForm(version) {
            let form = {
                product_id: this.$route.params.productId,
                start: this.start, end: this.end, metric_id: this.metric.id,
                dimensions: [],
                filters: [
                    {key: 'app_version', operator: '=', values: [version]},
                ]
            };
            if (this.countries.length > 0) {
                form.filters.push({key: 'country', operator: 'in', values: this.countries});
            }
            if (version.includes('<')) {
                form.filters[0].values = [version.slice(2)];
                form.filters[0].operator = '<';
            }
            return form;
        },
        formatTable(version, data) {
            const percentage_category = ['event_rate', 'retain_7', 'user_rate'];
            data.forEach(row => {
                row.app_version = version;
                if (this.metric) {
                    if (percentage_category.includes(this.metric.category)) {
                        row.val = row.val === null ? null : parseInt(row.val * 10000) / 100;
                    } else if (this.metric.category === 'duration') {
                        row.val = row.val === null ? null : parseInt(row.val / 600) / 100;
                    }
                }
                this.data.push(row);
            })
        },
        fetchData() {
            this.loading = true;
            const promises = [];
            this.headers.splice(0, this.headers.length);
            this.data.splice(0, this.data.length);
            this.versions.forEach(v => {
                promises.push(new Promise((resolve, reject) => {
                        axios.post(`/api/v1/metric/${this.metric.id}/report`, this.formatForm(v)).then(res => {
                            this.formatTable(v, res.data.data.data);
                            resolve(res)
                        }).catch(err => {
                            reject(err)
                        })
                    })
                );
            });
            axios.all(promises).then(_ => {
                this.data.sort(function (a, b) {
                    return a.date < b.date ? -1 : 1;
                });
                this.loading = false;
                this.$nextTick(_ => {
                    this.drawChart();
                });
            }).catch(err => {
                this.loading = false;
            })

        },
        formatSeries() {
            let series = [];
            this.versions.forEach(version => {
                const ser = {
                    type: 'line',
                    name: version,
                    data: [],
                };
                this.data.forEach(row => {
                    if (row['app_version'] === version) {
                        ser.data.push([row.date, row.val])
                    }
                });
                series.push(ser);
            });
            return series;
        },
        drawChart() {
            const chart = echarts.init(document.getElementById(this.metric.id));
            const option = {
                title: {text: this.metric.name},
                legend: {show: true},
                tooltip: {
                    trigger: "axis",
                    axisPointer: {type: "shadow"},
                },
                yAxis: {
                    type: 'value',
                    min: function (value) {
                        return value.min * 1.5 - value.max * 0.5 < 0 ? 0 : Math.floor(value.min * 1.5 - value.max * 0.5);
                    },
                    max: function (value) {
                        return Math.ceil(value.max * 1.5 - value.min * 0.5);
                    },
                    axisLabel: {
                        formatter: value => {
                            if (this.metric.category === 'duration') {
                                return formatDuration(value);
                            } else {
                                if (value >= 10 ** 9) {
                                    return (value / 10 ** 9).toFixed(1) + 'b';
                                } else if (value >= 10 ** 6) {
                                    return (value / 10 ** 6).toFixed(1) + 'm';
                                } else if (value >= 10 ** 3) {
                                    return (value / 10 ** 3).toFixed(1) + 'k';
                                } else {
                                    return value
                                }
                            }
                        }
                    }
                },
                series: this.formatSeries(),
                xAxis: {
                    type: 'category',
                    // data: this.xAxis,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        formatter: function (value, index) {
                            return value.length === 10 ? value.slice(5) : value;
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#7d7b7b',
                        }
                    }
                }
            };
            chart.setOption(option, true);
        },
    },
    // mounted() {
    //     this.fetchData();
    // },
}
</script>

<style scoped>

</style>

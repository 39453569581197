<template>
    <div class="aside-item" @click.stop="goto" :class="className">
        <div style="width: 56px">
            <el-icon size="20px" style="vertical-align: middle">
                <el-tooltip v-if="icon && collapsed" placement="right" :content="title">
                    <component :is="icon"></component>
                </el-tooltip>
                <component :is="icon" v-else-if="icon"></component>
            </el-icon>
        </div>
        <span v-if="!collapsed">{{ title }}</span>
    </div>
</template>

<script>
export default {
    name: "AsideItem",
    props: {
        index: String,
        icon: String,
        disabled: Boolean,
        fixed: Boolean,
        title: String,
        query: String,
        unfixable: Boolean,
        defaultCollapsed: Boolean,
    },
    inject: ['parentDisabled', 'collapsed', 'collapse'],
    computed: {
        className() {
            let class_name = '';
            if (this.$route.name === this.index && JSON.stringify(this.query || {}) === JSON.stringify(this.$route.query))
                class_name += 'is-active ';
            else {
                let c = '';
                this.$route.matched.forEach(m => {
                    if (m.name === this.index) {
                        c = 'is-active ';
                    }
                });
                class_name += c;
            }
            if (this.disabled || this.parentDisabled) {
                class_name += 'disabled '
            }
            return class_name;
        }
    },
    methods: {
        goto() {
            if (this.disabled || this.parentDisabled)
                return
            if (this.index && this.index !== this.$route.name) {
                this.$router.push({name: this.index})
            }
        },
    },
    watch: {
        $route() {
            if (this.index && this.index === this.$route.name) {
                if (this.defaultCollapsed) {
                    this.collapse();
                }
            }
        }
    }
}
</script>

<style scoped>
.aside-item {
    font-size: 14px;
    color: var(--el-text-color-regular);
    padding-left: 4px;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    white-space: nowrap;
    line-height: 40px;
    display: flex;
    text-align: center;
}

.aside-item:hover {
    background-color: var(--el-border-color-light);
    cursor: pointer;
    color: var(--el-text-color-primary);
}

.aside-item.disabled {
    cursor: not-allowed;
    color: var(--el-text-color-placeholder);
}

.aside-item.is-active {
    background-color: var(--el-border-color-lighter);
    color: var(--el-color-primary);
}
</style>
<template>
    <el-dialog v-model="dialog_opened" title="新增/编辑指标" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="指标名" prop="name" :rules="[{required: true, message: '请输入指标名'}]">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="指标描述" prop="description" :rules="[{required: true, message: '请描述指标详情'}]">
                <el-input type="textarea" :rows="5" v-model="form.description"></el-input>
            </el-form-item>
            <el-form-item label="指标类型" prop="category" :rules="[{required: true, message: '请选择指标类型'}]">
                <el-select v-model="form.category" style="width: 300px" filterable @change="changeCategory">
                    <el-option-group label="固定指标">
                        <el-option v-for="f in fixed_metric" :label="f.name" :value="f.value"></el-option>
                    </el-option-group>
                    <el-option-group label="计数指标">
                        <el-option label="事件计数" value="event_count"></el-option>
                        <el-option label="用户计数" value="user_count"></el-option>
                        <el-option label="事件value求和" value="sum_value"></el-option>
                    </el-option-group>
                    <el-option-group label="比率指标">
                        <el-option label="事件比率" value="event_rate"></el-option>
                        <el-option label="用户比率" value="user_rate"></el-option>
                        <el-option label="用户日活比率" value="user_head_rate"></el-option>
                    </el-option-group>
                    <el-option-group label="日活均值指标">
                        <el-option label="日活人均事件数" value="event_per_head"></el-option>
                        <el-option label="日活value均值" value="sum_value_per_head"></el-option>
                    </el-option-group>
                    <el-option-group label="用户均值指标">
                        <el-option label="用户人均事件数" value="event_per_user"></el-option>
                        <el-option label="用户value均值" value="sum_value_per_user"></el-option>
                    </el-option-group>
                    <el-option-group label="事件均值指标">
                        <el-option label="事件value均值" value="sum_value_per_event"></el-option>
                    </el-option-group>
                    <!--                    <el-option-group label="复合指标">-->
                    <!--                        <el-option label="(A+B)/C" value="combination"></el-option>-->
                    <!--                    </el-option-group>-->
                </el-select>
            </el-form-item>
            <number-input v-if="form.category.indexOf('retain') > -1" int-only v-model="form.atomic[0].interval"
                          placeholder="第N日留存" label="留存天数" prop="atomic.0.interval" required
                          width="100px"></number-input>
            <template v-for="(metric, i) in form.atomic">
                <event-selector v-if="metric.name !== undefined"  :label="`事件${upper_letter[i]}`"
                                :disabled="loading" :prop="`atomic.${i}`" :data="metric"
                                :dimension-key="metric.dimension_key"></event-selector>
            </template>
            <el-form-item>
                <div style="font-size: 24px">
                    <span v-if="form.category === 'dau_retain'">
                        $$ f = \frac{留存人数}{活跃人数} * 100 \% $$
                    </span>
                    <span v-else-if="form.category.indexOf('retain') > -1">
                        $$ f = \frac{留存人数}{安装人数} * 100 \% $$
                    </span>
                    <span v-else-if="form.category === 'duration'">
                        $$ f = \frac{\sum 单日时长}{活跃人数} $$
                    </span>
                    <span v-else-if="form.category === 'user_rate'">
                        $$ f(A, B) = \frac{发生事件A的人数}{发生事件B的人数} {{form.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
                    </span>
                    <span v-else-if="form.category === 'event_rate'">
                        $$ f(A, B) = \frac{事件A的发生次数}{事件B的发生次数} {{form.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
                    </span>
                    <span v-else-if="form.category === 'user_head_rate'">
                        $$ f(A) = \frac{发生事件A的人数}{日活} {{form.metric_type === 'percent' ? ' * 100 \\%' : ''}} $$
                    </span>
                    <span v-else-if="form.category === 'event_per_head'">
                        $$ f(A) = \frac{事件A的发生次数}{日活} $$
                    </span>
                    <span v-else-if="form.category === 'sum_value_per_head'">
                        $$ f(A) = \frac{\sum 事件A聚合key对应的value}{日活} $$
                    </span>
                    <span v-else-if="form.category === 'event_per_user'">
                        $$ f(A, B) = \frac{事件A的发生次数}{发生事件B的人数} $$
                    </span>
                    <span v-else-if="form.category === 'sum_value_per_user'">
                        $$ f(A, B) = \frac{\sum 事件A聚合key对应的value}{发生事件B的人数} $$
                    </span>
                    <span v-else-if="form.category === 'sum_value_per_event'">
                        $$ f(A, B) = \frac{\sum 事件A聚合key对应的value}{事件B的发生次数} $$
                    </span>
                </div>
            </el-form-item>
            <el-form-item v-if="form.category.indexOf('rate') > -1">
                <el-switch v-model="form.metric_type" active-value="percent" active-text="显示百分比" inactive-text="显示原数字"
                           inactive-value="number" @change="refreshTex"></el-switch>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {update, deepcopy} from "../../../libs/utils";
import EventSelector from "../../../base/EventSelector";
import axios from 'ts-axios-new';
import NumberInput from "../../../base/NumberInput";
import {FixedMetricCategoryList} from "./data";

export default {
    name: "MetricEditor",
    components: {NumberInput, EventSelector},
    data() {
        return {
            dialog_opened: false, editing: null, loading: false, upper_letter: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            fixed_metric: FixedMetricCategoryList,
            form: {
                name: '',
                description: '',
                category: '',
                atomic: [],
                formula: '',
                metric_type: 'number',
            },
        }
    },
    methods: {
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
                this.refreshTex();
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.atomic.splice(0, this.form.atomic.length);
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.form.product_id = this.$route.params.productId;
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/api/v1/metric/${this.editing.id}`, this.form).then(res => {
                            // 如果指标有含义上的变化，那么增长看板含有该指标的缓存要清除
                            if (this.editing.category !== res.data.data.category || JSON.stringify(this.editing.atomic) !== JSON.stringify(res.data.data.atomic)) {
                                axios.delete(`/api/v1/growth/component/data`, {data: {metric_id: this.editing.id}}).then(res => {
                                })
                            }
                            update(this.editing, res.data.data);
                            this.reset();
                        });
                    } else {
                        axios.post(`/api/v1/metric`, this.form).then(res => {
                            this.$emit('add', res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        changeCategory() {
            this.form.atomic = [];
            this.form.metric_type = 'number';
            this.fixed_metric.forEach(m => {
                if (m.value === this.form.category) {
                    this.form.atomic[0] = {
                        cal_category: this.form.category,
                        interval: 1,
                    }
                    this.form.formula = 'A';
                }
            });
            if (this.form.atomic.length < 1) {
                this.form.atomic[0] = {
                    name: '',
                    items: [],
                }
                this.form.formula = 'A';
                // 确定第一个事件的category
                if (this.form.category.startsWith('event')) {
                    this.form.atomic[0].cal_category = 'event_count';
                } else if (this.form.category.startsWith('user')) {
                    this.form.atomic[0].cal_category = 'user_count';
                } else if (this.form.category.startsWith('sum_value')) {
                    this.form.atomic[0].cal_category = 'sum_value';
                    this.form.atomic[0].dimension_key = '';
                    this.form.atomic[0].dimension_type = '';
                }
                // 确定第二个事件的category
                if (this.form.category.indexOf('rate') > -1) {
                    this.form.atomic[1] = deepcopy(this.form.atomic[0]);
                    this.form.formula = 'A / B'
                    this.form.metric_type = 'percent';
                }
                if (this.form.category.indexOf('head') > -1) {
                    this.form.atomic[1] = {
                        cal_category: 'dau',
                    }
                    this.form.formula = 'A / B'
                }
                if (this.form.category.indexOf('per_user') > -1) {
                    this.form.atomic[1] = {
                        name: '',
                        items: [],
                        cal_category: 'user_count',
                    }
                    this.form.formula = 'A / B'
                }
                if (this.form.category.indexOf('per_event') > -1) {
                    this.form.atomic[1] = {
                        name: '',
                        items: [],
                        cal_category: 'event_count',
                    }
                    this.form.formula = 'A / B'
                }
            }
            this.refreshTex();
        },
        refreshTex() {
            this.$nextTick(_ => {
                MathJax.typeset();
            });
        }
    },
}
</script>

<style scoped>

</style>
<template>
    <el-aside :style="{width: collapsed?'60px':'256px'}">
        <el-scrollbar style="height: calc(100% - 41px)">
            <aside-wrapper @click="collapsed=false">
                <slot></slot>
            </aside-wrapper>
        </el-scrollbar>
        <div class="footer" @click="collapsed = !collapsed">
            <el-icon style="margin: 9px 20px 9px 0" :size="22">
                <ArrowRight v-if="collapsed"/>
                <ArrowLeft v-else/>
            </el-icon>
        </div>
    </el-aside>
</template>

<script>
import AsideGroup from "./AsideGroup";
import AsideWrapper from "./AsideWrapper";
import AsideItem from "./AsideItem";
import {computed} from "vue";
import {ArrowLeft, ArrowRight} from '@element-plus/icons-vue'

export default {
    name: "AsideNav",
    components: {AsideItem, AsideWrapper, AsideGroup, ArrowLeft, ArrowRight},
    props: {data: Object},
    data() {
        return {
            collapsed: false,
        }
    },
    provide() {
        return {collapsed: computed(_ =>this.collapsed), unCollapse: this.unCollapse, collapse: this.collapse}
    },
    methods: {
        collapse() {
            this.collapsed = true;
        },
        unCollapse() {
            this.collapsed = false;
        }
    },
}
</script>

<style scoped>
.el-aside {
    transition: 0.3s ease;
}
.el-aside .footer {
    font-size: 18px;
    border-top: 1px solid var(--el-border-color);
    width: 100%;
    text-align: right;
    cursor: pointer;
}
</style>
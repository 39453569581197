<template>
    <el-alert title="本页面的所有与收入金额相关的均为估算值！" type="warning" effect="dark" :closable="false"></el-alert>
    <div style="margin-top: 5px;display: inline-flex">
        <date-range-picker v-model="form" :disabled="loading"></date-range-picker>
        <el-select v-model="form.country" :disabled="loading" clearable placeholder="全部国家" filterable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="`${c.en_name}(${c.code})`"></el-option>
        </el-select>
        <el-button type="primary" :loading="loading" @click="init">查询</el-button>
    </div>
    <el-row v-for="(_, k) in placement">
        <el-col :span="12" :xl="8" :xs="24">
            <div :id="`${k}-impression`" class="chart"></div>
        </el-col>
        <el-col :span="12" :xl="8" :xs="24">
            <div :id="`${k}-ecpm`" class="chart"></div>
        </el-col>
    </el-row>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate, generateDateRange} from "../../libs/utils";
import DateRangePicker from "../../base/DateRangePicker";

export default {
    name: "IAAPlacement",
    components: {DateRangePicker},
    data() {
        const now = Date.now();
        return {
            loading: false, active: {}, placement: {},
            form: {
                start: timestampToDate(now - (this.$root.t + 6) * 24 * 3600 * 1000),
                end: timestampToDate(now - this.$root.t * 24 * 3600 * 1000),
                country: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/api/v1/iaa/${this.$route.params.productId}/placement/insight`, {params: this.form}).then(res => {
                    this.data = res.data.data.insightList;
                }),
                axios.get(`/api/v1/pm/${this.$route.params.productId}/report`, {params: this.form}).then(res => {
                    res.data.data.metricList.forEach(m => {
                        this.active[m.date] = m.bq_dau;
                    })
                }),
                axios.get(`https://elastic.plutodesk.com/cms/v1/mediation`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.placement = {};
                    res.data.data.mediationList.forEach(m => {
                        if (m.active) {
                            m.data.forEach(d => {
                                d.groups.forEach(g => {
                                    if (g.type === 'reward' || g.type === 'interstitial') {
                                        g.placements.forEach(p => {
                                            this.placement[p] = {
                                                format: g.type,
                                                impression: [{type: 'line', name: 'show', data: []}, {
                                                    type: 'line',
                                                    name: 'displayed',
                                                    data: []
                                                }],
                                                ecpm: [{type: 'line', name: 'eCPM', data: []}]
                                            }
                                        })
                                    }
                                })
                            })
                        }
                    })
                })
            ]).then(_ => {
                this.data.forEach(d => {
                    if (this.placement[d.placement]) {
                        this.placement[d.placement].impression[0].data.push([d.date, parseFloat((d.show / this.active[d.date]).toFixed(2))])
                        this.placement[d.placement].impression[1].data.push([d.date, parseFloat((d.displayed / this.active[d.date]).toFixed(2))])
                        this.placement[d.placement].ecpm[0].data.push([d.date, parseFloat((d.revenue / d.displayed * 1000).toFixed(2))])
                        this.placement[d.placement].impression[0].data.sort(function (a, b) {
                            return a[0] < b[0] ? -1 : 1;
                        });
                        this.placement[d.placement].impression[1].data.sort(function (a, b) {
                            return a[0] < b[0] ? -1 : 1;
                        });
                        this.placement[d.placement].ecpm[0].data.sort(function (a, b) {
                            return a[0] < b[0] ? -1 : 1;
                        });
                    }
                });
                const x = generateDateRange(this.form.start, this.form.end);
                this.$nextTick(_ => {
                    for (let k in this.placement) {
                        echarts.init(document.getElementById(`${k}-impression`), this.$root.dark ? 'dark' : '').setOption(this.getOption(x, this.placement[k].impression, `${k}(${this.placement[k].format})人均展示数`));
                        echarts.init(document.getElementById(`${k}-ecpm`), this.$root.dark ? 'dark' : '').setOption(this.getOption(x, this.placement[k].ecpm, `${k}(${this.placement[k].format})eCPM`));
                    }
                    this.loading = false;
                })
            })
        },
        getOption(x, series, title) {
            return {
                grid: {
                    bottom: 30
                },
                xAxis: {
                    type: 'category',
                    data: x,
                },
                yAxis: {
                    type: 'value',
                },
                series,
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        axis: 'x',
                        type: "shadow",
                    }
                },
                legend: {},
                title: {
                    text: title,
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
            }
        }
    },
}
</script>

<style scoped>
.chart {
    height: 300px;
    margin: 5px;
    background-color: var(--el-bg-color);
}
</style>
<template>
    <json-viewer ref="json"></json-viewer>
</template>

<script>
import JsonViewer from "./JsonViewer";
import axios from 'ts-axios-new';

export default {
    name: "ExpGroupHistory",
    components: {JsonViewer},
    props: {form: Object},
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        openDialog(index) {
            this.loading = true;
            axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + this.form.id + '/history', {params: {index}}).then(res => {
                this.$refs.json.openDialog(res.data.data.historyList);
                this.loading = false;
            })
        }
    },
}
</script>

<style scoped>

</style>

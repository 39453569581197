<template>
    <div style="padding: 0 30px">
        <cohort-form :loading="loading" ref="cohort_form" @submit="submit"></cohort-form>
        <div style="display: flex;justify-content: flex-end">
            <sql-runtime ref="timer" :stat="timer_stat" style="margin-right: 20px"></sql-runtime>
        </div>
        <el-card shadow="never">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="目标指标" prop="metrics" :rules="[{required: true, message: '请至少选择一个指标'}]">
                    <el-select v-model="form.metrics" multiple  style="width: 500px" :disabled="loading" filterable>
                        <el-option v-for="m in metric_list" :value="m.id" :label="m.name">
                            {{m.name}} <span style="font-size: 10px;" class="color-info">({{m.description}})</span>
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <downloadable-table :loading="loading" :data="data" title="指标数据">
                <el-table-column v-for="h in headers" width="200" show-overflow-tooltip :label="h"
                                 :prop="h"></el-table-column>
            </downloadable-table>
        </el-card>
    </div>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import SqlRuntime from "../../base/SqlRuntime";
import DownloadableTable from "../../base/DownloadableTable";
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";

export default {
    name: "MetricAnalysis",
    components: {DownloadableTable, SqlRuntime, CohortForm},
    data() {
        return {
            loading: false, data: [], headers: [], timer_stat: {
                total_bytes_processed: 0, duration: 0, message: null, sql: ''
            }, metric_list: [],
            form: {
                metrics: [],
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/api/v1/metric', {
                params: {
                    product_id: this.$route.params.productId,
                    contain_preset: 1
                }
            }).then(res => {
                this.metric_list = res.data.data.models;
                this.loading = false;
            });
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$refs.timer.start();
                    const form = this.$refs.cohort_form.getData();
                    form.metrics = this.form.metrics;
                    axios.post(`/api/v1/metrics/${this.$route.params.productId}/report`, form).then(res => {
                        this.headers = res.data.data.headers;
                        update(this.timer_stat, res.data.data);
                        this.loading = false;
                        this.$refs.timer.success();
                        this.data = res.data.data.data;
                    }).catch(err => {
                        this.loading = false;
                        this.$refs.timer.error(err.response.data.status.message);
                    })
                }
            })
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
                this.form.metrics = [];
            }
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <el-dialog v-model="dialog_opened">
        <div v-for="(g, i) in groups" :key="i">
            <p>{{g.name}} (
                <user v-model="g.user_id"></user>
                {{g.c_time ? timestampToDatetime(g.c_time) : ''}} {{g.tag}})
            </p>
            <json-input mode="preview" v-model="g.data" height="200px"></json-input>
        </div>
        <template #footer>
            <el-button @click="dialog_opened=false" type="primary">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import JsonInput from '../../../base/JsonInput'
import User from "../../../base/User";
import {timestampToDatetime} from "../../../libs/utils";

export default {
    name: "JsonViewer",
    components: {User, JsonInput},
    data() {
        return {
            dialog_opened: false, groups: []
        }
    },
    methods: {
        openDialog(data) {
            this.dialog_opened = true;
            this.groups = data;
        },
        timestampToDatetime(timestamp) {
            return timestampToDatetime(timestamp)
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <el-dialog v-model="dialog_opened" :before-close="resetForm">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item v-for="l in user_layer_define" :key="l.id" :label="l.name">
                <user-layer-checkbox :options="l.labels" :selected="form[l.id]"></user-layer-checkbox>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text @click="dialog_opened = false">取消</el-button>
            <el-button type="primary" @click="submitForm">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import UserLayerCheckbox from "./UserLayerCheckbox";
import {update} from "../../../libs/utils";

export default {
    name: "UserLayerEditor",
    components: {UserLayerCheckbox},
    props: {
        modelValue: Object,
        user_layer_define: Array,
    },
    data() {
        return {
            dialog_opened: false, form: {},
        }
    },
    methods: {
        openDialog() {
            this.form = {};
            this.user_layer_define.forEach(l => {
                this.form[l.id] = [];
            });
            update(this.form, this.modelValue);
            this.dialog_opened = true;
        },
        submitForm() {
            const form = {};
            for (let key in this.form) {
                if (this.form[key].length > 0) {
                    form[key] = this.form[key];
                }
            }
            this.$emit('update:modelValue', form);
            this.resetForm();
        },
        resetForm() {
            this.dialog_opened = false;
        },

    }
}
</script>

<style scoped>

</style>

<template>
    <el-alert title="本页面的所有与收入金额相关的均为估算值！" type="warning" effect="dark" :closable="false"></el-alert>
    <div style="margin-top: 5px;display: inline-flex">
        <date-range-picker v-model="form" :disabled="loading"></date-range-picker>
        <el-select v-model="form.country" :disabled="loading" clearable placeholder="全部国家" filterable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="`${c.en_name}(${c.code})`"></el-option>
        </el-select>
        <el-button type="primary" :loading="loading" @click="init">查询</el-button>
    </div>
    <el-row>
        <el-col :span="12" :xl="8" :xs="24">
            <div id="request" class="chart"></div>
        </el-col>
        <el-col :span="12" :xl="8" :xs="24">
            <div id="filled" class="chart"></div>
        </el-col>
        <el-col :span="12" :xl="8" :xs="24">
            <div id="impression" class="chart"></div>
        </el-col>
        <el-col :span="12" :xl="8" :xs="24">
            <div id="revenue" class="chart"></div>
        </el-col>
    </el-row>
</template>

<script>
import {timestampToDate, generateDateRange} from "../../libs/utils";
import axios from 'ts-axios-new';
import DateRangePicker from "../../base/DateRangePicker";

export default {
    name: "IAADashboard",
    components: {DateRangePicker},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], active: {}, format: [],
            form: {
                start: timestampToDate(now - (this.$root.t + 6) * 24 * 3600 * 1000),
                end: timestampToDate(now - this.$root.t * 24 * 3600 * 1000),
                country: '',
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/api/v1/iaa/${this.$route.params.productId}/format/insight`, {params: this.form}).then(res => {
                    this.data = res.data.data.insightList;
                    this.format = [];
                    this.data.forEach(d => {
                        if (!this.format.includes(d.format)) {
                            this.format.push(d.format);
                        }
                    });
                }),
                axios.get(`/api/v1/pm/${this.$route.params.productId}/report`, {params: this.form}).then(res => {
                    res.data.data.metricList.forEach(m => {
                        this.active[m.date] = m.bq_dau;
                    })
                }),
            ]).then(_ => {
                const x = generateDateRange(this.form.start, this.form.end);
                const request = [], filled = [], impression = [], revenue = [];
                this.data.forEach(d => {
                    const index = this.format.indexOf(d.format);
                    if (!revenue[index]) {
                        revenue[index] = {type: 'line', name: d.format, data: []}
                    }
                    revenue[index].data.push([d.date, parseFloat((d.revenue / this.active[d.date]).toFixed(4))]);
                    revenue[index].data.sort(function (a, b) {
                        return a[0] < b[0] ? -1 : 1;
                    })
                    if (!impression[index]) {
                        impression[index] = {type: 'line', name: d.format, data: []}
                    }
                    impression[index].data.push([d.date, parseFloat((d.impression / this.active[d.date]).toFixed(2))]);
                    impression[index].data.sort(function (a, b) {
                        return a[0] < b[0] ? -1 : 1;
                    })
                    if (!filled[index]) {
                        filled[index] = {type: 'line', name: d.format, data: []}
                    }
                    filled[index].data.push([d.date, parseFloat((d.filled / this.active[d.date]).toFixed(2))]);
                    filled[index].data.sort(function (a, b) {
                        return a[0] < b[0] ? -1 : 1;
                    })
                    if (!request[index]) {
                        request[index] = {type: 'line', name: d.format, data: []}
                    }
                    request[index].data.push([d.date, parseFloat((d.request / this.active[d.date]).toFixed(2))]);
                    request[index].data.sort(function (a, b) {
                        return a[0] < b[0] ? -1 : 1;
                    })
                });
                echarts.init(document.getElementById('request'), this.$root.dark ? 'dark' : '').setOption(this.getOption(x, request, '人均请求数'), true);
                echarts.init(document.getElementById('filled'), this.$root.dark ? 'dark' : '').setOption(this.getOption(x, filled, '人均填充数'), true);
                echarts.init(document.getElementById('impression'), this.$root.dark ? 'dark' : '').setOption(this.getOption(x, impression, '人均展示数'), true);
                echarts.init(document.getElementById('revenue'), this.$root.dark ? 'dark' : '').setOption(this.getOption(x, revenue, '人均收入'), true);
                this.loading = false;
            })
        },
        getOption(x, series, title) {
            return {
                grid: {
                    bottom: 30
                },
                xAxis: {
                    type: 'category',
                    data: x,
                },
                yAxis: {
                    type: 'value',
                },
                series,
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        axis: 'x',
                        type: "shadow",
                    }
                },
                legend: {},
                title: {
                    text: title,
                }
            }
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
            }
        },
        "$root.dark"() {
        },
    },
}
</script>

<style scoped>
.chart {
    height: 300px;
    margin: 5px;
    background-color: var(--el-bg-color);
}
</style>
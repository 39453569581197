<template>
    <div style="display: inline-flex">
        <date-range-picker v-model="form" :disabled="loading"></date-range-picker>
        <el-select v-model="form.country" :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="`${c.en_name}(${c.code})`"></el-option>
        </el-select>
        <el-button type="primary" :loading="loading" @click="init">查询</el-button>
    </div>
    <el-table style="margin-top: 10px" :data="data" v-loading="loading" class="retention-table">
        <el-table-column width="160" fixed sortable show-overflow-tooltip align="center" label="SKU" prop="sku">
            <template #default="scope">
                <div style="padding: 25px 0" class="inner-cell">
                    <p style="line-height: 24px">{{ scope.row.sku }}</p>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="total" label="订阅" width="128" align="center">
            <template #default="scope">
                <div class="inner-cell">
                    <p>100%</p>
                    <p style="font-size: 12px;">{{ formatInt(scope.row.total) }}</p>
                </div>
            </template>
        </el-table-column>
        <el-table-column v-for="h in [1, 2, 3, 4, 5, 6, 7]" width="128" :label="`复购${h}`" align="center">
            <template #default="scope">
                <div class="inner-cell"
                     :style="{backgroundColor: blue[parseInt((scope.row[`retention_${h}`] / scope.row.total) * 10)], color: scope.row[`retention_${h}`] / scope.row.total>=0.5?'#fff':''}">
                    <p>{{ (scope.row[`retention_${h}`] / scope.row.total * 100).toFixed(2) }}%</p>
                    <p style="font-size: 12px;">{{ formatInt(scope.row[`retention_${h}`]) }}</p>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate, formatInt} from "../../libs/utils";
import DateRangePicker from "../../base/DateRangePicker";

export default {
    name: "IAPSubscription",
    components: {DateRangePicker},
    data() {
        const now = new Date();
        return {
            loading: false, data: [],
            blue: [
                'var(--el-fill-color)',
                'var(--el-color-primary-light-9)',
                'var(--el-color-primary-light-8)',
                'var(--el-color-primary-light-7)',
                'var(--el-color-primary-light-5)',
                'var(--el-color-primary-light-3)',
                'var(--el-color-primary)',
                'var(--el-color-primary-dark-2)',
                'var(--el-color-primary-dark-2)',
                'var(--el-color-primary-dark-2)',
                'var(--el-color-primary-dark-2)',
            ],
            form: {
                start: timestampToDate((new Date(now.setDate(1) - 24 * 3600 * 1000)).setDate(1)),
                end: timestampToDate(now.setDate(1) - 24 * 3600 * 1000),
                country: '',
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/api/v1/iap/${this.$route.params.productId}/subscription`, {params: this.form}).then(res => {
                this.data = res.data.data.retentionList;
                this.loading = false;
            });
        },
        formatInt(value) {
            return formatInt(value);
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.retention-table ::v-deep(td) {
    border-bottom: 0;
    padding: 0;
}

.retention-table ::v-deep(th), .retention-table ::v-deep(.install) {
    background-color: var(--el-fill-color);
}

.retention-table ::v-deep(.cell) {
    padding: 0;
}

.retention-table ::v-deep(.inner-cell) {
    padding: 14px 0;
    color: var(--el-text-color-regular);
}

.retention-table ::v-deep(p) {
    margin: 0;
}
</style>
<template>
    <span>
        <span v-if="state === 'running'" style="font-size: 12px">
            <el-icon size="12px" class="color-modest">
                <Clock/>
            </el-icon> 正在查询{{ second.toFixed(1) }}秒
        </span>
        <el-popover trigger="click" v-else-if="state==='success'">
            <template #reference>
                <span style="font-size: 12px">
                <el-icon class="color-success" size="12px" style="cursor: pointer;"><CircleCheckFilled/></el-icon>
                    已完成 {{ second.toFixed(1) }}秒 处理{{ formatBytes(stat.total_bytes_processed) }}数据</span>
            </template>
            <p style="font-size: 12px">
                <el-progress :show-text="false" :percentage="stat.duration / second * 100"
                             status="success">{{ stat.duration }}</el-progress>计算耗时
                <b style="float: right">{{ stat.duration.toFixed(1) }}s</b>
            </p>
            <p style="font-size: 12px">
            <el-progress :show-text="false" :percentage="(1 - stat.duration / second) * 100"
                         status="success"></el-progress>传输耗时
                <b style="float: right;">{{ (second - stat.duration).toFixed(1) }}s</b>
            </p>
            <el-button type="primary" text @click="copySql">复制sql</el-button>
        </el-popover>
        <el-popover trigger="click" v-else-if="state==='error'">
            <p style="font-size: 12px">{{ message }}</p>
            <template #reference>
                <span style="font-size: 12px">
                <el-icon size="12px" class="color-error" style="cursor: pointer;"><CircleCloseFilled/></el-icon>
                    失败 {{ second.toFixed(1) }}秒
                </span>
            </template>
        </el-popover>
        <i style="font-size: 12px;opacity: 0" v-else>占位符</i>
    </span>
</template>

<script>
import {formatBytes} from "../libs/utils";

export default {
    name: "SqlRuntime",
    props: {stat: Object},
    data() {
        return {
            second: 0, state: 'pending', percent: 0, message: '', timer: 0,
        }
    },
    methods: {
        start() {
            this.second = 0;
            this.timer = new Date();
            this.state = 'running';
            this.timing();
        },
        success() {
            this.state = 'success';
        },
        error(message) {
            this.state = 'error';
            this.message = message;
        },
        timing() {
            setTimeout(_ => {
                this.second = parseInt((new Date() - this.timer) / 100) * 0.1;
                if (this.state === 'running') {
                    this.timing();
                }
            }, 100)
        },
        copySql() {
            clipboard.writeText(this.stat.sql);
            this.$notify.success('复制成功！');
        },
        reset() {
            this.state = 'pending';
        },
        formatBytes(bytes) {
            return formatBytes(bytes)
        }
    },
}
</script>

<style scoped>

</style>
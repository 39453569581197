<template>
    <div class="aside-menu" @click="clickCollapse" :class="className">
        <div style="width: 18px">
            <el-icon size="14px" style="vertical-align: middle">
                <Loading v-if="loading" class="fa-spin"/>
                <CaretRight v-else-if="collapse"/>
                <CaretBottom v-else/>
            </el-icon>
        </div>
        <div style="width: 20px">
            <el-tooltip v-if="icon && collapsed" placement="right" :content="title">
                <component style="vertical-align: middle" :is="icon"></component>
            </el-tooltip>
            <component style="vertical-align: middle" :is="icon" v-else-if="icon"></component>
        </div>
        <div style="padding-left: 18px" v-if="!$parent.collapse">{{ title }}</div>
    </div>
    <el-collapse-transition>
        <div class="aside-submenu-wrapper" v-if="!collapse && !$parent.collapse">
            <slot></slot>
        </div>
    </el-collapse-transition>
</template>

<script>
export default {
    name: "AsideMenu",
    props: {
        index: String,
        icon: String,
        disabled: Boolean,
        fixed: Boolean,
        title: String,
        loading: Boolean,
        unfixable: Boolean,
    },
    data() {
        return {
            collapse: true,
        }
    },
    inject: ['parentDisabled', 'collapsed'],
    methods: {
        clickCollapse() {
            this.collapse = !this.collapse;
            this.$emit('click');
        },
    },
    computed: {
        className() {
            if (this.disabled) {
                return 'disabled'
            } else {
                if (this.$route.name === this.index)
                    return 'is-active'
                else {
                    let c = '';
                    this.$route.matched.forEach(m => {
                        if (m.name === this.index) {
                            c = 'is-active';
                        }
                    });
                    return c;
                }
            }
        }
    },
    mounted() {
        if (this.className === 'is-active') {
            this.clickCollapse();
        }
    },
    watch: {
        $route() {
            if (this.className !== 'is-active') {
                this.collapse = true;
            }
        }
    }
}
</script>

<style scoped>
.aside-menu {
    font-size: 14px;
    color: var(--el-text-color-regular);
    padding-left: 4px;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    white-space: nowrap;
    line-height: 40px;
    display: flex;
}

.aside-menu:hover {
    background-color: var(--el-border-color-light);
    cursor: pointer;
    color: var(--el-text-color-primary);
}

.aside-menu.disabled {
    cursor: not-allowed;
    color: var(--el-text-color-placeholder);
}

.aside-menu.is-active {
    background-color: var(--el-border-color-lighter);
    color: var(--el-text-color-regular);
}
</style>
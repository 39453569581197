<template>
    <el-row>
        <el-button  type="success" @click="openDialog()">创建参数</el-button>
    </el-row>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="参数名" prop="key" width="200">
            <template #default="scope">
                <el-button  type="primary" text @click="openDialog1(scope.row.key)">{{scope.row.key}}
                </el-button>
            </template>
        </el-table-column>
        <el-table-column label="描述" prop="desc" width="250">
            <template #default="scope">
                <el-input  v-model="scope.row.desc" @change="updateDesc(scope.row)"
                          :disabled="loading"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="域">
            <template #default="scope">
                <template v-for="(b, i) in scope.row.boundary">
                    <el-button  plain v-if="typeof b.value[0] !== 'object'" style="margin: 0 5px"
                               :disabled="b.event !== 'exp_published' && b.expired * 1000 < new Date()"
                               :type="b.event === 'exp_published' ? 'danger' : b.expired && b.expired * 1000 > new Date() ? 'primary' : 'info'"
                               @click="b.event === 'exp_published' ? $refs.editor.openDialog(b.exp_id) : openDialog(scope.row.key, b)">
                        <span> >={{b.version}} </span>
                        <span v-if="b.country.length"> |
                            <span v-if="b.country.length > 2">
                                {{b.country[0]}}等{{b.country.length}}个国家
                            </span>
                            <span v-else>
                                {{b.country.join('、')}}
                            </span>
                        </span>
                        <span v-if="b.device_category.length"> |
                            <span v-for="d in b.device_category" :key="d">{{{mobile: '手机', tablet: '平板'}[d]}}</span>
                        </span>
                        <span v-if="b.media_source.length"> |
                            <span v-if="b.media_source.length > 2">
                                {{b.media_source[0]}}等{{b.media_source.length}}个渠道
                            </span>
                            <span v-else>
                                {{b.media_source.join('、')}}
                            </span>
                        </span>
                        <span v-if="b.campaign_id.length"> |
                            {{b.campaign_id.length}}个Campaign
                        </span>
                        <span v-if="JSON.stringify(b.user_layers)!=='{}'"> |
                            <template v-for="(v, k) in b.user_layers">
                                <span v-if="v.length > 0">
                                    <span v-for="(tag, i) in b.user_layers[k]" :key="i">
                                        <template v-for="l in user_layer_define">
                                            <span v-if="k === l.id">
                                                <template v-for="ll in l.labels">
                                                    <span v-if="ll.id === tag">{{ll.name}} </span>
                                                </template>
                                            </span>
                                        </template>
                                    </span>
                                </span>
                            </template>
                        </span>
                        :
                        {{b.value.join('、')}}
                    </el-button>
                </template>
                <template v-for="(b, ii) in scope.row.boundary">
                    <el-popover v-if="typeof b.value[0] === 'object'" placement="top" trigger="hover">
                        <div v-for="(v, i) in b.value" :key="i">{{JSON.stringify(v)}}</div>
                        <template #reference>
                            <el-button  plain style="margin: 0 5px"
                                       :disabled="b.event !== 'exp_published' && b.expired * 1000 < new Date()"
                                       :type="b.event === 'exp_published' ? 'danger' : b.expired && b.expired * 1000 > new Date() ? 'primary' : 'info'"
                                       @click="b.event === 'exp_published' ? $refs.editor.openDialog(b.exp_id) : openDialog(scope.row.key, b)">
                            <span v-if="b.country.length > 2">
                                {{b.country[0]}}、{{b.country[1]}}等{{b.country.length}}个
                            </span>
                                <span v-else>
                                {{b.country.join('、') || '全球'}}
                            </span>
                                |
                                <template v-for="(v, k) in b.user_layers">
                                    <span v-if="v.length > 0">
                                        <span v-for="(tag, _i) in b.user_layers[k]" :key="_i">
                                            <template v-for="l in user_layer_define">
                                                <span v-if="k === l.id">
                                                    <template v-for="ll in l.labels">
                                                        <span v-if="ll.id === tag">{{ll.name}} </span>
                                                    </template>
                                                </span>
                                            </template>
                                        </span>
                                    </span>
                                </template>
                                : object
                            </el-button>
                        </template>
                    </el-popover>
                </template>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" width="50%" :before-close="resetForm"
               :title="editing?'编辑参数':'创建参数'">
        <el-form :model="form" ref="form" label-width="140px">
            <el-form-item label="参数名称" prop="key" :rules="[{required: true, message: '请输入参数名称'}]">
                <el-input v-model="form.key" :disabled="!!editing || loading"></el-input>
            </el-form-item>
            <el-form-item label="参数类型">
                <el-select v-model="value_type" :disabled="!!editing || loading" @change="changeType">
                    <el-option label="数字型" value="number"></el-option>
                    <el-option label="字符串型" value="string"></el-option>
                    <el-option label="布尔型" value="bool"></el-option>
                    <el-option label="对象型" value="json"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="默认值" prop="value" :rules="[{required: true, message: '请输入默认值'}, {validator}]">
                <el-select v-if="value_type === 'bool'" v-model="form.value">
                    <el-option :value="true" label="true"></el-option>
                    <el-option :value="false" label="false"></el-option>
                </el-select>
                <json-input v-else-if="value_type === 'json'" v-model="form.value" mode="code"></json-input>
                <el-input v-else="value_type !== 'bool'" v-model="form.value"></el-input>
            </el-form-item>
            <el-form-item label="失效时间（天）" prop="expire"
                          :rules="[{required: true, message: '请输入失效时间'}, {type: 'number', message: '必须是数字'}]">
                <el-input v-model.number="form.expire" style="width: 100%"></el-input>
                <span>{{editing ? '(' + timestampToDate(editing.expired) + ')' : ''}}</span>
            </el-form-item>
            <el-form-item label="最小版本" prop="version"
                          :rules="[{required: true, message: '请输入版本号'}, {validator: versionValidator}]">
                <el-select v-model="form.version" filterable allow-create style="width: 100%"
                           :disabled="!!editing || loading">
                    <el-option v-for="v in version_list" :key="v.id" :value="v.version"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="国家">
                <country-selector v-model="form.boundary.country" width="100%"
                                  :disabled="!!editing || loading"></country-selector>
            </el-form-item>
            <el-form-item label="设备类型" prop="device_category">
                <el-select multiple v-model="form.boundary.device_category" style="width: 100%" :multiple-limit="1"
                           clearable :disabled="!!editing || loading">
                    <el-option label="手机" value="mobile"></el-option>
                    <el-option label="平板" value="tablet"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="获客来源" prop="af_status">
                <el-select v-model="form.boundary.af_status" multiple style="width: 100%" :multiple-limit="1"
                           clearable :disabled="!!editing || loading" @change="changeAfStatus">
                    <el-option label="自然量" value="Organic"></el-option>
                    <el-option label="非自然量" value="Non-organic"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="媒体渠道" v-if="form.boundary.af_status.indexOf('Non-organic') > -1">
                <el-select v-model="form.boundary.media_source" multiple collapse-tags style="width: 300px"
                           :disabled="!!editing || loading">
                    <el-option v-for="c in media_source" :key="c.id" :value="c.media_source"
                               v-if="c.media_source"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Campaign ID" v-if="form.boundary.af_status.indexOf('Non-organic') > -1">
                <el-select v-model="form.boundary.campaign_id" multiple filterable style="width: 300px"
                           allow-create :disabled="!!editing || loading"></el-select>
            </el-form-item>
            <el-form-item label="用户分层标签" v-if="user_layer_define.length > 0">
                <div v-for="(_, k) in form.boundary.user_layers" :key="k">
                    <template v-for="l in user_layer_define">
                        <div v-if="l.id===k">
                            {{l.name}} :
                            <el-tag effect="plain" size="medium" v-for="(tag, i) in form.boundary.user_layers[k]"
                                    :key="i">
                                <template v-for="ll in l.labels">
                                    <span v-if="ll.id===tag">{{ll.name}}</span>
                                </template>
                            </el-tag>
                        </div>
                    </template>
                </div>
                <el-button type="primary"  @click="openUserLayerEditor" :disabled="!!editing || loading">
                    设置
                </el-button>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="resetForm">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submitForm">确定</el-button>
        </template>
    </el-dialog>
    <exp-editor ref="editor"></exp-editor>
    <user-layer-editor ref="user_layer_editor" v-model="form.boundary.user_layers"
                       :user_layer_define="user_layer_define"></user-layer-editor>
    <el-dialog v-model="dialog_opened1" title="参数历史" width="80%">
        <h4>参数名：{{history.length ? history[0].key : ''}}</h4>
        <el-table v-loading="loading" :data="history">
            <el-table-column label="时间" width="200" prop="c_time" sortable>
                <template #default="scope">
                    {{timestampToDate(scope.row.c_time)}}
                </template>
            </el-table-column>
            <el-table-column label="域" width="200" prop="boundary" sortable>
                <template #default="scope">
                    {{scope.row.boundary.country.join(',') || '全球'}}
                </template>
            </el-table-column>
            <el-table-column label="用户分层标签" width="200" prop="boundary" sortable>
                <template #default="scope">
                    <div v-if="scope.row.boundary.user_layers">
                        <div v-for="(v, k) in scope.row.boundary.user_layers" :key="k">
                            <el-tag effect="plain" size="small"
                                    v-for="(tag, i) in scope.row.boundary.user_layers[k]" :key="i">
                                <template v-for="l in user_layer_define">
                                    <span v-if="k === l.id">
                                        <template v-for="ll in l.labels">
                                            <span v-if="ll.id === tag">{{ll.name}} </span>
                                        </template>
                                    </span>
                                </template>
                            </el-tag>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="参数值" width="200" prop="value" sortable>
                <template #default="scope">
                        <span v-for="(v, i) in scope.row.value" :key="i" style="margin: 0 5px;">
                            {{scope.row.value.length > 1 ? 'abcdefghij'[i] + ':' : ''}}{{v.value}}
                        </span>
                </template>
            </el-table-column>
            <el-table-column label="事件" width="100" prop="event" sortable>
                <template #default="scope">
                    {{
                        {
                            exp_published: '实验开始', exp_finished: '实验应用', exp_terminated: '实验停止',
                            manual: '人工调整', expired: '已失效'
                        }[scope.row.event]
                    }}
                </template>
            </el-table-column>
            <el-table-column label="失效时间" width="100" prop="expired" sortable>
                <template #default="scope">
                    {{scope.row.expired ? parseInt((scope.row.expired - scope.row.c_time) / 24 / 3600) : '-'}}
                </template>
            </el-table-column>
            <el-table-column label="操作人" width="150" prop="user_id" sortable>
                <template #default="scope">
                    <user v-model="scope.row.user_id"></user>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="dialog_opened1=false">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import CountrySelector from "../../../base/CountrySelector";
import JsonInput from '../../../base/JsonInput'
import ExpEditor from "./ExpEditor";
import {update, timestampToDate, deepcopy, versionValidator} from "../../../libs/utils";
import User from "../../../base/User";
import UserLayerEditor from "./UserLayerEditor";
import axios from 'ts-axios-new';

export default {
    name: "ParamsList",
    components: {User, ExpEditor, CountrySelector, JsonInput, UserLayerEditor},
    data() {
        return {
            loading: false, data: [], value_type: 'string', user_layer_define: [], key_map: {}, version_list: [],
            dialog_opened: false, editing: null, dialog_opened1: false, history: [], media_source: [],
            form: {
                product_id: this.$route.params.productId,
                key: '',
                expire: 30,
                value: '',
                user_id: this.$root.user.user_id,
                boundary: {
                    country: [],
                    device_category: [],
                    af_status: [],
                    media_source: [],
                    campaign_id: [],
                    user_layers: {},
                },
                version: '',
            },
        }
    },
    methods: {
        changeAfStatus() {
            this.form.boundary.media_source.splice(0, this.form.boundary.media_source.length);
            this.form.boundary.campaign_id.splice(0, this.form.boundary.campaign_id.length);
        },
        versionValidator(rules, value, callback) {
            return versionValidator(rules, value, callback);
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/params', {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.data.splice(0, this.data.length);
                    this.key_map = {};
                    res.data.data.paramsList.forEach(p => {
                        if (!p.key.startsWith('emptyTestKeyFromBi'))
                            this.insertKey(p);
                    });
                }), axios.get('/api/v1/ua/channel').then(res => {
                    update(this.media_source, res.data.data.channelList);
                }), axios.get('/api/v1/pm/version', {params: {product_id: this.$route.params.productId}}).then(res => {
                    update(this.version_list, res.data.data.versionList);
                })
            ]).then(_ => {
                this.loading = false;
            });
        },
        insertKey(p) {
            if (!this.key_map[p.key]) {
                this.key_map[p.key] = {
                    key: p.key,
                    boundary: [],
                    desc: p.desc
                };
                this.data.push(this.key_map[p.key]);
            }
            const boundary = {
                version_num: p.boundary.version_num,
                country: p.boundary.country,
                device_category: p.boundary.device_category,
                af_status: p.boundary.af_status,
                media_source: p.boundary.media_source,
                campaign_id: p.boundary.campaign_id,
                user_layers: p.boundary.user_layers,
                value: [],
                event: p.event,
                exp_id: p.exp_id,
                expired: p.expired,
                c_time: p.c_time,
                version: p.version,
            };
            p.value.forEach(v => {
                boundary.value.push(v.value);
            });
            this.key_map[p.key].boundary.push(boundary);
        },
        openDialog(k, b) {
            this.dialog_opened = true;
            if (b) {
                this.editing = b;
                this.$nextTick(_ => {
                    this.form.key = k;
                    this.form.expire = parseInt((b.expired - b.c_time) / 24 / 3600);
                    update(this.form.boundary, b);
                    this.form.boundary.user_layers = deepcopy(b.user_layers);
                    this.form.value = b.value[0];
                    this.form.version = b.version;
                    if (typeof this.form.value === 'boolean') {
                        this.value_type = 'bool';
                    } else if (typeof this.form.value === 'number') {
                        this.value_type = 'number'
                    } else if (typeof this.form.value === 'object') {
                        this.value_type = 'json'
                    } else {
                        this.value_type = 'string';
                    }
                })
            }
        },
        resetForm() {
            this.$refs.form.resetFields();
            this.form = {
                product_id: this.$route.params.productId,
                key: '',
                expire: 30,
                value: '',
                user_id: this.$root.user.user_id,
                boundary: {
                    country: [],
                    device_category: [],
                    af_status: [],
                    media_source: [],
                    campaign_id: [],
                    user_layers: {},
                },
                version: '',
            };
            this.value_type = 'string';
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        validator(rule, value, callback) {
            if (this.value_type === 'number') {
                if (isNaN(Number(this.form.value))) {
                    callback('不是数字类型!')
                } else {
                    callback();
                    this.form.value = Number(this.form.value);
                }
            } else if (this.value_type === 'bool') {
                if (typeof value === 'boolean') {
                    callback();
                } else {
                    callback('不是true/false！');
                }
            } else if (this.value_type === 'json') {
                if (typeof value === 'object') {
                    callback();
                } else {
                    callback('不是object')
                }
            } else {
                callback();
            }
        },
        changeType() {
            if (this.value_type === 'bool') {
                this.form.value = null;
            } else if (this.value_type === 'json') {
                this.form.value = {};
            } else {
                this.form.value = '';
            }
        },
        submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.form.product_id = this.$route.params.productId;
                    axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/params', this.form).then(res => {
                        if (res.data.data) {
                            this.init();
                            this.resetForm();
                        } else {
                            this.loading = false;
                            this.$alert('<p>创建参数需满足以下条件：</p><p>1、同域下不可以有正在做实验的参数；</p><p>2、若参数已存在则数据类型需要保持一致；</p><p>3、若参数已存在且与实验同域，则对照组参数需要和该域参数默认值一致；</p>', '创建失败', {
                                type: 'error',
                                dangerouslyUseHTMLString: true,
                            }).catch(_ => {
                            })
                        }
                    });
                }
            })
        },
        openDialog1(key) {
            this.loading = this.dialog_opened1 = true;
            const params = {
                product_id: this.$route.params.productId,
                key,
            };
            axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/params/history', {params}).then(res => {
                update(this.history, res.data.data.historyList);
                this.history.forEach(h => {
                    if (h.event !== 'exp_published') {
                        const n = this.history[this.history.indexOf(h) + 1];
                        if (n && n.c_time > h.expired || !n && new Date() > h.expired * 1000) {
                            const insert = deepcopy(h);
                            insert.c_time = insert.expired;
                            insert.event = 'expired';
                            this.history.splice(this.history.indexOf(h) + 1, 0, insert);
                        }
                    }
                });
                this.loading = false;
            });
        },
        openUserLayerEditor() {
            this.$refs.user_layer_editor.openDialog();
        },
        updateDesc(row) {
            this.loading = true;
            axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/params', {
                product_id: this.$route.params.productId,
                key: row.key, desc: row.desc,
            }).then(res => {
                this.loading = false;
            })
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp)
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.init();
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <el-card shadow="never" :body-style="{padding: '2px', display: 'flex', lineHeight}" style="width: 100%">
        <div style="width: calc(100% - 30px);cursor: pointer;" @click="open">
            <template v-for="m in modelValue">
                <el-tooltip placement="top">
                    <template #content>
                        <metric-formula :model="m"></metric-formula>
                    </template>
                    <el-tag type="info" style="margin-left: 5px;cursor: auto;" :closable="!disabled" :size="size"
                            @close="modelValue.splice(modelValue.indexOf(m), 1)" @click.stop="">{{ m.name }}
                    </el-tag>
                </el-tooltip>
            </template>
        </div>
        <el-icon style="width: 25px">
            <el-icon style="margin: 4px" class="color-info">
                <Loading v-if="disabled"/>
                <Plus v-else/>
            </el-icon>
        </el-icon>
    </el-card>
    <el-dialog v-model="dialog_opened" width="80%" title="选择指标">
        <el-button type="primary" text  @click="$refs.editor.openDialog()">没有想要的指标？快去创建一个！</el-button>
        <el-form label-position="top">
            <el-form-item label="固定指标" :size="size">
                <template v-for="m in metricList">
                    <template
                        v-if="m.category.indexOf('dau') > -1 || m.category.indexOf('first_open') > -1 || m.category.indexOf('retain') > -1 || m.category==='duration'">
                        <el-button :size="size" v-if="m.category.indexOf('retain') > -1 || m.category === 'duration'"
                                   :type="buttonType(m)" @click="select(m)">{{ m.name }}
                        </el-button>
                        <el-tooltip v-else content="无法计算显著性" effect="dark" placement="top">
                            <span><el-button :size="size" disabled>{{ m.name }}</el-button></span>
                        </el-tooltip>
                    </template>
                </template>
                <el-tag class="none" type="info">未创建</el-tag>
            </el-form-item>
            <el-form-item label="计数指标" :size="size" style="margin-top: 20px">
                <template v-for="m in metricList">
                    <template v-if="m.category.indexOf('count') > -1 || m.category === 'sum_value'">
                        <el-tooltip content="无法计算显著性" effect="dark" placement="top">
                            <span><el-button :size="size" disabled>{{ m.name }}</el-button></span>
                        </el-tooltip>
                    </template>
                </template>
                <el-tag :size="size" class="none" type="info">未创建</el-tag>
            </el-form-item>
            <el-form-item label="比率指标" :size="size" style="margin-top: 20px">
                <template v-for="m in metricList">
                    <template v-if="m.category.indexOf('rate') > -1">
                        <el-tooltip placement="top">
                            <template #content>
                                <metric-formula :model="m"></metric-formula>
                            </template>
                            <el-button :size="size" :type="buttonType(m)" @click="select(m)" :disabled="!m.init">
                                {{ m.name + (m.init ? '' : '(未初始化)') }}
                            </el-button>
                        </el-tooltip>
                    </template>
                </template>
                <el-tag :size="size" class="none" type="info">未创建</el-tag>
            </el-form-item>
            <el-form-item label="日活均值指标" :size="size" style="margin-top: 20px">
                <template v-for="m in metricList">
                    <template v-if="m.category.indexOf('per_head') > -1">
                        <el-tooltip placement="top">
                            <template #content>
                                <metric-formula :model="m"></metric-formula>
                            </template>
                            <el-button :size="size" :type="buttonType(m)" @click="select(m)" :disabled="!m.init">
                                {{ m.name + (m.init ? '' : '(未初始化)') }}
                            </el-button>
                        </el-tooltip>
                    </template>
                </template>
                <el-tag :size="size" class="none" type="info">未创建</el-tag>
            </el-form-item>
            <el-form-item label="用户均值指标" :size="size" style="margin-top: 20px">
                <template v-for="m in metricList">
                    <template v-if="m.category.indexOf('per_user') > -1">
                        <el-tooltip placement="top">
                            <template #content>
                                <metric-formula :model="m"></metric-formula>
                            </template>
                            <el-button :size="size" :type="buttonType(m)" @click="select(m)" :disabled="!m.init">
                                {{ m.name + (m.init ? '' : '(未初始化)') }}
                            </el-button>
                        </el-tooltip>
                    </template>
                </template>
                <el-tag :size="size" class="none" type="info">未创建</el-tag>
            </el-form-item>
            <el-form-item label="事件均值指标" :size="size" style="margin-top: 20px">
                <template v-for="m in metricList">
                    <template v-if="m.category.indexOf('per_event') > -1">
                        <el-tooltip placement="top">
                            <template #content>
                                <metric-formula :model="m"></metric-formula>
                            </template>
                            <el-button :size="size" :type="buttonType(m)" @click="select(m)" :disabled="!m.init">
                                {{ m.name + (m.init ? '' : '(未初始化)') }}
                            </el-button>
                        </el-tooltip>
                    </template>
                </template>
                <el-tag :size="size" class="none" type="info">未创建</el-tag>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text @click="reset">取消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <metric-editor ref="editor"></metric-editor>
</template>

<script>
import {update} from "../../../libs/utils";
import MetricEditor from "./MetricEditor";
import MetricFormula from "./MetricFormula";

export default {
    name: "MetricSelectorHyper",
    components: {MetricFormula, MetricEditor},
    inject: ['metricList'],
    props: {
        modelValue: Array,
        size: String,
        disabled: Boolean,
    },
    data() {
        return {
            data: [], lineHeight: '40px', dialog_opened: false,
        }
    },
    methods: {
        init() {
            if (this.size === 'mini') {
                this.lineHeight = '28px';
            } else if (this.size === 'small') {
                this.lineHeight = '32px'
            } else if (this.size === 'medium') {
                this.lineHeight = '36px';
            } else {
                this.lineHeight = '40px';
            }
        },
        submit() {
            update(this.modelValue, this.data);
            this.reset();
        },
        reset() {
            this.dialog_opened = false;
            this.data = [];
        },
        open() {
            this.dialog_opened = true;
            update(this.data, this.modelValue);
        },
        select(metric) {
            if (this.data.indexOf(metric) < 0) {
                this.data.push(metric);
            } else {
                this.data.splice(this.data.indexOf(metric), 1);
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        modelValue() {
        }
    },
    computed: {
        buttonType() {
            return metric => {
                let res = '';
                this.data.forEach(d => {
                    if (d.id === metric.id) {
                        res = 'primary'
                    }
                });
                return res;
            }
        }
    }
}
</script>

<style scoped>
.el-button + span, span + .el-button {
    margin-left: 10px;
}

.el-button + .none {
    display: none;
}
</style>
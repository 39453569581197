<template>
    <div style="display: inline-flex">
        <el-radio-group v-model="route">
            <el-radio-button label="Event">事件列表</el-radio-button>
            <el-radio-button label="Param">参数列表</el-radio-button>
        </el-radio-group>
        <el-button type="primary" :loading="loading" style="margin-left: 10px" @click="openDialog">新建</el-button>
    </div>
    <component :is="route" ref="entity"></component>
</template>

<script>
import Event from "./Event";
import Param from "./Param";

export default {
    name: "AnchorGuide",
    components: {Event, Param},
    data() {
        return {
            loading: false, route: 'Event',
        }
    },
    methods: {
        openDialog() {
            this.$refs.entity.openDialog();
        }
    }
}
</script>

<style scoped>

</style>
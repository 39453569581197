<template>
    <el-card shadow="never" class="component-container" :body-style="{padding: '10px', height: 'calc(100% - 20px)'}">
        <h4 style="margin: 0">
            {{ component.name }}
            <el-tooltip v-if="disabled" content="只有创建者才可以操作" placement="top">
                <el-icon class="color-info" style="float: right;">
                    <Lock/>
                </el-icon>
            </el-tooltip>
            <el-dropdown trigger="click" style="float: right;" v-else>
                <el-icon style="cursor: pointer">
                    <MoreFilled/>
                </el-icon>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <div @click="$emit('edit')">
                                <el-icon>
                                    <Edit/>
                                </el-icon>
                                编辑
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item class="color-error">
                            <div @click="$emit('remove')">
                                <el-icon>
                                    <Delete/>
                                </el-icon>
                                删除
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <el-icon class="handle" style="float: right;margin-right: 10px" v-if="!disabled">
                <Rank/>
            </el-icon>
            <el-icon style="float: right;margin-right: 10px;cursor: pointer" @click="$emit('view')">
                <View/>
            </el-icon>
        </h4>
        <div class="component-main" v-loading="loading">
            <template v-if="metric && valid">
                <p style="margin: 0;font-size: 12px" class="color-info">{{ subtitle }}</p>
                <component-chart :component="component" :metric="metric" :value="value" :value_date="value_date"
                                 :dod="dod" :wow="wow" ref="chart"></component-chart>
            </template>
            <template v-else>
                <b class="component-error">{{ valid ? '指标已被删除' : '组件配置错误' }}</b>
            </template>
        </div>
    </el-card>
</template>

<script>
import {timestampToDate, generateDateRange} from "../../../libs/utils";
import axios from 'ts-axios-new';
import ComponentChart from "./ComponentChart";

export default {
    name: "ComponentGrid",
    components: {ComponentChart},
    props: {
        component: Object,
        disabled: Boolean,
        dateRange: Object,
        metricList: Array,
    },
    data() {
        return {
            loading: false, data: [], metric: null, subtitle: '',
            value: null, value_date: null, dod: null, wow: null,
            valid: true,
        }
    },
    methods: {
        getDateRange() {
            const now = Date.now();
            if (this.dateRange) {
                const end = this.dateRange.end;
                let start = this.dateRange.start;
                if (new Date(end) - new Date(start) < 7 * 24 * 3600 * 1000) {
                    start = timestampToDate(new Date(end) - 7 * 24 * 3600 * 1000);
                }
                return {start, end};
            }
            let interval = 0;
            if (this.metric.category.indexOf('retain') > -1) {
                interval = this.metric.events[0].interval;
            }
            return {
                start: timestampToDate(now - (this.$root.t + this.component.latest_days + interval) * 24 * 3600 * 1000),
                end: timestampToDate(now - (this.$root.t + interval) * 24 * 3600 * 1000), chart: null,
            }
        },
        resize() {
            this.$refs.chart.resize();
        },
        init() {
            for (let i = 0; i < this.metricList.length; i++) {
                if (this.metricList[i].id === this.component.metric_id)
                    this.metric = this.metricList[i];
            }
            if (!this.metric)
                return
            this.loading = this.valid = true;
            this.component.groupings.forEach(g => {
                let valid = false;
                this.component.filters.forEach(f => {
                    if (f.key === g) {
                        valid = true;
                    }
                });
                if (!valid) {
                    this.valid = valid;
                }
            });
            if (!this.valid) {
                this.loading = false;
                return
            }
            const params = this.getDateRange();
            axios.post(`/api/v1/growth/data`, {
                component: this.component,
                product_id: this.$route.params.productId, ...params
            }).then(res => {
                if (res.data.data.data.length) {
                    const x = this.dateRange ? generateDateRange(this.dateRange.start, this.dateRange.end) : generateDateRange(params.start, params.end);
                    if (this.component.category === 'number') {
                        this.subtitle = this.value_date = res.data.data.data[res.data.data.data.length - 1].date.substring(5);
                        this.value = res.data.data.data[res.data.data.data.length - 1].val;
                        if (res.data.data.data.length > 1)
                            this.dod = (this.value - res.data.data.data[res.data.data.data.length - 2].val) / res.data.data.data[res.data.data.data.length - 2].val * 100
                        if (res.data.data.data.length > 7)
                            this.wow = (this.value - res.data.data.data[res.data.data.data.length - 8].val) / res.data.data.data[res.data.data.data.length - 8].val * 100
                    } else {
                        this.subtitle = `${x[0].substring(5)} ~ ${x[x.length - 1].substring(5)}`
                        if (!this.component.groupings.length) {
                            this.value_date = res.data.data.data[res.data.data.data.length - 1].date.substring(5);
                            this.value = res.data.data.data[res.data.data.data.length - 1].val;
                            if (res.data.data.data.length > 1)
                                this.dod = (this.value - res.data.data.data[res.data.data.data.length - 2].val) / res.data.data.data[res.data.data.data.length - 2].val * 100
                            if (res.data.data.data.length > 7)
                                this.wow = (this.value - res.data.data.data[res.data.data.data.length - 8].val) / res.data.data.data[res.data.data.data.length - 8].val * 100
                        }
                    }
                    const series = [];
                    const group_data = {};
                    res.data.data.data.forEach(d => {
                        // if (x.indexOf(d.date.substring(5)) < 0) {
                        //     x.push(d.date.substring(5));
                        // }
                        const groupings = [];
                        this.component.groupings.forEach(g => {
                            groupings.push(d[g]);
                        });
                        const key = groupings.join('-');
                        group_data[key] ? group_data[key].push([d.date, d.val]) : group_data[key] = [[d.date, d.val]];
                    });
                    for (let k in group_data) {
                        series.push({
                            type: this.component.category,
                            name: k || this.component.name,
                            data: group_data[k]
                        });
                        if (series.length === this.component.limit)
                            break;
                    }
                    if (this.component.category !== 'number' && this.$refs.chart) {
                        this.$refs.chart.draw(x, series);
                    }
                }
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.component-container {
    width: 100%;
    height: 100%;
    overflow: unset;
}

.component-main {
    width: 100%;
    height: calc(100% - 22px);
    position: relative;
    padding: 5px 0;
}

.component-error {
    font-size: 1.5em;
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 77px);
    color: var(--el-text-color-placeholder)
}
</style>
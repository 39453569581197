<template>
    <el-dialog v-model="dialog_opened" title="配置指标">
        <el-row style="margin-left: 110px; margin-bottom: 20px">
            <el-select  placeholder="载入指标组" :disabled="metricGroups.length === 0" v-model="selectedGroup"
                       value-key="name" @change="selectMetricGroup" style="width: 200px" clearable>
                <el-option v-for="d in metricGroups" :key="d.name" :value="d.id" :label="d.name"></el-option>
            </el-select>
            <el-button type="primary"  @click="saveMetricGroup" style="margin-left: 0">保存指标组</el-button>
            <el-button type="danger"  @click="removeMetricGroup" :disabled="! selectedGroup">删除指标组
            </el-button>
        </el-row>
        <el-row style="margin: 10px">
            <el-form label-width="150px">
                <el-form-item v-for="c in categoryList" :key="c.value"  :label="c.name">
                    <el-button v-for="metric in metrics.filter(m => m.category === c.value)"
                               :key="metric.id" border style="margin: 2px" @click="clickMetric(metric.id)"
                               :type="selected.includes(metric.id) ? 'primary' : 'plain'" :disabled="! metric.init">
                        <el-popover placement="top" width="300" trigger="hover">
                            <template #reference>{{ metric.name }}{{ metric.init ? '' : '(未初始化)' }}</template>
                            <DWMetricPreviewer :data="metric"></DWMetricPreviewer>
                        </el-popover>
                    </el-button>
                </el-form-item>
            </el-form>
            <el-button type="success"  @click="$refs.editor.openDialog()">新增指标</el-button>
        </el-row>
        <template #footer>
            <el-button type="primary" text @click="dialog_opened = false">取消</el-button>
            <el-button type="primary" @click="applyMetrics">应用</el-button>
        </template>
        <metric-editor ref="editor" @add="afterCreateMetric"></metric-editor>
    </el-dialog>
</template>

<script>
import DWMetricPreviewer from "../metric/DWMetricPreviewer";
import {MetricCategoryList} from "../metric/data";
import axios from 'ts-axios-new';
import {update} from "../../../libs/utils";
import MetricEditor from "../metric/MetricEditor";

export default {
    name: "ExpReportMetric",
    emits: ['change'],
    components: {MetricEditor, DWMetricPreviewer},
    props: {
        modelValue: Array,
        metrics: Array,
        need_dau: Boolean,
    },
    data() {
        return {
            selected: [], dialog_opened: false, metricMap: {}, selectedGroup: '', metricGroups: [],
            saving: false,
        }
    },
    methods: {
        selectMetricGroup() {
            this.metricGroups.forEach(mg => {
                if (mg.id === this.selectedGroup) {
                    this.selected = mg.metrics;
                }
            })
        },
        fetchMetricGroup() {
            this.saving = true;
            axios.get(`/api/v1/metric-group`, {params: {product_id: this.$route.params.productId}}).then(resp => {
                this.saving = true;
                this.metricGroups = resp.data.data;
            }).catch(err => {
                this.saving = false;
            })
        },
        saveMetricGroup() {
            if (this.selectedGroup) {
                this.saving = true;
                axios.put(`/api/v1/metric-group/${this.selectedGroup}`, {metrics: this.selected}).then(_ => {
                    this.fetchMetricGroup();
                    this.saving = false;
                    this.$message.success('已更新指标组')
                }).catch(err => {
                    this.saving = false;
                })
            } else {
                this.createMetricGroup();
            }
        },
        createMetricGroup() {
            this.$prompt('请输入指标组名称', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                inputValidator: (value) => {
                    if (!value) {
                        return '名称不能为空！'
                    } else {
                        let valid = true;
                        this.metricGroups.forEach(s => {
                            if (s.name === value) {
                                valid = false;
                            }
                        });
                        return valid || '配置名称重复！';
                    }
                },
            }).then(({value}) => {
                this.saving = true;
                axios.post(`/api/v1/metric-group`, {
                    name: value,
                    metrics: this.selected,
                    product_id: this.$route.params.productId
                }).then(resp => {
                    this.selectedGroup = resp.data.data.id;
                    this.metricGroups.push(resp.data.data);
                    this.saving = false;
                }).catch(err => {
                    this.saving = false;
                })
            }).catch(_ => {
            })
        },
        removeMetricGroup() {
            this.$confirm('确定要删除该指标组吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.saving = true;
                axios.delete(`/api/v1/metric-group/${this.selectedGroup}`).then(_ => {
                    this.saving = false;
                    this.selectedGroup = '';
                    this.fetchMetricGroup();
                }).catch(err => {
                    this.saving = false;
                })
            }).catch(_ => {
            })
        },
        clickMetric(mid) {
            if (this.selected.includes(mid)) {
                this.selected.splice(this.selected.indexOf(mid), 1);
            } else {
                this.selected.push(mid);
            }
        },
        openDialog() {
            this.dialog_opened = true;
            this.selected = this.modelValue.slice();
            this.metrics.forEach(m => {
                this.metricMap[m.id] = m.name;
            })
        },
        afterCreateMetric(newMetric) {
            this.metricMap[newMetric.id] = newMetric.name;
            this.metric_list.push(newMetric);
            this.selected.push(newMetric.id);
        },
        applyMetrics() {
            update(this.modelValue, this.selected);
            this.$emit("change");
            this.dialog_opened = false;
        }
    },
    mounted() {
        this.fetchMetricGroup();
    },
    computed: {
        categoryList() {
            const ignore = ['af_dau', 'first_open', 'af_first_open', 'af_retain', 'combination', 'event_value_sum', 'event_count', 'user_count'];
            let categoryList = this.need_dau ? MetricCategoryList : MetricCategoryList.slice(1);
            return categoryList.filter(c => !ignore.includes(c.value));
        }
    }
}
</script>

<style scoped>
</style>

<template>
    <h4>实验详情</h4>
    <p>
        名称：{{ form.name }}
        <el-icon style="cursor: pointer" @click="updateName">
            <Edit></Edit>
        </el-icon>
    </p>
    <p>描述：{{ form.desc || '无' }}</p>
    <p>所属层：{{ layer ? layer.name : '' }}</p>
    <p style="position: relative">
        层占比：{{ form.percent }}%
        <el-button type="primary" text  @click="openSlider" v-if="form.status === 'published'">修改</el-button>
        <span style="position: absolute;left: 150px;top: -10px" v-if="slider">
                <el-slider :min="min" :max="max" :marks="marks" style="float: left;width: 600px"
                           v-model="percent"></el-slider>
                <span style="float: left;line-height: 40px">{{ max }}%</span>
                <el-button  style="float: left;margin-left: 10px" @click="slider=false">取消</el-button>
                <el-button type="primary"  style="float: left;"
                           @click="submitSlider">确定</el-button>
            </span>
    </p>
    <p>最小版本：{{ form.version ? form.method : '' }} {{ form.version }}</p>
    <p>国家：{{ form.country.join('、') || '全球' }}</p>
    <p>设备类型：{{ form.device_category.length ? {tablet: '平板', mobile: '手机'}[form.device_category[0]] : '全部' }}</p>
    <p>安装来源：{{ form.af_status.length ? {Organic: '自然量', 'Non-organic': '非自然量'}[form.af_status[0]] : '全部' }}</p>
    <p>媒体渠道：{{ form.media_source.join('、') || '全部' }}</p>
    <p>Campaign：{{ form.campaign_id.join('、') || '全部' }}</p>
    <p>空跑实验：{{ form.is_empty ? '是' : '否' }}</p>
    <p v-if="user_layer_define.length > 0">
        用户分层标签：
    </p>
    <div v-for="(_, k) in form.user_layers" :key="k" style="margin: 10px 50px">
        <template v-for="l in user_layer_define">
            <span v-if="k===l.id">
                {{ l.name }}
                <el-tag effect="plain" size="medium" v-for="(tag, i) in form.user_layers[k]" :key="i"
                        style="margin-left: 10px">
                    <template v-for="ll in l.labels">
                        <span v-if="tag===ll.id">{{ ll.name }}</span>
                    </template>
                </el-tag>
            </span>
        </template>
    </div>
    <h4>实验配置</h4>
    <el-switch v-if="form.status==='published' && is_empty" v-model="form.is_empty" active-text="空跑实验"></el-switch>
    <el-table :data="form.groups">
        <el-table-column label="组名" width="200" prop="name"></el-table-column>
        <el-table-column label="占比" width="100">
            <template #default="scope">
                {{ (100 / form.groups.length).toFixed(2) }}%
            </template>
        </el-table-column>
        <el-table-column label="配置" width="100" v-if="!form.is_empty">
            <template #default="scope">
                <el-button type="primary" text @click="openDialog(scope.row, form.status !=='published')"
                           class="color-success">{{ form.status === 'published' ? '编辑' : '查看' }}Json
                </el-button>
            </template>
        </el-table-column>
        <el-table-column label="历史记录" width="200" v-if="form.status !== 'pending'">
            <template #default="scope">
                <el-button type="primary" text @click="$refs.json.openDialog(scope.$index)">
                    <span v-if="scope.row.tags">查看记录({{ scope.row.tags.length }})</span>
                </el-button>
            </template>
        </el-table-column>
        <el-table-column label="Tag" width="100" prop="tag"></el-table-column>
        <el-table-column label="描述" width="200" prop="desc"></el-table-column>
        <el-table-column label="操作" width="200" v-if="form.status==='published'">
            <template #default="scope">
                <el-button type="success"  @click="copyGroup(scope.row)">复制</el-button>
                <el-button type="danger" @click="removeGroup(scope.row)"
                           v-if="new_groups.indexOf(scope.row) > -1">删除
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-row>
        <el-button type="primary"  style="margin-top: 10px" v-if="form.status === 'published'"
                   @click="openDialog()">添加实验组
        </el-button>
    </el-row>
    <el-divider></el-divider>
    <h4>实验指标</h4>
    <ExpMetricEditor :form="form" :disabled="form.status !== 'published'"></ExpMetricEditor>
    <el-dialog v-model="dialog_opened">
        <el-form :model="inner_form" ref="form" label-width="100px">
            <el-form-item label="名称" prop="name" :rules="[{required: true, message: '请输入名称'}]">
                <el-input v-model="inner_form.name"></el-input>
            </el-form-item>
            <el-form-item label="Json" prop="data" :rules="[{validator}]">
                <json-input v-model="inner_form.data" :mode="read_only?'preview':'code'"
                            @status="statusChangeHandle"></json-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text @click="resetForm">取消</el-button>
            <el-button type="primary" @click="submitForm" :disabled="submit_disabled" :loading="inner_loading"
                       v-if="form.status==='published'&&!read_only">确定
            </el-button>
        </template>
    </el-dialog>
    <exp-group-history ref="json" :form="form"></exp-group-history>
</template>

<script>
import ExpMetricEditor from "./ExpMetricEditor";
import JsonInput from "../../../base/JsonInput";
import ExpGroupHistory from "./ExpGroupHistory";
import axios from 'ts-axios-new';
import {update, deepcopy} from "../../../libs/utils";

export default {
    name: "ExpEditorStep3",
    components: {ExpGroupHistory, JsonInput, ExpMetricEditor},
    props: {form: Object},
    data() {
        return {
            inner_loading: false, layer: null, user_layer_define: [], dialog_opened: false, slider: false,
            submit_disabled: false, editing: null, avg_bucket: 0, new_groups: [], marks: {}, read_only: false,
            min: 0, max: 100, raw_min: 0, percent: 0, modify_groups: [], is_empty: this.form.is_empty,
            inner_form: {
                name: '',
                data: {},
            },
        }
    },
    methods: {
        init() {
            this.inner_loading = true;
            this.avg = parseInt(this.form.percent / this.form.groups.length);
            this.min = this.raw_min = this.form.percent;
            this.marks[this.min] = this.min + '%';
            axios.all([
                axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer/' + this.form.layer_id).then(res => {
                    this.layer = res.data.data;
                    this.layer.exps.forEach(e => {
                        if (e.id !== this.form.id) {
                            this.max -= e.percent;
                        }
                    });
                })
            ]).then(_ => {
                this.inner_loading = false;
            });
            this.avg_bucket = parseInt(this.form.percent / this.form.groups.length);
        },
        resetForm() {
            this.editing = null;
            this.dialog_opened = false;
            this.inner_form = {
                name: '',
                data: {},
            }
        },
        openSlider() {
            this.slider = true;
            this.percent = this.form.percent;
        },
        submitSlider() {
            this.form.percent = this.percent;
            this.slider = false;
        },
        openDialog(g, read_only) {
            if (g) {
                this.editing = g;
                this.inner_form = deepcopy(g);
            } else {
                this.inner_form = deepcopy(this.form.groups[0]);
            }
            this.dialog_opened = true;
            this.read_only = !!read_only;
        },
        getMaxTag() {
            let index = '-1';
            this.form.groups.forEach(g => {
                let _ = g.tag[2];
                if (_ > index) {
                    index = _;
                }
            });
            return index;
        },
        copyGroup(g) {
            this.inner_form = deepcopy(g);
            this.dialog_opened = true;
        },
        statusChangeHandle($event) {
            this.submit_disabled = !$event
        },
        submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.inner_loading = true;
                    axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/params/check', {
                        data: this.inner_form.data,
                        product_id: this.form.product_id,
                        version: this.form.version,
                        boundary: {
                            country: this.form.country,
                            user_layers: this.form.user_layers,
                            device_category: this.form.device_category,
                            af_status: this.form.af_status,
                            media_source: this.form.media_source,
                            campaign_id: this.form.campaign_id,
                        },
                        exp_id: this.form.id,
                    }).then(res => {
                        this.inner_loading = false;
                        const invalid = [];
                        for (const key in res.data.data) {
                            if (!res.data.data[key]) {
                                invalid.push(key);
                            }
                        }
                        if (!invalid.length) {
                            let is_new = false;
                            this.new_groups.forEach(g => {
                                if (g.tag === this.inner_form.tag) {
                                    is_new = true;
                                }
                            });
                            this.modify_groups.forEach(g => {
                                if (g.tag === this.inner_form.tag) {
                                    is_new = true;
                                }
                            });
                            if (!is_new) {
                                const new_tag = this.getMaxTag();
                                if (new_tag === 'z') {
                                    this.$alert('使用tag已达上限，无法修改');
                                    return
                                }
                                const str = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                                this.inner_form.tag = this.inner_form.tag.slice(0, 2) + str[str.indexOf(new_tag) + 1] + '|';
                            }
                            if (this.editing) {
                                update(this.editing, this.inner_form);
                                this.editing.data = this.inner_form.data;
                                this.modify_groups.push(this.inner_form);
                                this.resetForm();
                            } else {
                                const new_percent = this.form.percent + parseInt(this.form.percent / this.form.groups.length);
                                if (new_percent > this.max) {
                                    this.$alert('剩余实验流量不允许再增加实验组');
                                } else {
                                    this.$confirm('增加实验组将使实验的流量增加至' + new_percent + '%，确定要增加吗？').then(_ => {
                                        this.new_groups.push(this.inner_form);
                                        this.form.groups.push(this.inner_form);
                                        this.resetForm();
                                        this.form.percent = new_percent;
                                        this.min = this.raw_min + this.new_groups.length * this.avg;
                                        this.marks[this.min] = this.min + '%';
                                    }).catch(_ => {
                                    })
                                }
                            }
                        } else {
                            this.$alert('<p>参数 ' + invalid.join(',') + ' 无法创建，需满足以下条件：</p><p>1、同域下不可以有正在做实验的参数；</p><p>2、若参数已存在则数据类型需要保持一致</p>', '创建失败', {
                                type: 'error',
                                dangerouslyUseHTMLString: true,
                            })
                        }
                    });
                }
            })
        },
        validator(rule, value, callback) {
            // let valid = true;
            // for (let key in (this.editing ? this.editing.data : this.form.groups[0].data)) {
            //     if (value[key] === undefined) {
            //         valid = false;
            //     }
            // }
            // for (let key in value) {
            //     if ((this.editing ? this.editing.data : this.form.groups[0].data)[key] === undefined) {
            //         valid = false;
            //     }
            // }
            // if (valid) {
            //     callback();
            // } else {
            //     callback('该组配置的key与其它组不同!');
            // }
            callback();
        },
        removeGroup(g) {
            this.form.percent -= parseInt(this.form.percent / this.form.groups.length);
            this.new_groups.splice(this.new_groups.indexOf(g), 1);
            this.form.groups.splice(this.form.groups.indexOf(g), 1);
            this.min = this.raw_min + this.new_groups.length * this.avg;
        },
        replaceTag() {
            const new_tag = this.getMaxTag();
            if (new_tag > 8) {
                this.$alert('使用tag已达上限，无法修改');
                return
            }
            this.form.groups[0].tag = this.inner_form.tag.slice(0, 2) + (new_tag + 1) + '|';
            this.resetForm();
        },
        updateName() {
            this.$prompt('请输入名称', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                inputValue: this.form.name,
                inputPattern: /\w+/,
                inputErrorMessage: '请输入名称',
            }).then(({value}) => {
                this.form.name = value;
            }).catch(_ => {
            })
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>
.el-button.el-button--text.color-success {
    color: var(--el-color-success);
}
</style>

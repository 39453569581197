<template>
    <el-table height="100%" :data="tableData" ref="table"
              v-loading="loading" show-summary :summary-method="SummaryMethod" border
              @selection-change="SelectionChange" :default-sort="{prop: 'spend', order: 'descending'}"
              @sort-change="sortChangeHandle">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="Preview" width="140">
            <template #default="scope">
                <preview :data="scope.row"></preview>
            </template>
        </el-table-column>
        <el-table-column label="Name" width="300" prop="name" sortable="custom"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="Date" width="100" prop="date" sortable v-if="groupings.includes('date')"></el-table-column>
        <el-table-column label="Country" width="80" prop="country" sortable v-if="groupings.includes('country')"></el-table-column>
        <el-table-column label="Status" width="90" prop="status"></el-table-column>
        <el-table-column label="Type" prop="type" width="100"></el-table-column>
        <el-table-column label="CPI" width="100" prop="cpi" align="right" header-align="left"
                         sortable="custom"></el-table-column>
        <el-table-column label="Install" width="120" prop="install" align="right" header-align="left"
                         sortable="custom">
            <template #default="scope">
                {{ formatInt(scope.row.install) }}
            </template>
        </el-table-column>
        <el-table-column label="Spend" width="120" prop="spend" align="right" header-align="left" sortable="custom">
            <template #default="scope">
                {{ formatInt(scope.row.spend) }}
            </template>
        </el-table-column>
        <el-table-column label="Impression" width="120" prop="impression" align="right" header-align="left"
                         sortable="custom">
            <template #default="scope">
                {{ formatInt(scope.row.impression) }}
            </template>
        </el-table-column>
        <el-table-column label="eCPM" width="100" prop="ecpm" align="right" header-align="left"
                         sortable="custom"></el-table-column>
        <el-table-column label="Click" width="120" prop="click" align="right" header-align="left" sortable="custom">
            <template #default="scope">
                {{ formatInt(scope.row.click) }}
            </template>
        </el-table-column>
        <el-table-column v-for="h in retentionHeaders" :label="h" :prop="h" width="100">
            <template #default="scope">
                {{ scope.row[h] }}%
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import {formatInt, deepcopy} from "../../libs/utils";
import Preview from './Preview.vue'
import axios from 'ts-axios-new';

export default {
    name: "AdTable",
    components: {Preview},
    data() {
        return {
            loading: false,
            selectedRows: [],
            tableData: [],
            allData: [],
            is_selected_change: false,
            retentionHeaders: [],
            limit: 100,
            groupings: [],
        }
    },
    props: {
        selectedCampaign: Array,
        selectedAdset: Array,
    },
    watch: {
        selectedCampaign(newVal, oldVal) {
            this.is_selected_change = true
        },
        selectedAdset(newVal, oldVal) {
            this.is_selected_change = true
        },
    },
    methods: {
        sortChangeHandle(param) {
            this.allData.sort((a, b) => {
                if (param.order === 'ascending') {
                    return a[param.prop] < b[param.prop] ? -1 : 1
                } else if (param.order === 'descending') {
                    return a[param.prop] > b[param.prop] ? -1 : 1
                } else {
                    return a.spend > b.spend ? -1 : 1
                }
            })
            this.refreshTable(true)
        },
        refreshTable(flag) {
            if (!flag && !this.is_selected_change) return
            const tableData = this.allData.filter(item => {
                return (this.selectedCampaign.length === 0 || this.selectedCampaign.findIndex(campaign_id => campaign_id === item.campaign_id) > -1)
                    && (this.selectedAdset.length === 0 || this.selectedAdset.findIndex(adset_id => adset_id === item.adset_id) > -1)
            })
            this.tableData = tableData.slice(0, this.limit)
            this.is_selected_change = false

            this.requestPreviewImageBatch()
        },
        requestPreviewImageBatch() {
            const thumbArr = [], videoArr = [];
            this.tableData.forEach(data => {
                if (!data.preview_url) {
                    if (data.video_id) {
                        videoArr.push(data.video_id)
                    } else if (data.creative_id) {
                        thumbArr.push(data.creative_id)
                    }
                }
            })
            // 批量获取广告图片的地址，广告视频的地址，视频的截图。
            const batch = [], creativeRes = {}, videoRes = {}, thumbRes = {};
            let i = 0;
            while (thumbArr.length > i) {
                batch.push(axios.get(`/facebook/`, {
                    params: {
                        ids: thumbArr.slice(i, i + 50).join(','),
                        fields: 'image_url,thumbnail_url'
                    }
                }).then(res => {
                    for (let key in res.data) {
                        creativeRes[key] = res.data[key];
                    }
                }));
                i += 50;
            }
            i = 0;
            while (videoArr.length > i) {
                batch.push(axios.get(`/facebook/`, {
                    params: {
                        ids: videoArr.slice(i, i + 50).join(','),
                        fields: 'source'
                    }
                }).then(res => {
                    for (let key in res.data) {
                        videoRes[key] = res.data[key];
                    }
                }));
                batch.push(axios.get(`/facebook/thumbnails/`, {params: {ids: videoArr.slice(i, i + 50).join(',')}}).then(res => {
                    for (let key in res.data) {
                        videoRes[key] = res.data[key];
                    }
                }));
                i += 50;
            }
            axios.all(batch).then(_ => {
                this.tableData.forEach(data => {
                    if (data.video_id) {
                        if (videoRes[data.video_id]) {
                            data.video_url = videoRes[data.video_id].source
                            if (thumbRes[data.video_id]) {
                                data.thumbnails = thumbRes[data.video_id].data.map(thumb => {
                                    return thumb.uri
                                }) || []
                                data.preview_url = data.thumbnails[0]
                            }
                        }
                    } else if (creativeRes[data.creative_id]) {
                        data.preview_url = creativeRes[data.creative_id].thumbnail_url
                        data.thumbnails = creativeRes[data.creative_id].image_url ? [creativeRes[data.creative_id].image_url] : []
                    }
                })
            })
        },
        init(data, adList, headers, groupings) {
            this.$refs.table.clearSort()
            data.forEach(item => {
                item.cpi = item.install ? parseFloat((item.spend / item.install).toFixed(2)) : null;
                item.ecpm = parseFloat((item.spend / item.impression * 1000).toFixed(2));
                const content = adList.find(element => element.ad_id === item.ad_id)
                if (content) {
                    item.name = content.name
                    item.creative_id = content.creative_id
                    item.campaign_id = content.campaign_id
                    item.adset_id = content.adset_id
                    if (content.video_id) item.type = 'VIDEO'
                    else if (content.image_has) item.type = 'IMAGE'
                    else item.type = ''
                    item.status = content.status
                    item.video_id = content.video_id
                }
            });
            this.allData = data
            this.retentionHeaders = headers;
            this.groupings = groupings;
            this.sortChangeHandle({prop: 'spend', order: 'descending'})
        },
        formatInt(value) {
            return formatInt(value);
        },
        SummaryMethod({columns, data}) {
            const summary = [];
            columns.forEach((c, i) => {
                if (['spend', 'impression', 'click', 'install'].indexOf(c.property) > -1) {
                    summary[i] = 0;
                    (this.selectedRows.length ? this.selectedRows : data).forEach(d => {
                        summary[i] += d[c.property];
                    });
                    summary[i] = parseFloat((summary[i]).toFixed(2));
                } else if (c.property === 'cpi') {
                    let spend = 0, install = 0;
                    (this.selectedRows.length ? this.selectedRows : data).forEach(d => {
                        spend += d.spend;
                        install += d.install;
                    });
                    summary[i] = parseFloat((spend / install).toFixed(2));
                } else if (c.property === 'ecpm') {
                    let spend = 0, impression = 0;
                    (this.selectedRows.length ? this.selectedRows : data).forEach(d => {
                        spend += d.spend;
                        impression += d.impression;
                    });
                    summary[i] = parseFloat((spend / impression * 1000).toFixed(2));
                } else if (c.property === 'name') {
                    summary[i] = '合计'
                } else if (['campaignIds', 'CreativePackIds'].indexOf(c.property) > -1) {
                    summary[i] = 0;
                    (this.selectedRows.length ? this.selectedRows : data).forEach(d => {
                        summary[i] += d[c.property] ? d[c.property].length : 0;
                    });
                } else {
                    summary[i] = null;
                }
            });
            return summary;
        },
        SelectionChange(rows) {
            this.selectedRows = rows;
        }
    },
    mounted() {
        // this.init();
    }
}
</script>

<template>
    <el-card class="metric-card" shadow="never" :body-style="{padding: '22px'}">
        <slot></slot>
    </el-card>
</template>

<script>
export default {
    name: "MetricCard",
}
</script>

<style>
.metric-card {
    width: 300px;
}

.metric-card .title {
    margin: 5px;
    font-size: 12px;
    line-height: 20px;
    color: #5F6368
}

.metric-card .value {
    margin: 5px;
    font-size: 36px;
    line-height: 44px;
}
</style>
<template>
    <!-- <img v-if="preview_url" src="" alt=""> -->
    <el-image
        style="width: 100px; height: 100px"
        :src="data.preview_url"
        fit="contain"
        placeholder=" "
        :preview-src-list="data.thumbnails">
    </el-image>
    <el-link
        v-if="data.type === 'VIDEO'"
        :disabled="!data.video_url"
        :href="data.video_url"
        type="primary"
        target="_blank"
        class="video-link">
        新标签页打开视频
    </el-link>
</template>

<script>
export default {
    name: "Preview",
    data() {
        return {}
    },
    props: {
        data: Object,
    },
    methods: {},
    mounted() {

    }
}
</script>
<style scoped>
.video-link {
    font-size: 12px;
}
</style>
<template>
    <el-row>
        <el-col :lg="18" :sm="16" :xs="12">
            <el-form :model="versionForm" ref="version" label-width="100px" style="width: 90%">
                <el-form-item label="发版时间" prop="c_time" v-if="first_edit">
                    <el-date-picker type="datetime" v-model="versionForm.c_time" :disabled="loading"
                                    style="width: 180px"></el-date-picker>
                </el-form-item>
                <el-form-item label="覆盖比例" prop="percent" v-if="first_edit"
                              :rules="[{type: 'number', message: '请输入0~100之间的数字', min: 0, max: 100}]">
                    <el-input v-model.number="versionForm.percent" style="width: 120px; margin-top: 6px"
                              :disabled="loading">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="更新内容" prop="content"
                              :rules="[{required: true, message: '请输入版本记录'}]">
                    <el-card shadow="never" v-if="!log_editing && !first_edit" :body-style="{padding: '0 10px'}"
                             style="width: 100%">
                        <pre style="margin: 0">{{ versionForm.content }}</pre>
                    </el-card>
                    <el-input v-model="versionForm.content" type="textarea" :rows="6" :disabled="loading || saving"
                              v-else resize="none"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitVersionForm" :loading="saving"
                               v-if="log_editing || first_edit">保存
                    </el-button>
                    <el-button type="primary" text @click="log_editing=false" v-if="log_editing">取消</el-button>
                    <el-button type="success" @click="log_editing=true" v-else-if="!first_edit">编辑
                    </el-button>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="操作记录" v-if="!first_edit">
                    <el-timeline style="width: 100%">
                        <el-timeline-item v-for="n in note_list" :key="n.id" :timestamp="timestampToDate(n.c_time)"
                                          :size="n.percent===100?'large':'normal'" placement="top"
                                          :type="n.percent===0?'danger':n.percent===100?'success':'info'">
                            <el-card shadow="never">
                                <template #header>
                                    版本推送
                                    <el-icon style="float: right;margin: 13px;cursor: pointer" @click="removeNote(n)">
                                        <CloseBold/>
                                    </el-icon>
                                </template>
                                <p style="margin: 0">{{ n.version }} 版本
                                    {{ n.percent === 0 ? '停止发布' : 'Rollout ' + n.percent + '%' }}</p>
                                <p class="color-info" style="margin: 0;font-size: 12px;text-align: right">
                                    <user v-model="n.user_id"></user>
                                    提交于{{ timestampToDatetime(n.u_time) }}
                                </p>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :lg="6" :sm="8" :xs="12">
            <el-card shadow="never" v-if="! first_edit" style="text-align: center">
                <div @click="openDialog()" slot="header">
                    <h4>当前版本发布进度</h4>
                    <el-tooltip content="点击修改覆盖比例" placement="top-start">
                        <el-progress type="dashboard" :percentage="versionForm.percent" v-loading="saving"
                                     :color="colors" style="cursor: pointer"></el-progress>
                    </el-tooltip>
                </div>
                <div style="text-align: center">
                    <el-button type="success" @click="createNote(100)"
                               :disabled="versionForm.percent===100||versionForm.percent===0">
                        {{ versionForm.percent === 100 ? '已' : '' }}全量发布
                    </el-button>
                    <el-button type="danger" @click="createNote(0)"
                               :disabled="versionForm.percent===100||versionForm.percent===0">
                        {{ versionForm.percent === 0 ? '已' : '' }}停止发布
                    </el-button>
                </div>
            </el-card>
        </el-col>
        <el-dialog v-model="dialog_opened" :before-close="resetNote" :close-on-click-modal="false"
                   width="360px" :title="`${editing ? '编辑' : '新增'}进度记录`">
            <el-form :model="noteForm" ref="note" label-width="100px">
                <el-form-item label="覆盖率" prop="percent"
                              :rules="[{required: true, type: 'number', message: '请输入0~100之间的数字', min: 0, max: 100}]">
                    <el-input v-model.number="noteForm.percent">
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="时间" prop="c_time" :rules="[{required: true, message: '请选择时间'}]">
                    <el-date-picker type="datetime" v-model="noteForm.c_time"></el-date-picker>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" text :loading="saving" @click="resetNote">取消</el-button>
                <el-button type="primary" :loading="saving" @click="submitNoteForm">确定</el-button>
            </template>
        </el-dialog>
    </el-row>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate, timestampToDatetime, update} from "../../../libs/utils";
import User from "../../../base/User";

export default {
    name: "VersionDetailPanel",
    components: {User},
    props: {
        loading: Boolean,
        modelValue: Object,
        metric_list: Array,
    },
    data() {
        return {
            colors: [
                {percentage: 60, color: 'var(--el-color-error)'},
                {percentage: 100, color: 'var(--el-color-warning)'},
                {percentage: 100, color: '#5cb87a'}
            ],
            editing: null, versionForm: {c_time: 0, content: '', percent: 0}, log_editing: false,
            noteForm: {
                percent: 0, c_time: 0, user_id: this.$root.user.userid, content: ''
            }, saving: false, note_list: [], dialog_opened: false, first_edit: !this.modelValue.content,
        }
    },
    methods: {
        fetchNotes() {
            const api = '/api/v1/pm/version/' + this.$route.params.versionId + '/note';
            this.saving = true;
            axios.get(api).then(res => {
                this.saving = false;
                this.note_list = res.data.data.noteList;
            }).catch(err => {
                this.saving = false;
            })
        },
        submitVersionForm() {
            // 更新 version, 新增 note
            this.$refs.version.validate(valid => {
                if (valid) {
                    this.saving = true;
                    let version_form = {content: this.versionForm.content};
                    axios.put('/api/v1/pm/version/' + this.$route.params.versionId, version_form).then(version => {
                        if (this.first_edit) {
                            let note_form = {
                                c_time: this.versionForm.c_time / 1000,
                                percent: parseInt(this.versionForm.percent)
                            };
                            axios.post('/api/v1/pm/version/' + this.$route.params.versionId + '/note', note_form).then(res => {
                                this.note_list.unshift(res.data.data);
                                this.first_edit = false;
                                this.$nextTick(_ => {
                                    this.saving = false;
                                })
                                // this.fetchNotes();
                            })
                        }
                        this.saving = false;
                        // this.$message.success(`版本v${version.data.data.version}保存成功!`);
                        this.$emit("afterSave", version.data.data.id);
                    }).catch(err => {
                        this.saving = false;
                        this.$message.error(err.response.data.status.message);
                    })
                }
            })
        },
        resetNote() {
            this.dialog_opened = this.saving = false;
            this.$refs.note.resetFields();
            this.editing = null;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                // this.noteForm.content = this.versionForm.content;
                if (item) {
                    this.editing = item;
                    this.noteForm.percent = item.percent;
                    this.noteForm.c_time = new Date(item.c_time * 1000);
                } else {
                    this.noteForm.percent = this.versionForm.percent;
                    this.noteForm.c_time = Date.now();
                }
            })
        },
        createNote(percent) {
            this.openDialog();
            this.$nextTick(_ => {
                this.noteForm.percent = percent;
            });
        },
        removeNote(item) {
            this.$confirm('确定删除该记录吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.saving = true;
                axios.delete('/api/v1/pm/note/' + item.id).then(_ => {
                    this.note_list.splice(this.note_list.indexOf(item), 1);
                    this.fetchNotes();
                    this.saving = false;
                }).catch(err => {
                    console.log(err);
                });
            }).catch(_ => {
            });
        },
        submitNoteForm() {
            this.$refs.note.validate(valid => {
                if (valid) {
                    this.noteForm.c_time = parseInt(this.noteForm.c_time / 1000);
                    this.saving = true;
                    if (this.editing) {
                        axios.put('/api/v1/pm/note/' + this.editing.id, this.noteForm).then(res => {
                            this.editing = res.data.data;
                            // this.fetchNotes();
                            this.resetNote();
                        });
                    } else {
                        axios.post('/api/v1/pm/version/' + this.$route.params.versionId + '/note', this.noteForm).then(res => {
                            this.note_list.unshift(res.data.data);
                            this.versionForm.percent = res.data.data.percent;
                            // this.fetchNotes();
                            // this.sendEmail();
                            this.resetNote();
                        })
                    }
                }
            })
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp);
        },
        timestampToDatetime(timestamp) {
            return timestampToDatetime(timestamp);
        }
    },
    mounted() {
        this.fetchNotes();
    },
    watch: {
        modelValue(newVal) {
            update(this.versionForm, newVal);
            if (this.versionForm.c_time < 10000000000) {
                this.versionForm.c_time *= 1000;
            }
            this.first_edit = !this.modelValue.content;
        },
        "$route.params.productId"() {
            this.$router.push({name: 'PMArchiveVersion'});
        }
    }
}
</script>

<style scoped>
.el-timeline .el-card ::v-deep(.el-card__header) {
    padding: 0 10px;
}
</style>

<template>
    <el-row>
        <el-radio-group v-model="category" :disabled="loading">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="pending">未开始</el-radio-button>
            <el-radio-button label="published">运行中</el-radio-button>
            <el-radio-button label="finished">已应用</el-radio-button>
            <el-radio-button label="terminated">已关闭</el-radio-button>
        </el-radio-group>
        <el-button type="success" @click="$refs.editor.openDialog()">创建实验</el-button>
        <el-button type="primary" text :loading="loading" @click="$refs.exp_json.openDialog()">获取实验配置
        </el-button>
    </el-row>
    <el-table :data="dataFilter" v-loading="loading" style="margin-top: 10px;width: 1390px">
        <el-table-column label="实验名称" prop="name" width="160" sortable show-overflow-tooltip>
            <template #default="scope">
                <span class="like-button" :class="'c-' + scope.row.status" @click="openStatNewTab(scope.row)">
                    {{ scope.row.name }}
                </span>
            </template>
        </el-table-column>
        <el-table-column label="最小版本" prop="version" width="120" sortable></el-table-column>
        <el-table-column label="国家" width="150" sortable show-overflow-tooltip prop="country"></el-table-column>
        <el-table-column label="发布时间" prop="publish_time" width="110" align="center" sortable
                         v-if="!category||category!=='pending'">
            <template #default="scope">
                {{ scope.row.publish_time ? timestampToDate(scope.row.publish_time) : '' }}
            </template>
        </el-table-column>
        <el-table-column label="结束时间" prop="finish_time" width="110" align="center" sortable
                         v-if="!category || category==='finished'||category==='terminated'">
            <template #default="scope">
                {{ scope.row.finish_time ? timestampToDate(scope.row.finish_time) : '' }}
            </template>
        </el-table-column>
        <el-table-column label="实验状态" prop="status" width="100" sortable v-if="!category">
            <template #default="scope">
                {{ {pending: '未开始', published: '运行中', finished: '已应用', terminated: '已关闭'}[scope.row.status] }}
            </template>
        </el-table-column>
        <el-table-column label="应用组" prop="apply" width="120" align="center"
                         v-if="!category||category==='finished'">
            <template #default="scope">
                {{ scope.row.apply !== null ? scope.row.groups[scope.row.apply].name : '' }}
            </template>
        </el-table-column>
        <el-table-column label="所属层" prop="layer_id" width="120" align="center" sortable show-overflow-tooltip>
            <template #default="scope">
                {{ scope.row.layer_id && layer_map[scope.row.layer_id] ? layer_map[scope.row.layer_id].name : '' }}
            </template>
        </el-table-column>
        <el-table-column label="操作" width="500">
            <template #default="scope">
                <el-button size="small" type="primary" disabled v-if="!scope.row.layer_id">待编辑</el-button>
                <el-button type="primary" v-else-if="scope.row.status === 'pending'" size="small"
                           @click="publish(scope.row)">发布
                </el-button>
                <el-button type="danger" v-else-if="scope.row.status === 'published'" size="small"
                           @click="terminate(scope.row)">终止实验
                </el-button>
                <el-button type="primary" disabled v-else size="small">
                    已{{ scope.row.status === 'finished' ? '应用' : '终止' }}
                </el-button>
                <el-button type="primary" v-if="scope.row.status === 'published'" size="small"
                           @click="$refs.apply_dialog.openDialog(scope.row)">应用实验
                </el-button>
                <el-button type="success" @click="$refs.copy_exp.openDialog(scope.row)" size="small">复制</el-button>
                <el-button @click="$refs.editor.openDialog(scope.row.id)" size="small">编辑</el-button>
                <el-button @click="$refs.json.openDialog(scope.row.groups)" size="small">查看Json
                </el-button>
                <el-button type="danger" :disabled="scope.row.status !== 'pending'" size="small"
                           @click="removeExp(scope.row)">删除
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <exp-editor ref="editor" @change="init"></exp-editor>
    <json-viewer ref="json"></json-viewer>
    <copy-exp ref="copy_exp" @change="copyFinish"></copy-exp>
    <exp-json ref="exp_json"></exp-json>
    <exp-stat ref="report"></exp-stat>
    <ExpApplyDialog ref="apply_dialog" @afterSave="init()"></ExpApplyDialog>
</template>

<script>
import ExpEditor from "./ExpEditor";
import JsonViewer from "./JsonViewer";
import CopyExp from "./CopyExp";
import ExpJson from "./ExpJson";
import ExpApplyDialog from "./ExpApplyDialog";
import axios from 'ts-axios-new';
import {update, timestampToDate} from "../../../libs/utils";
import ExpStat from "./ExpStat";

export default {
    name: "ExpList",
    components: {ExpStat, ExpJson, CopyExp, JsonViewer, ExpEditor, ExpApplyDialog},
    data() {
        return {
            loading: false, data: [], layer_map: {}, category: 'published', user_layer_map: {},
            metric_list: [],
        }
    },
    provide() {
        return {
            metricList: this.metric_list,
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/layer', {params: {product_id: this.$route.params.productId}}).then(res => {
                    res.data.data.layerList.forEach(l => {
                        this.layer_map[l.id] = l;
                    });
                }), axios.get(`/api/v1/metric`, {
                    params: {
                        product_id: this.$route.params.productId,
                        contain_preset: 1
                    }
                }).then(res => {
                    update(this.metric_list, res.data.data.models);
                })
            ]).then(_ => {
                axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp', {params: {product_id: this.$route.params.productId}}).then(res => {
                    update(this.data, res.data.data.expList);
                    if (this.$route.query.expId) {
                        this.data.forEach(d => {
                            if (d.id === this.$route.query.expId) {
                                this.openStatNewTab(d);
                            }
                        })
                    }
                    this.loading = false;
                });
            })
        },
        publish(exp) {
            this.$confirm('确定要发布该实验吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + exp.id + '/status', {
                    status: 'published',
                    user_id: this.$root.user.user_id
                }).then(res => {
                    update(exp, res.data.data);
                    this.loading = false;
                    this.category = 'published'
                }).catch(err => {
                    this.loading = false;
                    this.$alert('<p>参数 ' + err.body.data.join(',') + ' 无法创建，需满足以下条件：</p><p>1、同域下不可以有正在做实验的参数；</p><p>2、若参数已存在则数据类型需要保持一致</p>', '创建失败', {
                        type: 'error',
                        dangerouslyUseHTMLString: true,
                    })
                }).catch(_ => {
                })
            })
        },
        terminate(exp) {
            this.$confirm('确定要终止 "' + exp.name + '" 实验吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + exp.id + '/status', {
                    status: 'terminated',
                    user_id: this.$root.user.user_id
                }).then(res => {
                    update(exp, res.data.data);
                    this.category = 'terminated';
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        copyFinish() {
            this.category = 'pending';
            this.init();
        },
        removeExp(exp) {
            this.$confirm('确定要删除该实验吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(_ => {
                this.loading = true;
                axios.delete(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + exp.id).then(res => {
                    this.data.splice(this.data.indexOf(exp), 1);
                    this.loading = false;
                }).catch(_ => {
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        openStatNewTab(exp) {
            this.$refs.report.open(exp);
        },
        updateName(row) {
            this.$prompt('请输入名称', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                inputValue: row.name,
                inputPattern: /\w+/,
                inputErrorMessage: '请输入名称',
            }).then(({value}) => {
                row.name = value;
                this.loading = true;
                axios.put(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + row.id, row).then(_ => {
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp)
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            this.init();
        },
        "$route.query.domain"() {
            if (this.$route.query.domain)
                this.init();
        }
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return !this.category || d.status === this.category;
            })
        }
    },
}
</script>

<style scoped>
.like-button {
    color: #409EFF;
    font-weight: 500;
    cursor: pointer;
}

.like-button:hover {
    color: #66b1ff;
}

.c-published {
    color: var(--el-color-success);
}

.c-published:hover {
    color: #85ce61;
}

.c-finished, .c-terminated {
    color: var(--el-color-info);
}

.c-finished:hover, .c-terminated:hover {
    color: #a6a9ad;
}
</style>

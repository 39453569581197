<template>
    <div>
        <el-checkbox style="float: left;margin-right: 20px" v-model="is_all_check" :indeterminate="is_indeterminate"
                     @change="changeAllCheck">全选
        </el-checkbox>
        <el-checkbox-group v-model="inner_selected" @change="changeCheck">
            <el-checkbox v-for="o in options" :key="o.id" :label="o.id">{{o.name}}</el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
import {update} from "../../../libs/utils";

export default {
    name: "UserLayerCheckbox",
    props: {
        options: Object,
        selected: Object,
    },
    data() {
        return {
            is_all_check: true, is_indeterminate: false, inner_selected: [], inner_update: false,
        }
    },
    methods: {
        changeAllCheck(val) {
            this.inner_selected = [];
            if (val) {
                this.options.forEach(o => {
                    this.inner_selected.push(o.id);
                })
            }
            this.is_indeterminate = false;
            this.updateSelect();
        },
        changeCheck() {
            this.is_indeterminate = this.inner_selected.length > 0 && this.inner_selected.length < this.options.length;
            this.is_all_check = this.inner_selected.length === this.options.length;
            this.updateSelect();
        },
        updateSelect() {
            this.inner_update = true;
            if (this.inner_selected.length < this.options.length)
                update(this.selected, this.inner_selected);
            else
                this.selected.splice(0, this.selected.length);
            this.$nextTick(_ => {
                this.inner_update = false;
            })
        },
        init() {
            update(this.inner_selected, this.selected);
            if (this.inner_selected.length === 0) {
                this.options.forEach(o => {
                    this.inner_selected.push(o.id);
                });
                this.is_all_check = true;
            }
            if (this.inner_selected.length < this.options.length) {
                this.is_indeterminate = true;
                this.is_all_check = false;
            }
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        selected() {
            if (!this.inner_update) {
                this.init();
            }
        }
    }
}
</script>

<style scoped>

</style>

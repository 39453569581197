<template>
    <div style="padding: 0 30px">
        <div style="display: flex;justify-content: space-between">
            <el-radio-group v-model="name" @change="$router.push({name, query: {domain}})" >
                <el-radio-button label="ExpList">实验管理</el-radio-button>
                <el-radio-button label="LayerList">层管理</el-radio-button>
                <el-radio-button label="ParamsList">参数管理</el-radio-button>
            </el-radio-group>
            <el-select v-model="domain" @change="$router.push({query: {domain}})" style="width: 200px">
                <el-option label="测试环境" value="elastic-test"></el-option>
                <el-option label="正式环境" value="elastic"></el-option>
            </el-select>
        </div>
        <el-divider style="margin: 10px"></el-divider>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "ABTest",
    data() {
        return {
            name: this.$route.name, domain: this.$route.query.domain || 'elastic-test',
        }
    },
    watch: {
        $route() {
            this.name = this.$route.name;
            this.domain = this.$route.query.domain;
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "AsideWrapper",
    props: {collapse: Boolean}
}
</script>

<style scoped>

</style>
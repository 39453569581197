<template>
    <aside-menu index="PMGrowthDashboard" icon="Reading" title="自定义看板" :loading="loading">
        <aside-sub-menu v-for="g in data" :icon="g.public?'Lock':'Edit'"
                        :title="g.name" index="PMGrowthDashboard" :key="g.id"
                        :query="{dashboardId: g.id}"></aside-sub-menu>
        <aside-sub-menu icon="Plus" title="新增看板" @click="$refs.editor.open()"></aside-sub-menu>
    </aside-menu>
    <DashboardEditor ref="editor" @submit="switchNewDashboard"></DashboardEditor>
</template>

<script>
import AsideMenu from "../../base/AsideMenu";
import AsideSubMenu from "../../base/AsideSubMenu";
import DashboardEditor from "./growth/DashboardEditor";
import {setLocalObj} from "../../libs/storage";

export default {
    name: "GrowthGuide",
    components: {DashboardEditor, AsideSubMenu, AsideMenu},
    inject: ['growth'],
    props: {
        data: Array,
        loading: Boolean,
        collapse: Boolean,
        fixed: Boolean,
        unfixable: Boolean,
        index: String,
    },
    methods: {
        switchNewDashboard(newBoard) {
            this.data.push(newBoard);
            this.$router.push({name: 'PMGrowthDashboard', query: {dashboardId: newBoard.id}});
        },
        saveSort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            setLocalObj(`/growthGuide/sort/${this.$route.params.productId}`, sort);
        }
    },
}
</script>

<style scoped>

</style>
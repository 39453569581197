<template>
    <div style="padding: 0 30px;height: 100%">
        <div style="display: flex;justify-content: space-between">
            <div>
                <country-selector v-model="form.country" :disabled="loading"></country-selector>
                <date-range-picker :disabled="loading" v-model="form"></date-range-picker>
                <el-select placeholder="分组" multiple v-model="form.groupings" :disabled="loading">
                    <el-option label="日期" value="date"></el-option>
                    <el-option label="国家" value="country" disabled></el-option>
                </el-select>
                <el-button type="primary" :loading="loading" @click="init" style="margin-left: 10px">查询
                </el-button>
                <el-button type="success" :loading="loading" @click="download">下载</el-button>
            </div>
        </div>
        <div style="margin-top: 10px;height: calc(100% - 38px)">
            <el-tabs v-model="card" type="card" stretch>
                <el-tab-pane name="campaign">
                    <template #label>
                        Campaign
                        <span style="float: right"> {{
                                selectedCampaign.length > 0 ? `选择 ${selectedCampaign.length} 项` : ''
                            }}</span>
                    </template>
                </el-tab-pane>
                <el-tab-pane name="adset">
                    <template #label>
                        AdGroup
                        <span style="float: right"> {{
                                selectedAdset.length > 0 ? `选择 ${selectedAdset.length} 项` : ''
                            }}</span>
                    </template>
                </el-tab-pane>
            </el-tabs>
            <el-card shadow="never" v-loading="loading"
                     style="border-radius: 8px;height: calc(100% - 44px);border: 1px solid var(--el-border-color-light)"
                     :body-style="{padding: 0, height: '100%'}">
                <campaign-table v-show="card === 'campaign'" ref="campaign"
                                @select="(data) => {selectedCampaign = data}"></campaign-table>
                <adset-table
                    v-show="card === 'adset'" ref="adset"
                    @select="(data) => {selectedAdset = data}"
                    :selected-campaign="selectedCampaign"
                ></adset-table>
            </el-card>
        </div>
    </div>
</template>

<script>
import {timestampToDate, formatInt, deepcopy, openInNewTab} from "../../libs/utils";
import axios from 'ts-axios-new';
import DateRangePicker from "../../base/DateRangePicker";
import CountrySelector from "../../base/CountrySelector";
import CampaignTable from './CampaignTable.vue';
import AdsetTable from "./AdsetTable.vue"
import FileSaver from 'file-saver';

export default {
    name: "GoogleAdsDashboard",
    components: {CountrySelector, DateRangePicker, CampaignTable, AdsetTable},
    data() {
        const now = Date.now();
        return {
            card: 'campaign', loading: false, auth: null,
            checked: [],
            selectedCampaign: [],
            selectedAdset: [],
            selectedAd: [],

            tableData: [],
            campaign_insight: [],
            adset_insight: [],
            ad_insight: [],
            campaignContent: [],
            adsetContent: [],
            adContent: [],
            campaign_retention: [],
            adset_retention: [],
            headers: [],
            form: {
                country: [],
                start: timestampToDate(now - 7 * 24 * 3600 * 1000),
                end: timestampToDate(now - 2 * 24 * 3600 * 1000),
                groupings: [],
            },
        }
    },
    methods: {
        download() {
            const sheet0 = XLSX.utils.table_to_sheet(this.$refs.campaign.$refs.table.$refs.bodyWrapper.parentElement);
            const wb = {SheetNames: ['Campaign'], Sheets: {Campaign: sheet0}};
            const wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: false, type: 'array'});
            const blob = new Blob([wbout], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `GoogleAds投放.xlsx`);
        },
        init() {
            this.loading = true
            const country = [];
            this.form.country.forEach(c => {
                this.$root.country_list.forEach(cc => {
                    if (cc.code === c) {
                        country.push(cc.ip_name);
                    }
                })
            });
            let product = null;
            this.$root.product_list.forEach(p => {
                if (p.id === this.$route.params.productId) {
                    product = p;
                }
            });
            axios.all([
                axios.get(`/api/v1/ua/googleads/insight`, {
                    params: {
                        ...this.form,
                        breakdown: 'campaign',
                        product_id: this.$route.params.productId,
                        platform: product.platform,
                    }
                }).then(res => {
                    this.campaign_insight = res.data.data.insightList;
                }),
                axios.get(`/api/v1/ua/googleads/insight`, {
                    params: {
                        ...this.form,
                        breakdown: 'adset',
                        product_id: this.$route.params.productId,
                        platform: product.platform,
                    }
                }).then(res => {
                    this.adset_insight = res.data.data.insightList;
                }),
                axios.post(`/api/v1/retention/${this.$route.params.productId}/report`, {
                    dimensions: ['campaign_id'].concat(this.form.groupings),
                    start: this.form.start,
                    end: this.form.end,
                    min_installs: 1,
                    filters: [
                        {key: 'media_source', operator: 'in', values: ['google']},
                        {key: 'country', operation: 'in', values: country},
                    ],
                }).then(res => {
                    this.campaign_retention = res.data.data.data;
                    this.headers = res.data.data.headers.slice(3 + this.form.groupings.length);
                }),
                axios.post(`/api/v1/retention/${this.$route.params.productId}/report`, {
                    dimensions: ['adset_id'].concat(this.form.groupings),
                    start: this.form.start,
                    end: this.form.end,
                    min_installs: 1,
                    filters: [
                        {key: 'media_source', operator: 'in', values: ['google']},
                        {key: 'country', operation: 'in', values: country},
                    ],
                }).then(res => {
                    this.adset_retention = res.data.data.data;
                }),
            ]).then(() => {
                const campaignIds = this.campaign_insight.map(item => item.campaign_id)
                const adsetIds = this.adset_insight.map(item => item.adset_id)
                axios.all([
                    axios.post(`/api/v1/ua/googleads/adsource`, {
                        layer: 'campaign',
                        query_ids: campaignIds,
                        limit: 1000,
                        offset: 0
                    }).then(res => {
                        this.campaignContent = res.data.data.data
                    }),
                    axios.post(`/api/v1/ua/googleads/adsource`, {
                        layer: 'adset',
                        query_ids: adsetIds,
                        limit: 1000,
                        offset: 0
                    }).then(res => {
                        this.adsetContent = res.data.data.data
                    }),
                ]).then(_ => {
                    this.$refs.campaign.init(deepcopy(this.campaign_insight), this.campaignContent, [], deepcopy(this.headers), deepcopy(this.form.groupings))
                    this.$refs.adset.init(deepcopy(this.adset_insight), this.adsetContent, this.campaignContent, deepcopy(this.headers), deepcopy(this.form.groupings))
                    this.loading = false;
                });
                this.campaign_retention.forEach(r => {
                    this.campaign_insight.forEach(c => {
                        let valid = c.campaign_id === r.campaign_id;
                        this.form.groupings.forEach(g => {
                            valid &&= c[g] === r[g]
                        });
                        if (valid) {
                            this.headers.forEach(h => {
                                c[h] = parseInt(r[h] / r.installs * 10000) / 100;
                            })
                        }
                    })
                });
                this.adset_retention.forEach(r => {
                    this.adset_insight.forEach(c => {
                        let valid = c.adset_id === r.adset_id;
                        this.form.groupings.forEach(g => {
                            valid &&= c[g] === r[g]
                        });
                        if (valid) {
                            this.headers.forEach(h => {
                                c[h] = parseInt(r[h] / r.installs * 10000) / 100;
                            })
                        }
                    })
                });
            })
        },
        formatInt(value) {
            return formatInt(value);
        },
        SummaryMethod({columns, data}) {
            const summary = [];
            columns.forEach((c, i) => {
                if (['spend', 'impression', 'click', 'install'].indexOf(c.property) > -1) {
                    summary[i] = 0;
                    (this.checked.length ? this.checked : data).forEach(d => {
                        summary[i] += d[c.property];
                    });
                    summary[i] = parseFloat((summary[i]).toFixed(2));
                } else if (c.property === 'cpi') {
                    let spend = 0, install = 0;
                    (this.checked.length ? this.checked : data).forEach(d => {
                        spend += d.spend;
                        install += d.install;
                    });
                    summary[i] = parseFloat((spend / install).toFixed(2));
                } else if (c.property === 'ecpm') {
                    let spend = 0, impression = 0;
                    (this.checked.length ? this.checked : data).forEach(d => {
                        spend += d.spend;
                        impression += d.impression;
                    });
                    summary[i] = parseFloat((spend / impression * 1000).toFixed(2));
                } else if (c.property === 'name') {
                    summary[i] = '合计'
                } else if (['campaignIds', 'CreativePackIds'].indexOf(c.property) > -1) {
                    summary[i] = 0;
                    (this.checked.length ? this.checked : data).forEach(d => {
                        summary[i] += d[c.property] ? d[c.property].length : 0;
                    });
                } else {
                    summary[i] = null;
                }
            });
            return summary;
        },
        SelectionChange(rows) {
            this.checked = rows;
            console.log('SelectionChange', this.card)
            if (this.card === 'campaign') {
                this.selectedCampaign = rows
            } else if (this.card === 'adset') {
                this.selectedAdset = rows
            } else if (this.card === 'ad') {
                this.selectedAd = rows
            }
        },
        openInNewTab(url) {
            return openInNewTab(url);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            this.init();
        },
        card(newVal, oldVal) {
            if (newVal === 'campaign') {

            } else if (newVal === 'adset') {
                this.$refs.adset.refreshTable()
            } else if (newVal === 'ad') {
                this.$refs.ad.refreshTable()
            } else {

            }
        }
    },
}
</script>

<style scoped>

.el-select {
  width: 200px;
}

.el-tabs ::v-deep(.el-tabs__header) {
    margin: 0;
    border: 0;
}

.el-tabs ::v-deep(.el-tabs__nav) {
    border: 0;
    padding: 0 4px;
}

.el-tabs ::v-deep(.el-tabs__item) {
    background-color: #F2F2F2;
    text-align: left;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    margin: 4px 4px 0 4px;
    border: 1px solid var(--el-border-color-light) !important;
    border-bottom: 0 !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.el-tabs ::v-deep(.el-tabs__item.is-active) {
    background-color: #FFF;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    margin: 0 4px;
}

.preview-image {
    width: 90%;
    height: auto;
}
</style>
<template>
    <div style="padding: 0 30px">
        <div>
            <el-button type="primary" :loading="loading" @click="$refs.editor.openDialog()">新建指标</el-button>
        </div>
        <el-table style="margin-top: 10px" :data="data" v-loading="loading"
                  :default-sort="{prop: 'u_time', order: 'descending'}">
            <el-table-column prop="name" label="指标" width="200px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="description" label="描述" width="350px" show-overflow-tooltip></el-table-column>
            <el-table-column prop="events" label="事件" width="300px">
                <template #default="scope">
                    <span v-for="(e, ei) in scope.row.events" :key="ei">
                        <el-tag v-if="e.name && e.name.length > 0" :disable-transitions="false"
                                style="margin-left: 5px">
                            {{typeof e.name === 'object' ? e.name.join("\n") : e.name}}
                        </el-tag>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="user_id" label="创建人" width="120">
                <template #default="scope">
                    <user v-model="scope.row.user_id" avatar></user>
                </template>
            </el-table-column>
            <el-table-column prop="effective_date" label="生效时间" width="120px">
                <template #default="scope">
                    {{scope.row.effective_date ? timestampToDate(scope.row.effective_date) : ''}}
                </template>
            </el-table-column>
            <el-table-column prop="u_time" label="最后修改" width="120px">
                <template #default="scope">
                    {{timestampToDate(scope.row.u_time)}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150px">
                <template #default="scope">
                    <el-button icon="Edit" circle  type="success"
                               @click="$refs.editor.openDialog(scope.row)" :disabled="scope.row.preset"></el-button>
                    <el-button icon="Delete" circle  type="danger"
                               @click="remove(scope.row)" :disabled="scope.row.preset"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <metric-editor ref="editor" @add="addMetric"></metric-editor>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate} from "../../../libs/utils";
import MetricEditor from "./MetricEditor";
import User from "../../../base/User";

export default {
    name: "MetricIndex",
    components: {User, MetricEditor},
    data() {
        return {
            loading: false, data: [],
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/api/v1/metric`, {
                    params: {
                        product_id: this.$route.params.productId, contain_preset: 1
                    }
                }).then(res => {
                    this.data = res.data.data.models.filter(m => !m.deleted);
                }),
            ]).then(_ => {
                this.loading = false;
            });
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp);
        },
        remove(row) {
            this.$confirm(`确定要删除指标(${row.name})吗？`, '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/api/v1/metric/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        addMetric(row) {
            this.data.unshift(row);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            this.init();
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <el-main>
        <el-table v-if="$root.user.authority.includes('manager')" :data="data" v-loading="loading">
            <el-table-column label="头像" width="100" prop="avatar">
                <template #default="scope">
                    <el-image :src="scope.row.avatar" style="width: 50px" lazy></el-image>
                </template>
            </el-table-column>
            <el-table-column label="用户名" prop="name" width="100"></el-table-column>
            <el-table-column label="email" prop="email" width="250"></el-table-column>
            <el-table-column label="产品" width="250">
                <template #default="scope">
                    <el-select v-model="scope.row.product" multiple @change="update(scope.row)"
                               :disabled="scope.row.authority.includes('pm')">
                        <el-option v-for="p in $root.product_list" :value="p.id"
                                   :label="`${p.nickname}(${p.platform})`"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="权限" prop="authority">
                <template #default="scope">
                    <el-checkbox-group v-model="scope.row.authority" @change="update(scope.row)">
                        <el-checkbox label="manager">管理员</el-checkbox>
                        <el-checkbox label="pm">全部产品</el-checkbox>
                        <el-checkbox label="ua">投放花费</el-checkbox>
                        <el-checkbox label="iaa">内部收入</el-checkbox>
                        <el-checkbox label="black">外部收入</el-checkbox>
                    </el-checkbox-group>
                </template>
            </el-table-column>
        </el-table>
    </el-main>
</template>
<script>
import axios from "ts-axios-new";

export default {
    name: 'Authority',
    data() {
        return {
            loading: false, data: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/api/v1/user').then(res => {
                this.data = res.data.data.userList;
                this.loading = false;
            });
        },
        update(row) {
            this.loading = true;
            axios.put(`/api/v1/user/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped>
</style>
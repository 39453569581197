export const FixedMetricCategoryList = [
    {name: "日活", value: "dau"},
    {name: "新增", value: "first_open"},
    {name: "留存", value: "retain"},
    {name: '活跃留存', value: 'dau_retain'},
    {name: "平均时长", value: "duration"},
]

const CombinationMetricCategoryList = [
    {name: "事件计数", value: "event_count"},
    {name: "用户计数", value: "user_count"},
    {name: "事件比率", value: "event_rate"},
    {name: "用户比率", value: "user_rate"},
    {name: '事件value合计', value: 'sum_value'},
    {name: '用户人均事件数', value: 'event_per_user'},
    {name: '日活人均事件数', value: 'event_per_head'},
    {name: '事件value均值', value: 'sum_value_per_event'},
    {name: '用户value均值', value: 'sum_value_per_user'},
    {name: '日活value均值', value: 'sum_value_per_head'},
    {name: '用户日活比率', value: 'user_head_rate'},
    {name: '自定义指标', value: 'combination'},
]


export const MetricCategoryList = FixedMetricCategoryList.concat(CombinationMetricCategoryList);

export const fixedCategoryList = FixedMetricCategoryList.map(c => c.value);
export const combinationCategoryList = CombinationMetricCategoryList.map(c => c.value);

export const percentCategoryList = ['af_retain', 'retain', 'dau_retain', 'user_rate', 'event_rate'];

export const MetricCalCategoryList = [
    {name: '该事件发生数', value: 'event_count'},
    {name: '发生该事件的用户数', value: 'user_count'},
    {name: '该事件的value(string)求和', value: 'string_value_sum'},
    {name: '该事件的value(int)求和', value: 'int_value_sum'},
    {name: '该事件的value(double)求和', value: 'double_value_sum'},
];

export const MediaSourceList = [
    'Apple Search Ads',
    'Facebook Ads',
    'Organic',
    'Twitter',
    'adcolony_int',
    'applovin_int',
    'baiduxinxiliu_int',
    'blindferret_int',
    'bytedance_int',
    'chartboosts2s_int',
    'googleadwords_int',
    'ironsource_int',
    'kuaishou_int',
    'mintegral_int',
    'mistplay_int',
    'restricted',
    'tencent_int',
    'tiktok',
    'unityads_int',
    'vungle_int',
    'digitalturbine_int',
]

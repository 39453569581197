<template>
    <downloadable-table style="margin-top: 20px" :data="data" v-loading="loading" title="投放总览">
        <template #header>
            <div style="display: inline-flex">
                <date-range-picker v-model="form" :disabled="loading"></date-range-picker>
                <el-button type="primary" :loading="loading" @click="init"
                           style="margin-left: 10px">查询
                </el-button>
            </div>
        </template>
        <el-table-column width="120" prop="date" label="日期" fixed="left" sortable></el-table-column>
        <el-table-column width="100" prop="country" label="国家" fixed="left" sortable></el-table-column>
        <el-table-column width="100" prop="bq_installs" label="首次打开"></el-table-column>
        <el-table-column width="100" prop="ua_installs" label="非自然量"></el-table-column>
        <el-table-column width="100" prop="bq_dau" label="日活"></el-table-column>
        <el-table-column width="120" prop="bq_duration_seconds" label="人均时长"></el-table-column>
        <el-table-column width="100" prop="bq_sessions" label="人均会话">
            <template #default="scope">
                {{ scope.row.bq_dau ? (scope.row.bq_sessions / scope.row.bq_dau).toFixed(2) : null }}
            </template>
        </el-table-column>
        <el-table-column width="100" prop="bq_retention_1" label="次留">
            <template #default="scope">
                {{ scope.row.bq_retention_1 ? scope.row.bq_retention_1 + '%' : '' }}
            </template>
        </el-table-column>
        <el-table-column width="100" prop="bq_retention_7" label="7留">
            <template #default="scope">
                {{ scope.row.bq_retention_7 ? scope.row.bq_retention_7 + '%' : '' }}
            </template>
        </el-table-column>
        <el-table-column width="100" prop="bq_retention_30" label="30留">
            <template #default="scope">
                {{ scope.row.bq_retention_30 ? scope.row.bq_retention_30 + '%' : '' }}
            </template>
        </el-table-column>
        <el-table-column width="100" label="总收入" prop="revenue">
            <template #default="scope">
                {{
                    scope.row.iaa_revenue + scope.row.iap_proceed ? (scope.row.iaa_revenue + scope.row.iap_proceed).toFixed(2) : null
                }}
            </template>
        </el-table-column>
        <el-table-column width="100" label="总花费" prop="spend">
            <template #default="scope">
                {{ scope.row.ua_spend }}
            </template>
        </el-table-column>
        <el-table-column width="100" label="利润" prop="profit">
            <template #default="scope">
                {{
                    scope.row.iaa_revenue + scope.row.iap_proceed - scope.row.ua_spend ? (scope.row.iaa_revenue + scope.row.iap_proceed - scope.row.ua_spend).toFixed(2) : null
                }}
            </template>
        </el-table-column>
        <el-table-column width="100" label="平均单价" prop="cpa">
            <template #default="scope">
                {{ (scope.row.ua_spend / scope.row.ua_installs).toFixed(2) }}
            </template>
        </el-table-column>
        <el-table-column width="100" label="单位成本" prop="cpi">
            <template #default="scope">
                {{ (scope.row.ua_spend / scope.row.bq_installs).toFixed(2) }}
            </template>
        </el-table-column>
        <el-table-column width="100" label="ARPDAU" prop="arpdau">
            <template #default="scope">
                {{
                    (scope.row.iaa_revenue + scope.row.iap_proceed) / scope.row.bq_dau ? ((scope.row.iaa_revenue + scope.row.iap_proceed) / scope.row.bq_dau).toFixed(4) : null
                }}
            </template>
        </el-table-column>
        <el-table-column label="投放花费数据" v-if="ad_channel.length">
            <el-table-column width="100" label="总花费" prop="ua_spend">
                <template #default="scope">
                    {{ scope.row.ua_spend }}
                </template>
            </el-table-column>
            <template v-for="c in ad_channel" :key="c">
                <el-table-column width="140" :label="c + '花费'" :prop="c + '_spend'"></el-table-column>
                <el-table-column width="140" :label="c + '安装'" :prop="c + '_install'"></el-table-column>
                <el-table-column width="140" :label="c + '单价'" :prop="c + '_cpi'">
                    <template #default="scope">
                        {{
                            scope.row[c + '_install'] ? (scope.row[c + '_spend'] / scope.row[c + '_install']).toFixed(2) : null
                        }}
                    </template>
                </el-table-column>
            </template>
        </el-table-column>
    </downloadable-table>
</template>

<script>
import {formatDuration, timestampToDate} from "../../libs/utils";
import axios from 'ts-axios-new';
import DateRangePicker from "../../base/DateRangePicker";
import DownloadableTable from "../../base/DownloadableTable";

export default {
    name: "UADashboard",
    components: {DownloadableTable, DateRangePicker},
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], ad_channel: [],
            form: {
                start: timestampToDate(now - 7 * 24 * 3600 * 1000),
                end: timestampToDate(now - 24 * 3600 * 1000),
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            return axios.get(`/api/v1/pm/${this.$route.params.productId}/ua/report`, {
                params: this.form
            }).then(res => {
                res.data.data.metricList.forEach(d => {
                    d.bq_duration_seconds = d.bq_duration_seconds ? formatDuration(d.bq_duration_seconds * 1000) : null;
                    d.session = d.bq_dau ? (d.session / d.bq_dau).toFixed(2) : null;
                });
                this.data = res.data.data.metricList;
                this.ad_channel = res.data.data.ad_channel;
                this.loading = false;
            });
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
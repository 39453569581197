<template>
    <el-dialog v-model="dialog_opened" width="80%" :close-on-click-modal="false" :before-close="resetForm"
              >
        <div style="margin-bottom: 20px" v-if="form.status === 'pending'">
            <el-steps :active="inner_index" align-center>
                <el-step title="实验详情"></el-step>
                <el-step title="实验配置"></el-step>
                <el-step title="指标设置"></el-step>
                <el-step title="实验预览"></el-step>
            </el-steps>
        </div>
        <exp-editor-step0 :form="form" ref="editor" :loading="loading" v-if="inner_index===0"
                          :exp_id="exp_id"></exp-editor-step0>
        <exp-editor-step1 :form="form" ref="editor" :loading="loading" v-else-if="inner_index===1"></exp-editor-step1>
        <ExpMetricEditor :form="form" ref="editor" :loading="loading" v-else-if="inner_index===2"></ExpMetricEditor>
        <exp-editor-step3 :form="form" ref="editor" :disabled="loading" v-else></exp-editor-step3>
        <template #footer>
            <div v-if="inner_index < 3">
                <el-button :disabled="inner_index===0"  @click="previousStep">
                    上一步
                </el-button>
                <el-button  type="primary" @click="nextStep">下一步</el-button>
            </div>
            <div v-else>
                <el-button  v-if="form.status === 'pending'" @click="previousStep">上一步</el-button>
                <el-button  type="success" :loading="loading" @click="submitForm(true)"
                           v-if="form.status === 'pending'">直接发布
                </el-button>
                <el-button  type="primary" :loading="loading" @click="submitForm()"
                           v-if="form.status === 'pending'">保存待发
                </el-button>
                <el-button  type="primary" text :loading="loading" v-if="form.status === 'published'"
                           @click="resetForm">取消
                </el-button>
                <el-button  type="success" :loading="loading" v-if="form.status === 'published'"
                           @click="submitForm4">保存并生效
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import ExpEditorStep0 from "./ExpEditorStep0";
import ExpEditorStep1 from "./ExpEditorStep1";
// import ExpEditorStep2 from "./ExpEditorStep2";
import ExpEditorStep3 from "./ExpEditorStep3";
import ExpMetricEditor from "./ExpMetricEditor";
import axios from 'ts-axios-new';
import {update, deepcopy} from "../../../libs/utils";

export default {
    name: "ExpEditor",
    components: {ExpEditorStep0, ExpEditorStep1, ExpEditorStep3, ExpMetricEditor},
    props: {
        index: Number,
    },
    data() {
        return {
            loading: false, dialog_opened: false, inner_index: this.index || 0, exp_id: null,
            next_disabled: false,
            form: {
                id: '',
                product_id: this.$route.params.productId,
                user_id: this.$root.user.user_id,
                layer_id: '',
                name: '',
                desc: '',
                percent: 10,
                country: [],
                is_empty: false,
                version: '',
                status: 'pending',
                groups: [{
                    name: '对照组',
                    desc: '对照组',
                    data: {},
                }, {
                    name: '实验组1',
                    desc: '实验组1',
                    data: {},
                }],
                stat: {
                    metrics: [],
                },
                user_layers: {},
                device_category: [],
                af_status: [],
                media_source: [],
                campaign_id: [],
            },
        }
    },
    methods: {
        openDialog(exp_id) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.$refs.editor.init();
            });
            if (exp_id) {
                this.loading = true;
                this.exp_id = exp_id;
                axios.get(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + exp_id).then(res => {
                    update(this.form, res.data.data);
                    this.form.user_layers = deepcopy(res.data.data.user_layers);
                    if (this.form.status !== 'pending') {
                        this.inner_index = 3;
                    }
                    this.form.user_id = this.$root.user.user_id;
                    this.form.stat.metrics = this.form.stat.metrics.filter(m => typeof m === 'string');
                    this.loading = false;
                })
            }
        },
        resetForm() {
            this.form = {
                id: '',
                product_id: this.$route.params.productId,
                user_id: this.$root.user.user_id,
                layer_id: '',
                name: '',
                desc: '',
                status: 'pending',
                percent: 10,
                is_empty: false,
                country: [],
                version: '',
                groups: [{
                    name: '对照组',
                    desc: '对照组',
                    data: {},
                }, {
                    name: '实验组1',
                    desc: '实验组1',
                    data: {},
                }],
                stat: {
                    active: {
                        name: '',
                        keys: [],
                        values: [],
                    },
                    metrics: [],
                },
                user_layers: {},
                device_category: [],
                af_status: [],
                media_source: [],
                campaign_id: [],
            };
            this.$refs.editor.resetForm();
            this.exp_id = null;
            this.loading = this.dialog_opened = false;
            this.inner_index = 0;
            this.$emit('change');
        },
        nextStep() {
            this.$refs.editor.submitForm(_ => {
                this.inner_index++;
            });
        },
        previousStep() {
            this.inner_index--;
        },
        submitForm4() {
            // 判断每个组的参数是否一致
            let consistent = true;
            this.form.groups.forEach(g => {
                for (let k in g.data) {
                    this.form.groups.forEach(gg => {
                        if (gg.data[k] === undefined) {
                            consistent = false;
                        }
                    })
                }
            });
            if (!consistent) {
                return this.$message.error('实验组参数不一致！')
            }
            // 判断结束
            this.loading = true;
            axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + this.exp_id + '/status', this.form).then(res => {
                this.resetForm();
            }).catch(err => {
                this.loading = false;
                this.$alert('<p>参数 ' + err.data.data.join(',') + ' 无法创建，需满足以下条件：</p><p>1、同域下不可以有正在做实验的参数；</p><p>2、若参数已存在则数据类型需要保持一致</p>', '创建失败', {
                    type: 'error',
                    dangerouslyUseHTMLString: true,
                })
            })
        },
        submitForm(publish) {
            this.loading = true;
            for (let k in this.form.groups[0].data) {
                this.form.groups.forEach(g => {
                    if (g.data[k] === undefined) {
                        g.data[k] = this.form.groups[0].data[k];
                    }
                })
            }
            this.form.product_id = this.$route.params.productId;
            if (this.exp_id) {
                axios.put(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + this.exp_id, this.form).then(res => {
                    if (publish) {
                        this.publishExp(res.data.data.id);
                    } else {
                        this.resetForm();
                    }
                })
            } else {
                axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp', this.form).then(res => {
                    if (publish) {
                        this.publishExp(res.data.data.id);
                    } else {
                        this.resetForm();
                    }
                })
            }
        },
        publishExp(exp_id) {
            this.form.status = 'published';
            axios.post(this.$root.$getElasticDomain() + '/cms/v1/abtest/exp/' + exp_id + '/status', this.form).then(res => {
                this.resetForm();
            }).catch(err => {
                this.form.status = 'pending';
                this.loading = false;
                this.$alert('<p>参数 ' + err.data.data.join(',') + ' 无法创建，需满足以下条件：</p><p>1、同域下不可以有正在做实验的参数；</p><p>2、若参数已存在则数据类型需要保持一致</p>', '创建失败', {
                    type: 'error',
                    dangerouslyUseHTMLString: true,
                });
            })
        },
    },
}
</script>

<style scoped>

</style>

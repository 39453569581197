<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建计划</el-button>
        <el-button type="success" :disabled="loading" @click="dialog_opened1=true">测试消息</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column width="100" prop="active" label="活跃">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column width="150" prop="i18n_title.EN" label="标题"></el-table-column>
        <el-table-column width="250" prop="i18n_body.EN" label="内容"></el-table-column>
        <el-table-column width="100" prop="image" label="图片">
            <template #default="scope">
                <el-image :src="scope.row.image" lazy style="width: 50px"></el-image>
            </template>
        </el-table-column>
        <el-table-column width="80" prop="hour" label="触发时刻"></el-table-column>
        <el-table-column width="160" label="触发日期">
            <template #default="scope">
                {{ scope.row.date || `每周${scope.row.week}` }}
            </template>
        </el-table-column>
        <el-table-column width="80" label="触达" prop="show"></el-table-column>
        <el-table-column width="80" label="点击" prop="click"></el-table-column>
        <el-table-column width="200" label="操作">
            <template #default="scope">
                <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="标题" prop="i18n_title.EN" :rules="[{required: true, message: '请输入默认标题'}]">
                <el-input v-model="form.i18n_title.EN" :disabled="loading"></el-input>
            </el-form-item>
            <el-collapse>
                <el-collapse-item title="多语言标题">
                    <template v-for="(_, k) in form.i18n_title">
                        <el-form-item :label="`${k}标题`" :prop="`i18n_title.${k}`">
                            <el-input v-model="form.i18n_title[k]" :disabled="loading"></el-input>
                        </el-form-item>
                    </template>
                </el-collapse-item>
            </el-collapse>
            <el-form-item label="内容" prop="i18n_body.EN" :rules="[{required: true, message: '请输入默认内容'}]">
                <el-input type="textarea" v-model="form.i18n_body.EN" :disabled="loading"></el-input>
            </el-form-item>
            <el-collapse>
                <el-collapse-item title="多语言内容">
                    <template v-for="(_, k) in form.i18n_body">
                        <el-form-item :label="`${k}内容`" :prop="`i18n_title.${k}`">
                            <el-input v-model="form.i18n_body[k]" :disabled="loading"></el-input>
                        </el-form-item>
                    </template>
                </el-collapse-item>
            </el-collapse>
            <el-form-item label="图片链接" prop="image">
                <el-input v-model="form.image" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="触发时刻" prop="hour" :rules="[{required: true, message: '请选择时刻'}]">
                <el-select v-model="form.hour" filterable>
                    <el-option v-for="(e, i) in Array(24)" :label="`${i}点整`" :value="i"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="周期">
                <el-select v-model="period">
                    <el-option :value="0" label="一次"></el-option>
                    <el-option :value="1" label="每周"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="触发日期" prop="date" :rules="[{required: true, message: '请选择日期'}]" v-if="period === 0">
                <el-date-picker v-model="form.date" value-format="YYYY-MM-DD" :disabled="loading"></el-date-picker>
            </el-form-item>
            <el-form-item label="触发日期" prop="week" :rules="[{required: true, message: '请选择日期'}]"
                          v-else-if="period === 1">
                <el-select v-model="form.week" :disabled="loading">
                    <el-option v-for="(e, i) in Array(7)" :label="`星期${i + 1}`" :value="i + 1"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened1" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="标题" prop="i18n_title.EN" :rules="[{required: true, message: '请输入标题'}]">
                <el-input v-model="form.i18n_title.EN" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="i18n_body.EN" :rules="[{required: true, message: '请输入内容'}]">
                <el-input v-model="form.i18n_body.EN" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="图片链接" prop="image">
                <el-input v-model="form.image" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="Topic" prop="topic">
                <el-select v-model="form.topic" :disabled="loading">
                    <el-option v-for="t in topic_list" :value="t.topic"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Token" prop="token">
                <el-input v-model="form.token" :disabled="loading"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit1">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";
import {ElMessage} from 'element-plus'

export default {
    name: "Push",
    data() {
        return {
            loading: false, data: [], domain: this.$route.query.domain || 'elastic', topic_list: [],
            dialog_opened: false, editing: null, period: 0, dialog_opened1: false,
            form: {
                product_id: '',
                i18n_title: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                i18n_body: {
                    EN: '',
                    FR: '',
                    HI: '',
                    IT: '',
                    AR: '',
                    ES: '',
                    PT: '',
                    DE: '',
                    EL: '',
                    ZH_HANS: '',
                    ZH_HANT: '',
                    ID: '',
                    KO: '',
                    TH: '',
                    NL: '',
                    TR: '',
                    PL: '',
                    VI: '',
                    FI: '',
                    RU: '',
                    HU: '',
                    JA: '',
                    NO: '',
                    SV: '',
                    UK: '',
                    MS: '',
                    IW: '',
                    RO: '',
                    TL: '',
                },
                image: '',
                data: null,
                date: null,
                hour: 0,
                week: null,
                active: false,
                topic: '',
                token: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`${this.$root.$getElasticDomain()}/cms/v1/fcm/schedule`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.data = res.data.data.scheduleList;
                }),
                axios.get(`${this.$root.$getElasticDomain()}/cms/v1/fcm/topic`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.topic_list = res.data.data.topicList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        changeEnv() {
            this.$router.push({query: {domain: this.domain}});
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                    if (this.form.date) {
                        this.period = 0
                    } else {
                        this.period = 1;
                    }
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.i18n_title = {
                EN: '',
                FR: '',
                HI: '',
                IT: '',
                AR: '',
                ES: '',
                PT: '',
                DE: '',
                EL: '',
                ZH_HANS: '',
                ZH_HANT: '',
                ID: '',
                KO: '',
                TH: '',
                NL: '',
                TR: '',
                PL: '',
                VI: '',
                FI: '',
                RU: '',
                HU: '',
                JA: '',
                NO: '',
                SV: '',
                UK: '',
                MS: '',
                IW: '',
                RO: '',
                TL: '',
            };
            this.form.i18n_body = {
                EN: '',
                FR: '',
                HI: '',
                IT: '',
                AR: '',
                ES: '',
                PT: '',
                DE: '',
                EL: '',
                ZH_HANS: '',
                ZH_HANT: '',
                ID: '',
                KO: '',
                TH: '',
                NL: '',
                TR: '',
                PL: '',
                VI: '',
                FI: '',
                RU: '',
                HU: '',
                JA: '',
                NO: '',
                SV: '',
                UK: '',
                MS: '',
                IW: '',
                RO: '',
                TL: '',
            };
            this.loading = this.dialog_opened = this.dialog_opened1 = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.form.product_id = this.$route.params.productId;
                    if (this.editing) {
                        axios.put(`${this.$root.$getElasticDomain()}/cms/v1/fcm/schedule/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`${this.$root.$getElasticDomain()}/cms/v1/fcm/schedule`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        submit1() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.form.product_id = this.$route.params.productId;
                    axios.post(`${this.$root.$getElasticDomain()}/cms/v1/fcm/send`, this.form).then(res => {
                        ElMessage.success('发送成功！')
                        this.reset();
                    });
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`${this.$root.$getElasticDomain()}/cms/v1/fcm/schedule/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
    },
    mounted() {
        this.changeEnv();
        this.$nextTick(_ => {
            this.init();
        })
    },
    watch: {
        "$route.params.productId"() {
            this.init();
        },
        "$route.query.domain"() {
            if (this.$route.query.domain)
                this.init();
        },
        period(val) {
            if (val === 0) {
                this.form.week = null;
            } else {
                this.form.date = null;
            }
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <el-dialog v-model="dialog_opened" width="50%" :before-close="resetFields">
        <p style="text-align: center">
            复制实验到
            <el-button  style="margin-left: 10px" @click="copyExp('elastic')" :loading="loading">正式环境
            </el-button>
            <el-button  @click="copyExp('elastic-test')" :loading="loading">测试环境
            </el-button>
        </p>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';

export default {
    name: "CopyExp",
    data() {
        return {
            dialog_opened: false, editing: null, loading: false,
        }
    },
    methods: {
        openDialog(exp) {
            this.editing = exp;
            this.dialog_opened = true;
        },
        copyExp(domain) {
            this.loading = true;
            axios.post((import.meta.env.VITE_ELASTIC_PROXY ? import.meta.env.VITE_ELASTIC_PROXY : `https://${domain}.plutodesk.com`) + '/cms/v1/abtest/exp/' + this.editing.id + '/copy', {
                domain: this.$route.query.domain,
                user_id: this.$root.user.user_id
            }).then(res => {
                if (this.$route.query.domain !== domain) {
                    this.$router.push({query: {domain}})
                }
                this.loading = this.dialog_opened = false;
                this.$emit('change');
            })
        },
        resetFields() {
            this.dialog_opened = false;
            this.editing = null;
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <el-table height="100%" :data="tableData" ref="table"
                v-loading="loading" show-summary :summary-method="SummaryMethod" border
                @selection-change="SelectionChange" :default-sort="{prop: 'spend', order: 'descending'}"
                @sort-change="sortChangeHandle">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="Name" width="300" prop="name" sortable
                            show-overflow-tooltip></el-table-column>
        <el-table-column label="Date" width="100" prop="date" sortable v-if="groupings.includes('date')"></el-table-column>
        <el-table-column label="Country" width="80" prop="country" sortable v-if="groupings.includes('country')"></el-table-column>
        <el-table-column label="Status" width="90" prop="status"></el-table-column>
        <el-table-column label="Adsets" width="110" prop="containAdsets">
            <template #default="scope">
                <span v-if="scope.row.containAdsets.length > 0">{{scope.row.containAdsets.length}} 个Adset </span>
            </template>
        </el-table-column>
        <el-table-column label="CPI" width="100" prop="cpi" align="right" header-align="left"
                            sortable></el-table-column>
        <el-table-column label="Install" width="120" prop="install" align="right" header-align="left"
                            sortable>
            <template #default="scope">
                {{formatInt(scope.row.install)}}
            </template>
        </el-table-column>
        <el-table-column label="Spend" width="120" prop="spend" align="right" header-align="left" sortable>
            <template #default="scope">
                {{formatInt(scope.row.spend)}}
            </template>
        </el-table-column>
        <el-table-column label="Impression" width="120" prop="impression" align="right" header-align="left"
                            sortable>
            <template #default="scope">
                {{formatInt(scope.row.impression)}}
            </template>
        </el-table-column>
        <el-table-column label="eCPM" width="100" prop="ecpm" align="right" header-align="left"
                            sortable></el-table-column>
        <el-table-column label="Click" width="120" prop="click" align="right" header-align="left" sortable>
            <template #default="scope">
                {{formatInt(scope.row.click)}}
            </template>
        </el-table-column>
        <el-table-column v-for="h in retentionHeaders" :label="h" :prop="h" width="100">
            <template #default="scope">
                {{scope.row[h]}}%
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import {formatInt} from "../../libs/utils";
export default {
    name: "CampaignTable",
    data() {
        return {
            loading: false,
            selectedRows: [],
            tableData: [],
            allData: [],
            retentionHeaders: [],
            groupings: [],
        }
    },
    watch: {

    },
    methods: {
        sortChangeHandle(param){
            this.allData.sort((a, b)=>{
                if (param.order === 'ascending') {
                    return a[param.prop] < b[param.prop] ? -1 : 1
                } else if (param.order === 'descending') {
                    return a[param.prop] > b[param.prop] ? -1 : 1
                } else {
                    return a.spend > b.spend ? -1 : 1
                }
            })
            this.refreshTable()
        },
        refreshTable(){
            this.tableData = this.allData.slice(0, 100)
        },
        init(data, campaignList, adsetList, headers, groupings){
            this.$refs.table.clearSort()
            data.forEach(item => {
                item.cpi = item.install ? parseFloat((item.spend / item.install).toFixed(2)) : null;
                item.ecpm = parseFloat((item.spend / item.impression * 1000).toFixed(2));
                const content = campaignList.find(element => element.campaign_id === item.campaign_id)
                if (content) {
                    item.name = content.name
                    item.status = content.status
                }
                item.containAdsets = adsetList.filter(adset => {
                    return adset.campaign_id === item.campaign_id
                })
            });
            this.retentionHeaders = headers;
            this.allData = data;
            this.groupings = groupings;
            this.sortChangeHandle({prop: 'spend', order: 'descending'})
        },
        formatInt(value) {
            return formatInt(value);
        },
        SummaryMethod({columns, data}) {
            const summary = [];
            columns.forEach((c, i) => {
                if (['spend', 'impression', 'click', 'install'].indexOf(c.property) > -1) {
                    summary[i] = 0;
                    (this.selectedRows.length ? this.selectedRows : this.allData).forEach(d => {
                        summary[i] += d[c.property];
                    });
                    summary[i] = parseFloat((summary[i]).toFixed(2));
                } else if (c.property === 'cpi') {
                    let spend = 0, install = 0;
                    (this.selectedRows.length ? this.selectedRows : this.allData).forEach(d => {
                        spend += d.spend;
                        install += d.install;
                    });
                    summary[i] = parseFloat((spend / install).toFixed(2));
                } else if (c.property === 'ecpm') {
                    let spend = 0, impression = 0;
                    (this.selectedRows.length ? this.selectedRows : this.allData).forEach(d => {
                        spend += d.spend;
                        impression += d.impression;
                    });
                    summary[i] = parseFloat((spend / impression * 1000).toFixed(2));
                } else if (c.property === 'name') {
                    summary[i] = '合计'
                } else if (['campaignIds', 'CreativePackIds'].indexOf(c.property) > -1) {
                    summary[i] = 0;
                    (this.selectedRows.length ? this.selectedRows : this.allData).forEach(d => {
                        summary[i] += d[c.property] ? d[c.property].length : 0;
                    });
                } else {
                    summary[i] = null;
                }
            });
            return summary;
        },
        SelectionChange(rows) {
            this.selectedRows = rows;
            this.$emit('select', rows.map(item => item.campaign_id))
        }
    },
    mounted() {
        // this.init();
    }
}
</script>

<template>
    <el-form-item :prop="prop" :size="size" :rules="rules" :label="label" :label-width="labelWidth">
        <slot name="prefix"></slot>
        <el-input v-model="number" :size="size" :disabled="disabled" @change="change" placeholder="请输入数字"
                  :style="{width}" :placeholder="placeholder"></el-input>
        <slot name="append"></slot>
    </el-form-item>
</template>

<script>
export default {
    name: "NumberInput",
    props: {
        modelValue: Number,
        size: String,
        disabled: Boolean,
        prop: String,
        required: Boolean,
        label: String,
        labelWidth: String,
        validator: Object,
        intOnly: Boolean,
        width: String,
        placeholder: String,
    },
    data() {
        const rules = [];
        if (this.required) {
            rules.push({required: true, message: '请输入数字'})
        }
        rules.push({validator: this.innerValidator});
        if (this.validator) {
            rules.push({validator: this.validator});
        }
        return {
            number: 0, rules: rules, inner_update: false,
        }
    },
    methods: {
        init() {
            this.number = this.modelValue;
        },
        innerValidator(rule, value, callback) {
            if (this.number) {
                const float = parseFloat(this.number);
                if (isNaN(float)) {
                    callback('必须是数字')
                } else if (this.intOnly && float !== parseInt(this.number)) {
                    callback('必须是整数')
                } else {
                    callback();
                }
            } else {
                callback()
            }
        },
        change() {
            this.inner_update = true;
            const float = parseFloat(this.number);
            if (!isNaN(float) && (!this.intOnly || float === parseInt(this.number))) {
                this.$emit('update:modelValue', float);
            }
            this.$nextTick(_ => {
                this.inner_update = false;
            });
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        modelValue() {
            if (!this.inner_update)
                this.init();
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div style="padding: 0 30px">
        <cohort-form :loading="loading" ref="cohort" @submit="submit"></cohort-form>
        <div style="margin-top: 20px">
            <el-dropdown trigger="click" @command="load">
                <el-button type="primary" :loading="loading" :disabled="funnel_list.length < 1">载入漏斗
                </el-button>
                <template #dropdown>
                    <template v-for="f in funnel_list">
                        <el-dropdown-item :command="f">
                            {{ f.name }}
                            <span style="font-size: 10px;" class="color-info">
                            (创建人：<user v-model="f.user_id" class="color-info"></user>)
                        </span>
                        </el-dropdown-item>
                    </template>
                </template>
            </el-dropdown>
            <el-button type="success" @click="save" :loading="loading" style="margin-left: 10px">保存漏斗
            </el-button>
        </div>
        <el-form :model="form" ref="form" label-width="80px" style="display: flex;flex-wrap: wrap">
            <div v-for="(e, i) in form.events" style="margin-top: 20px; flex: 0 0 auto">
                <el-card shadow="never" style="float: left;">
                    <template #header>
                        <span>第{{ i + 1 }}层漏斗</span>
                        <el-icon style="float: right;cursor: pointer;" v-if="form.events.length > 2"
                                 @click="removeEvent(i)">
                            <CloseBold/>
                        </el-icon>
                        <el-icon style="float: right;cursor: pointer;margin-right: 10px"
                                 @click="copyEvent(i)">
                            <CopyDocument/>
                        </el-icon>
                    </template>
                    <event-selector :data="e" label="事件名" :disabled="loading"
                                    :prop="'events.' + i"></event-selector>
                </el-card>
                <el-icon style="margin: 83px 10px;float: left" v-if="i<form.events.length - 1" size="22px">
                    <ArrowRightBold/>
                </el-icon>
            </div>
            <el-icon @click="addEvent" size="22px" style="margin: 83px 20px;float: left;cursor: pointer;">
                <Plus/>
            </el-icon>
        </el-form>
        <div style="display: flex;justify-content: flex-end">
            <sql-runtime ref="timer" :stat="timer_stat" style="float: right;margin-right: 20px"></sql-runtime>
        </div>
        <el-card shadow="never" v-if="data.length">
            <div id="chart" style="width: 100%;height: 400px;" v-loading="loading"></div>
            <downloadable-table :data="data" :loading="loading" title="源数据">
                <el-table-column v-for="(h, i) in headers" :key="i" :label="'第' + (i + 1) + '层'" :prop="h"
                                 width="200">
                    <template #default="scope">
                        {{ scope.row[h] }} ({{ (scope.row[h] / scope.row.layer0 * 100).toFixed(2) }}%)
                    </template>
                </el-table-column>
            </downloadable-table>
        </el-card>
    </div>
    <el-dialog v-model="dialogOpened" :before-close="resetSave">
        <el-form ref="save" label-width="100px" :model="save_form">
            <el-form-item label="存储类型">
                <el-radio v-model="save_form.is_new" :label="false">覆盖现有漏斗</el-radio>
                <el-radio v-model="save_form.is_new" :label="true">另存为新漏斗</el-radio>
            </el-form-item>
            <el-form-item v-if="save_form.is_new" prop="name" :rules="[{required: true, message: '请输入名称'}]"
                          label="保存名称">
                <el-input v-model="save_form.name" style="width: 300px" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item v-else prop="id" :rules="[{required: true, message: '请选择一个漏斗'}]" label="选择漏斗">
                <el-select v-model="save_form.id" style="width: 300px" :disabled="loading">
                    <el-option v-for="f in funnel_list" :value="f.id" :label="f.name"
                               :disabled="$root.user.user_id !== f.user_id">
                        {{ f.name }}
                        <span style="font-size: 10px;" class="color-info">
                            (创建人：<user v-model="f.user_id" class="color-info"></user>)
                        </span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="使用范围">
                <el-radio-group v-model="save_form.is_private" :disabled="loading">
                    <el-radio :label="true">私有</el-radio>
                    <el-radio :label="false">公有</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="resetSave">取消</el-button>
            <el-button type="primary" :loading="loading" @click="saveFunnel">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import axios from 'ts-axios-new';
import EventSelector from "../../base/EventSelector";
import {update, deepcopy} from "../../libs/utils";
import SqlRuntime from "../../base/SqlRuntime";
import DownloadableTable from "../../base/DownloadableTable";
import User from "../../base/User";

export default {
    name: "Funnel",
    components: {User, DownloadableTable, SqlRuntime, EventSelector, CohortForm},
    data() {
        return {
            loading: false, headers: [], data: [], funnel_list: [], dialogOpened: false,
            form: {
                events: [
                    {name: '', items: []},
                    {name: '', items: []},
                ]
            },
            save_form: {
                is_new: true,
                is_private: false,
                name: '',
                id: '',
            },
            timer_stat: {total_bytes_processed: 0, duration: 0, message: null, sql: ''},
        }
    },
    methods: {
        save() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.dialogOpened = true;
                }
            })
        },
        load(funnel) {
            this.form.events = deepcopy(funnel.events);
        },
        saveFunnel() {
            this.$refs.save.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.save_form.is_new) {
                        axios.post('/api/v1/funnel', {
                            product_id: this.$route.params.productId,
                            name: this.save_form.name,
                            events: this.form.events,
                            is_private: this.save_form.is_private,
                        }).then(res => {
                            this.funnel_list.unshift(res.data.data);
                            this.resetSave();
                        })
                    } else {
                        axios.put('/api/v1/funnel/' + this.save_form.id, {
                            name: this.save_form.name,
                            events: this.form.events,
                            is_private: this.save_form.is_private,
                        }).then(res => {
                            this.funnel_list.forEach(f => {
                                if (f.id === this.save_form.id) {
                                    update(f, res.data.data);
                                }
                            })
                            this.resetSave();
                        })
                    }
                }
            })
        },
        resetSave() {
            this.dialogOpened = this.loading = false;
            this.$refs.save.resetFields();
        },
        addEvent() {
            this.form.events.push({name: '', items: []});
        },
        removeEvent(i) {
            this.form.events.splice(i, 1);
        },
        copyEvent(i) {
            this.form.events.push(deepcopy(this.form.events[i]));
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get('/api/v1/funnel', {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.funnel_list = res.data.data.funnelList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$refs.timer.start();
                    const form = this.$refs.cohort.getData();
                    form.events = this.form.events;
                    axios.post(`/api/v1/funnel/${this.$route.params.productId}/report`, form).then(res => {
                        this.headers = res.data.data.headers;
                        this.data = res.data.data.data;
                        this.loading = false;
                        this.$nextTick(_ => {
                            this.draw();
                        });
                        this.$refs.timer.success();
                        update(this.timer_stat, res.data.data);
                    }).catch(err => {
                        this.$refs.timer.error(err.response.data.status.message);
                    });
                }
            });
        },
        draw() {
            const myChart = echarts.init(document.getElementById('chart'), 'light');
            let data = [];
            let lData = [];
            let xAxis = {
                type: 'category',
                data: [],
                axisTick: {show: false},
                axisLabel: {interval: 0},
            };
            for (let i = 0; i < this.form.events.length; i++) {
                if (i > 0)
                    xAxis.data.push('');
                xAxis.data.push('');
                xAxis.data.push(this.form.events[i].name);
                xAxis.data.push('');
            }
            const dimension = 0;
            const first = this.data[dimension].layer0;
            for (let i = 0; i < this.headers.length; i++) {
                if (i > 0) {
                    data.push(null);
                    const relative = this.data[dimension]['layer' + i] / this.data[dimension]['layer' + (i - 1)] * 100;
                    lData.push({
                        value: ((this.data[dimension]['layer' + i] + this.data[dimension]['layer' + (i - 1)]) * 50 / first).toFixed(2),
                        label: {
                            show: true,
                            formatter: Math.abs(relative).toFixed(2) + '%',
                            position: relative < 100 ? 'right' : relative > 100 ? 'left' : 'top',
                        },
                        itemStyle: {opacity: 1}
                    })
                }
                data.push(null);
                data.push((this.data[dimension]['layer' + i] * 100.0 / first).toFixed(2));
                data.push(null);
                lData.push(
                    (this.data[dimension]['layer' + i] * 100.0 / first).toFixed(2),
                    (this.data[dimension]['layer' + i] * 100.0 / first).toFixed(2),
                    (this.data[dimension]['layer' + i] * 100.0 / first).toFixed(2),
                );
            }
            const option = {
                title: {text: '用户漏斗'},
                xAxis: xAxis,
                yAxis: {type: 'value'},
                series: [{
                    type: 'line',
                    data: lData,
                    areaStyle: {},
                    lineStyle: {
                        opacity: 0,
                    },
                    itemStyle: {
                        opacity: 0,
                    },
                    hoverAnimation: false,
                    symbol: 'diamond',
                    symbolSize: 1,
                }, {
                    type: 'bar',
                    gap: 2,
                    labelLine: {},
                    label: {
                        show: true,
                        formatter: '{c}%',
                        position: 'top',
                    },
                    data: data,
                    itemStyle: {
                        color: '#3ea3d8',
                    },
                    barCategoryGap: '-100%',
                }]
            };
            myChart.setOption(option);
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
                this.form.events = [
                    {name: '', items: []},
                    {name: '', items: []},
                ];
            }
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div style="display: inline-flex">
        <date-range-picker v-model="form" :disabled="loading"></date-range-picker>
        <el-select v-model="form.country" :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :key="c.code" :value="c.code"
                       :label="`${c.en_name}(${c.code})`"></el-option>
        </el-select>
        <el-select v-model="form.sku" clearable filterable :disabled="loading" placeholder="SKU">
            <el-option v-for="s in sku_list" :value="s"></el-option>
        </el-select>
        <el-button type="primary" :loading="loading" @click="init">查询</el-button>
    </div>
    <el-table :data="dataPage" v-loading="loading" style="margin-top: 10px" show-summary>
        <el-table-column label="日期" prop="date" width="120"></el-table-column>
        <el-table-column label="国家" prop="country" width="60"></el-table-column>
        <el-table-column label="周期" prop="period" width="80"></el-table-column>
        <el-table-column label="SKU" prop="sku" width="160"></el-table-column>
        <el-table-column label="退款" width="60">
            <template #default="scope">
                <span v-if="scope.row.status === 'Refund'">是</span>
            </template>
        </el-table-column>
        <el-table-column label="复购" width="60">
            <template #default="scope">
                <div v-if="scope.row.order">
                    {{ scope.row.order.substring(26) ? parseInt(scope.row.order.substring(26)) : '' }}
                </div>
            </template>
        </el-table-column>
        <el-table-column label="货币" width="60" prop="customer_currency"></el-table-column>
        <el-table-column label="价格" width="60" prop="customer_price"></el-table-column>
        <el-table-column label="付款" width="60" prop="charged_amount"></el-table-column>
        <el-table-column label="税费" width="60" prop="taxes"></el-table-column>
        <el-table-column label="收入(USD)" width="100" prop="proceed">
            <template #default="scope">
                {{ scope.row.proceed.toFixed(2) }}
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex;justify-content: space-between">
        <el-pagination v-model:current-page="page" :page-size="size" :total="total"
                       layout="prev, pager, next"></el-pagination>
        <el-select v-model="size" :disabled="loading" style="width: 120px">
            <el-option :value="20" label="每页20条">20</el-option>
            <el-option :value="50" label="每页50条">50</el-option>
            <el-option :value="100" label="每页100条">100</el-option>
        </el-select>
    </div>
</template>

<script>
import {timestampToDate} from "../../libs/utils";
import axios from 'ts-axios-new';
import DateRangePicker from "../../base/DateRangePicker";

export default {
    name: "IAPOrder",
    components: {DateRangePicker},
    data() {
        const now = Date.now()
        return {
            loading: false, data: [], total: 0, page: 1, size: 20, sku_list: [],
            form: {
                start: timestampToDate(now - 7 * 24 * 3600 * 1000),
                end: timestampToDate(now - 24 * 3600 * 1000),
                country: '',
                sku: null,
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/api/v1/iap/${this.$route.params.productId}/order`, {params: this.form}).then(res => {
                this.data = res.data.data.orderList;
                this.sku_list = [];
                this.data.forEach(d => {
                    if (!this.sku_list.includes(d.sku)) {
                        this.sku_list.push(d.sku);
                    }
                })
                this.total = this.data.length;
                this.page = 1;
                this.loading = false;
            });
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            if (this.$route.name === 'IAPOrder') {
                this.init();
            }
        },
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return !this.form.sku || this.form.sku === d.sku;
            });
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        }
    }
}
</script>

<style scoped>

</style>
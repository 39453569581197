<template>
    <el-select v-model="country" :size="size" filterable @change="update" :clearable="true" placeholder="全部国家"
               :style="{width: width||'300px'}" multiple :collapse-tags="collapseTags"
               :disabled="disabled">
        <el-option v-for="c in $root.country_list" :key="c.code" :value="c[wanted]"
                   :label="labelKey?c[labelKey]:c.en_name + ' (' + c.code + ')'">
            {{c.en_name + ' (' + c.code + ')'}}
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "CountrySelector",
    props: {
        modelValue: Array,
        size: String,
        width: String,
        collapseTags: Boolean,
        disabled: Boolean,
        wanted: {
            type: String,
            default: 'code',
        },
        labelKey: String,
    },
    data() {
        return {
            country: [], inner_update: false,
        }
    },
    methods: {
        update() {
            this.inner_update = true;
            this.$emit('update:modelValue', this.country);
            this.$emit('change');
            this.$nextTick(_ => {
                this.inner_update = false;
            });
        }
    },
    mounted() {
        this.country = this.modelValue;
    },
    watch: {
        modelValue() {
            if (!this.inner_update) {
                this.country = this.modelValue;
            }
        }
    }
}
</script>

<style scoped>

</style>

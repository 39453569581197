<template>
    <div style="padding: 0 30px" v-loading="loading">
        <p style="margin: 5px 0"><b>{{ dashboard.name }}</b></p>
        <p style="margin: 5px 0;font-size: 12px" class="color-info">
            创建人：
            <user v-model="dashboard.user_id"></user>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            更新时间：{{ timestampToDate(dashboard.u_time) }}
        </p>
        <div style="display: flex;margin-top: 10px;justify-content: space-between">
            <div v-if="show_date_range">
                <date-range-picker v-model="form" @change="dateChange" ref="date"></date-range-picker>
                <el-icon style="margin-left: 10px;cursor: pointer;" @click="closeDateRange">
                    <CloseBold/>
                </el-icon>
            </div>
            <el-button icon="Calendar" v-else @click="showDate">
                选择日期
            </el-button>
            <div>
                <el-button type="success" @click="$refs.editor.open()" v-if="!disabled">添加组件
                </el-button>
                <el-dropdown trigger="click">
                    <el-button icon="MoreFilled" style="margin-left: 10px">更多设置</el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <div @click="$refs.dashboardEditor.open(dashboard)">
                                    <el-icon>
                                        <CopyDocument/>
                                    </el-icon>
                                    复制看板
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item :disabled="disabled">
                                <div v-if="dashboard.public" @click="updateDashboard">
                                    <el-icon>
                                        <Lock/>
                                    </el-icon>
                                    转为私有
                                </div>
                                <div v-else @click="updateDashboard">
                                    <el-icon>
                                        <Unlock/>
                                    </el-icon>
                                    公开看板
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item class="color-error" :disabled="disabled">
                                <div class="color-error" @click="removeDashboard">
                                    <el-icon>
                                        <Delete/>
                                    </el-icon>
                                    删除看板
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <el-divider style="margin: 10px 0"></el-divider>
        <grid-layout :layout.sync="component_list" :is-resizable="!disabled">
            <grid-item v-for="c in component_list" :x="c.x" :y="c.y" :w="c.w" :h="c.h" :i="c.i" :min-h="c.minH"
                       :min-w="c.minW" drag-allow-from=".handle" @resized="resize(c)" @moved="update">
                <component-grid :component="c" :disabled="disabled" :metric-list="metric_list" :ref="c.id"
                                @view="$refs.editor.open(c, true)" @remove="remove(c)"
                                :date-range="date_range" @edit="$refs.editor.open(c)"></component-grid>
            </grid-item>
        </grid-layout>
    </div>
    <component-editor ref="editor" :metric-list="metric_list" @change="refresh" @submit="add"></component-editor>
    <dashboard-editor ref="dashboardEditor" @afterSave="switchNewDashboard"></dashboard-editor>
</template>

<script>
import axios from 'ts-axios-new';
import User from "../../../base/User";
import {timestampToDate} from "../../../libs/utils";
import ComponentGrid from "./ComponentGrid";
import ComponentEditor from "./ComponentEditor";
import DashboardEditor from "./DashboardEditor";
import DateRangePicker from "../../../base/DateRangePicker";

export default {
    name: "GrowthDashboard",
    components: {DateRangePicker, DashboardEditor, ComponentEditor, ComponentGrid, User},
    inject: ['growth'],
    data() {
        const now = Date.now();
        const t = 2;
        return {
            loading: false, metric_list: [], dashboard: {}, component_list: [],
            show_date_range: false, date_range: null, t,
            form: {
                start: timestampToDate(now - (t + 7) * 24 * 3600 * 1000),
                end: timestampToDate(now - t * 24 * 3600 * 1000),
            }
        }
    },
    methods: {
        showDate() {
            this.show_date_range = true;
            this.$nextTick(_ => {
                this.$refs.date.focus();
            })
        },
        dateChange() {
            this.date_range = this.form;
            this.refreshComponents();
        },
        closeDateRange() {
            const now = Date.now();
            this.date_range = null;
            this.form = {
                start: timestampToDate(now - (this.$root.t + 7) * 24 * 3600 * 1000),
                end: timestampToDate(now - this.$root.t * 24 * 3600 * 1000),
            }
            this.refreshComponents();
            this.show_date_range = false;
        },
        refreshComponents() {
            this.$nextTick(_ => {
                this.component_list.forEach(c => {
                    this.$refs[c.id][0].init();
                });
            })
        },
        switchNewDashboard(newBoard) {
            this.growth.data.push(newBoard);
            this.$router.push({name: 'PMGrowthDashboard', query: {dashboardId: newBoard.id}});
        },
        resize(component) {
            this.$nextTick(_ => {
                this.$refs[component.id].resize();
                this.update();
            })
        },
        update() {
            this.dashboard.layout = {};
            this.component_list.forEach(c => {
                this.dashboard.layout[c.id] = {
                    x: c.x,
                    y: c.y,
                    w: c.w,
                    h: c.h
                }
            });
            axios.put(`/api/v1/growth/dashboard/${this.$route.query.dashboardId}`, this.dashboard).then(res => {
            })
        },
        refresh(c) {
            if (c.category !== 'number') {
                if (c.w < 6) {
                    c.w = 6;
                }
                if (c.h < 2) {
                    c.h = 2;
                }
                this.update();
            }
            this.$refs[c.id].init();
            c.minW = c.category === 'number' ? 3 : 6;
            c.minH = c.category === 'number' ? 1 : 2;
        },
        add(c) {
            const position = this.getLastPosition();
            c.x = position.x;
            c.y = position.y;
            c.i = c.id;
            if (c.category === 'number') {
                c.w = 3;
                c.h = 1;
            } else {
                c.w = 6;
                c.h = 2;
            }
            if (c.x + c.w > 12) {
                c.x = 0;
                c.y++;
            }
            c.minW = c.category === 'number' ? 3 : 6;
            c.minH = c.category === 'number' ? 1 : 2;
            this.component_list.push(c);
        },
        getLastPosition() {
            let y = 0;
            let x = 0;
            let last = null;
            this.component_list.forEach(c => {
                if (c.y > y) {
                    y = c.y;
                    x = c.x + c.w;
                    last = c;
                } else if (c.y === y && c.x + c.w > x) {
                    x = c.x + c.w;
                    last = c;
                }
            });
            if (x === 12) {
                y += last.h;
                x = 0;
            }
            return {x, y};
        },
        updateDashboard() {
            this.loading = true;
            this.dashboard.public = !this.dashboard.public;
            axios.put(`/api/v1/growth/dashboard/${this.$route.query.dashboardId}`, this.dashboard).then(res => {
                this.loading = false;
            })
        },
        init() {
            this.component_list = [];
            this.loading = true;
            axios.get(`/api/v1/metric`, {
                params: {
                    contain_preset: 1,
                    product_id: this.$route.params.productId,
                }
            }).then(res => {
                this.metric_list = res.data.data.models;
                axios.get(`/api/v1/growth/dashboard/${this.$route.query.dashboardId}`).then(res => {
                    this.dashboard = res.data.data;
                    const config = res.data.data.layout;
                    if (config) {
                        res.data.data.component_list.forEach(c => {
                            const component_config = config[c.id];
                            if (component_config) {
                                c.x = component_config.x;
                                c.y = component_config.y;
                                c.w = component_config.w;
                                c.h = component_config.h;
                            } else {
                                const p = this.getLastPosition();
                                c.x = p.x;
                                c.y = p.y;
                                if (c.category === 'number') {
                                    c.w = 3;
                                    c.h = 1;
                                } else {
                                    c.w = 6;
                                    c.h = 2;
                                }
                            }
                            c.minW = c.category === 'number' ? 3 : 6;
                            c.minH = c.category === 'number' ? 1 : 2;
                            this.component_list.push(c);
                        });
                    } else {
                        let x = 0, y = 0;
                        res.data.data.component_list.filter(f => f.category === 'number').forEach(c => {
                            c.x = x;
                            c.y = y;
                            c.w = 3;
                            c.h = 1;
                            x += c.w;
                            if (x === 12) {
                                x = 0;
                                y += 1;
                            }
                            c.minW = 3;
                            c.minH = 1;
                            this.component_list.push(c);
                        });
                        if (x > 0) {
                            x = 0;
                            y += 1;
                        }
                        res.data.data.component_list.filter(f => f.category !== 'number').forEach(c => {
                            c.x = x;
                            c.y = y;
                            c.w = 6;
                            c.h = 2;
                            x += c.w;
                            if (x === 12) {
                                x = 0;
                                y += 2;
                            }
                            c.minW = 6;
                            c.minH = 2;
                            this.component_list.push(c);
                        });
                    }
                    // 巨大的坑，必须有i
                    this.component_list.forEach(c => {
                        c.i = c.id;
                    });
                    document.title = `${this.$root.product.nickname}(${this.$root.product.platform}) ${(this.dashboard.name || '自定义看板')}`;
                    this.loading = false;
                });
            })
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp);
        },
        remove(item) {
            this.$confirm('确定要删除该组件吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/api/v1/growth/component/${item.id}`).then(_ => {
                    // this.component_list.splice(this.component_list.indexOf(item), 1);
                    // this.loading = false;
                    this.init();
                });
            }).catch(_ => {
            });
        },
        removeDashboard() {
            this.$confirm('确定要删除该看板吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/api/v1/growth/dashboard/${this.$route.query.dashboardId}`).then(res => {
                    let target = -1;
                    this.growth.data.forEach((d, i) => {
                        if (d.id === this.$route.query.dashboardId) {
                            target = i;
                        }
                    });
                    this.growth.data.splice(target, 1);
                    if (this.growth.data.length) {
                        this.$router.push({query: {dashboardId: this.growth.data[0].id}});
                    } else {
                        this.$router.push({name: 'PMDashboard'})
                    }
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.dashboardId"() {
            if (this.$route.query.dashboardId) {
                this.closeDateRange();
                this.init();
            }
        },
    },
    computed: {
        disabled() {
            return this.dashboard.user_id !== this.$root.user.user_id;
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <template v-if="component.category === 'number'">
        <div style="height: calc(100% - 49px);text-align: center;" ref="value"
             :style="{lineHeight: component.h * 150 - 91 + 'px'}">
            <b style="font-size: 2.5em;line-height: .5em" v-if="value !== null">
                {{
                    metric.category === 'duration' ? formatDuration(value) : metric.metric_type === 'percent' && metric.category.indexOf('retain') < 0 ? (value * 100).toFixed(2) : formatInt(value)
                }}<span
                v-if="value && metric.metric_type === 'percent'">%</span>
            </b>
            <b class="color-info" style="font-size: 2.5em" v-else> 无数据</b>
        </div>
        <div style="height: 32px">
            <p style="margin: 0; font-size: 12px;" class="color-info">
                <span style="vertical-align: middle;line-height: 32px">日环比</span>
                <b style="font-size: 18px;vertical-align: middle;line-height: 32px;margin-left: 10px">
                    <span class="color-success" v-if="dod > 0"><Top/> {{ dod.toFixed(2) }}%</span>
                    <span class="color-error" v-else-if="dod < 0"><Bottom/> {{ dod.toFixed(2) }}%</span>
                    <span class="color-info" v-else><Minus/> </span>
                </b>
                <b style="float: right;vertical-align: middle;font-size: 18px;line-height: 32px">
                    <span class="color-success" v-if="wow > 0"><Top/> {{ wow.toFixed(2) }}%</span>
                    <span class="color-error" v-else-if="wow < 0"><Bottom/> {{ wow.toFixed(2) }}%</span>
                    <span class="color-info" v-else><Minus/> </span>
                </b>
                <span
                    style="float: right;vertical-align: middle;line-height: 32px;margin-right: 10px">周同比</span>
            </p>
        </div>
    </template>
    <template v-else>
        <div style="height: 48px" v-if="!component.groupings.length">
            <p style="margin: 0; font-size: 12px" class="color-info">
                <span style="vertical-align: middle;line-height: 48px">{{ value_date }}</span>
                <b style="font-size: 32px;line-height: 48px;margin-left: 10px;color: var(--el-text-color-primary)"
                   v-if="value !== null">{{
                        metric.category === 'duration' ? formatDuration(value) : metric.metric_type === 'percent' && metric.category.indexOf('retain') < 0 ? (value * 100).toFixed(2) : formatInt(value)
                    }}
                    <span v-if="value && metric.metric_type === 'percent'">%</span>
                </b>
                <b class="color-info" style="font-size: 2.5em" v-else> 无数据</b>
                <span style="vertical-align: middle;line-height: 48px;margin-left: 20px">日环比</span>
                <b style="font-size: 18px;vertical-align: middle;line-height: 48px;margin-left: 10px">
                    <span class="color-success" v-if="dod > 0">
                        <el-icon><Top/></el-icon> {{ dod.toFixed(2) }}%</span>
                    <span class="color-error" v-else-if="dod < 0">
                        <el-icon><Bottom/></el-icon> {{ dod.toFixed(2) }}%</span>
                    <span class="color-info" v-else><el-icon><Minus/></el-icon> </span>
                </b>
                <span style="vertical-align: middle;line-height: 48px;margin-left: 20px">周同比</span>
                <b style="vertical-align: middle;font-size: 18px;line-height: 48px;margin-left: 10px">
                    <span class="color-success" v-if="wow > 0">
                        <el-icon><Top/></el-icon> {{ wow.toFixed(2) }}%</span>
                    <span class="color-error" v-else-if="wow < 0">
                        <el-icon><Bottom/></el-icon> {{ wow.toFixed(2) }}%</span>
                    <span class="color-info" v-else><el-icon><Minus/></el-icon> </span>
                </b>
            </p>
        </div>
        <div :style="{height: `calc(100% - ${!component.groupings.length ? '65px': '17px'})`}"
             ref="chart"></div>
    </template>
</template>

<script>
import {formatInt, formatDuration} from "../../../libs/utils";
import {shallowRef} from 'vue'

export default {
    name: "ComponentChart",
    props: {
        component: Object,
        metric: Object,
        value: Number,
        value_date: String,
        dod: Number,
        wow: Number,
    },
    data() {
        return {
            chart: null,
        }
    },
    methods: {
        formatDuration(duration) {
            return formatDuration(duration)
        },
        formatInt(value) {
            return formatInt(value);
        },
        resize() {
            if (this.chart) {
                this.chart.value.resize();
            }
        },
        clear() {
            if (this.chart) {
                this.chart.value.clear();
            }
        },
        draw(x, series) {
            if (this.metric.metric_type === 'percent' && this.metric.category.indexOf('retain') < 0) {
                series.forEach(s => {
                    s.data.forEach(d => {
                        d[1] = parseFloat((d[1] * 100).toFixed(2))
                    })
                });
            }
            if (series.length === 1) {
                const scatter = [];
                series[0].data.forEach((d, i) => {
                    scatter[i] = [i + 1, d[1]];
                });
                const regression = ecStat.regression('polynomial', scatter, 1);
                const fit = [];
                regression.points.forEach(p => {
                    fit.push(p[1]);
                })
                series.push({
                    name: '趋势线',
                    type: 'line',
                    showSymbol: false,
                    lineStyle: {type: 'dashed'},
                    data: fit,
                });
            }
            this.chart = shallowRef({
                value: echarts.init(this.$refs.chart, this.$root.dark ? 'dark' : '')
            });
            this.chart.value.setOption({
                grid: {
                    top: this.component.groupings.length ? 40 : 20,
                    bottom: 30,
                    right: 30,
                    left: 50
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        axis: 'x',
                        type: "shadow",
                    },
                    formatter: params => {
                        let ret = params[0].name + '<br>';
                        const series = [];
                        params.forEach(p => {
                            if (p.seriesName !== '趋势线')
                                series.push(p.marker + p.seriesName + '&nbsp;&nbsp;' + (this.metric.category === 'duration' ? formatDuration(p.data[1]) : formatInt(p.data[1])));
                        });
                        return ret + series.join('<br/>')
                    }
                },
                xAxis: {
                    type: 'category',
                    data: x,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#7d7b7b',
                        }
                    }
                },
                legend: {
                    show: this.component.groupings.length > 0,
                },
                yAxis: {
                    type: 'value',
                    min: function (value) {
                        return value.min * 1.5 - value.max * 0.5 < 0 ? 0 : Math.floor(value.min * 1.5 - value.max * 0.5);
                    },
                    max: function (value) {
                        return Math.ceil(value.max * 1.5 - value.min * 0.5);
                    },
                    axisLabel: {
                        formatter: value => {
                            if (this.metric.category === 'duration') {
                                return formatDuration(value);
                            } else {
                                if (value >= 10 ** 9) {
                                    return (value / 10 ** 9).toFixed(1) + 'b';
                                } else if (value >= 10 ** 6) {
                                    return (value / 10 ** 6).toFixed(1) + 'm';
                                } else if (value >= 10 ** 3) {
                                    return (value / 10 ** 3).toFixed(1) + 'k';
                                } else {
                                    return value
                                }
                            }
                        }
                    }
                },
                series: series,
            }, true);
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <el-select v-model="media_source" :size="size" filterable @change="update" :clearable="true" placeholder="全部渠道"
               :style="{width: width||'300px'}" multiple :collapse-tags="collapseTags" :disabled="disabled">
        <el-option v-for="m in $root.media_source_list" :value="m">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "MediaSourceSelector",
    props: {
        modelValue: Array,
        size: String,
        width: String,
        collapseTags: Boolean,
        disabled: Boolean,
    },
    data() {
        return {
            media_source: [], inner_update: false,
        }
    },
    methods: {
        update() {
            this.inner_update = true;
            this.$emit('update:modelValue', this.media_source);
            this.$emit('change');
            this.$nextTick(_ => {
                this.inner_update = false;
            });
        }
    },
    mounted() {
        this.media_source = this.modelValue;
    },
    watch: {
        modelValue() {
            if (!this.inner_update) {
                this.country = this.modelValue;
            }
        }
    }
}
</script>

<style scoped>

</style>

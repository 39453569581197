<template>
    <el-date-picker :clearable="false" @change="update" type="daterange" v-model="date_range"
                    :size="size" :disabled="disabled" :editable="false" :shortcuts="shortcuts"
                    :disabled-date="innerDisabledDate" ref="picker"
                    style="height: 32px;width:300px;background-color: var(--el-input-bg-color,var(--el-fill-color-blank))"/>
</template>

<script>
import {timestampToDate} from "../libs/utils";

export default {
    name: "DateRangePicker",
    props: {
        modelValue: Object,
        size: String,
        disabled: Boolean,
        disabledDate: Function,
    },
    data() {
        return {
            date_range: [], shortcuts: [{
                text: '最近7天',
                value: (_ => {
                    const now = Date.now();
                    return [
                        now - 8 * 24 * 3600 * 1000,
                        now - (this.$root.t || 2) * 24 * 3600 * 1000
                    ]
                })()
            }, {
                text: '最近30天',
                value: (_ => {
                    const now = Date.now();
                    return [
                        now - 31 * 24 * 3600 * 1000,
                        now - (this.$root.t || 2) * 24 * 3600 * 1000
                    ]
                })()
            }, {
                text: '上个月',
                value: (_ => {
                    const now = new Date();
                    let year = now.getFullYear();
                    let month = now.getMonth();
                    const end = new Date(`${year}-${month + 1}-01`) - 24 * 3600 * 1000;
                    if (!month) {
                        month = 12;
                        year--;
                    }
                    const start = new Date(`${year}-${month}-01`)
                    return [start, end]
                })()
            }], inner_update: false,
        }
    },
    methods: {
        focus() {
            this.$refs.picker.focus();
        },
        init() {
            this.date_range = [new Date(this.modelValue.start), new Date(this.modelValue.end)];
        },
        update() {
            this.inner_update = true;
            this.modelValue.start = timestampToDate(this.date_range[0]);
            this.modelValue.end = timestampToDate(this.date_range[1]);
            // this.$emit('change');
            this.$nextTick(_ => {
                this.inner_update = false;
            })
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "modelValue.start"() {
            if (!this.inner_update)
                this.init();
        },
        "modelValue.end"() {
            if (!this.inner_update)
                this.init();
        },
    },
    computed: {
        innerDisabledDate() {
            if (this.disabledDate) {
                return this.disabledDate
            } else
                return time => {
                    return time.getTime() + 8 * 3600 * 1000 > Date.now() || time.getTime() < 1630425600000;
                }
        }
    }
}
</script>

<style scoped>
</style>

<template>
    <div style="padding: 0 30px">
        <cohort-form :loading="loading" ref="cohort" @submit="init" dimensions></cohort-form>
        <div style="display: flex;justify-content: flex-end">
            <sql-runtime ref="timer" :stat="timer_stat"></sql-runtime>
        </div>
        <el-card shadow="never">
            <div id="chart" style="height: 400px;width: 100%"></div>
        </el-card>
    </div>
</template>

<script>
import CohortForm from "../../base/CohortForm";
import SqlRuntime from "../../base/SqlRuntime";
import axios from 'ts-axios-new';
import {deepcopy} from "../../libs/utils";

export default {
    name: "ActiveDistribute",
    components: {CohortForm, SqlRuntime},
    data() {
        return {
            loading: false,
            timer_stat: {
                total_bytes_processed: 0, duration: 0, message: null, sql: ''
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            const form = this.$refs.cohort.getData();
            this.$refs.timer.start();
            axios.post(`/api/v1/pm/${this.$route.params.productId}/active/distribute`, form).then(res => {
                this.$refs.timer.success();
                this.loading = false;
                const x = [], dims = {}, series = [];
                for (let i = 0; i < 180; i++) {
                    x.push(i);
                }
                const dimensions = deepcopy(form.dimensions);
                res.data.data.data.forEach(d => {
                    let key = '';
                    dimensions.forEach(dim => {
                        key += d[dim] + ' ';
                    });
                    if (!dims[key]) {
                        dims[key] = {type: 'line', raw_data: [], name: key, total: 0, data: []}
                    }
                    dims[key].raw_data.push([d.living_days, d.user]);
                    dims[key].total += d.user;
                });
                for (let key in dims) {
                    dims[key].raw_data.sort(function (a, b) {
                        return a[0] - b[0];
                    });
                    dims[key].raw_data.forEach(d => {
                        dims[key].data.push([d[0], parseFloat((d[1] / dims[key].total * 100).toFixed(2))])
                    })
                    series.push(dims[key])
                }
                echarts.init(document.getElementById('chart')).setOption({
                    xAxis: {
                        type: 'category',
                        data: x,
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series,
                    tooltip: {},
                    legend: {},
                    title: {
                        text: '日活分布'
                    }
                })
            }).catch(err => {
                this.loading = false;
                this.$refs.timer.error(err.response.data.status.message);
            });
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>
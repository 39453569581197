<template>
    <div class="aside-group" :class="disabled?'disabled':''">
        <div style="padding: 16px 0 8px 24px" v-if="!collapsed">
            <b>{{title}}</b>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import {computed} from 'vue';

export default {
    name: "AsideGroup",
    props: {
        title: String,
        disabled: Boolean,
    },
    inject: ['collapsed'],
    provide() {
        return {parentDisabled: computed(_ =>this.disabled)}
    },
}
</script>

<style scoped>
.aside-group {
    color: var(--el-text-color-regular);
    font-size: 12px;
}

.aside-group.disabled {
    cursor: not-allowed;
    color: var(--el-text-color-placeholder);
}
</style>
<template>
    <div style="padding: 0 30px">
        <p style="font-size: 12px;margin: 0" class="color-info">
            <span>发版时间：{{timestampToDatetime(version.c_time)}}</span>
            <user v-model="version.user_id"></user>
        </p>
        <el-tabs v-model="tab_panel">
            <el-tab-pane label="版本记录" name="setting">
                <VersionDetailPanel v-model="version" :loading="loading" :metric_list="metricList"></VersionDetailPanel>
            </el-tab-pane>
            <el-tab-pane label="版本监控" name="monitor">
                <VersionTrendPanel v-model="version" :metric_list="metricList"
                                   v-if="tab_panel === 'monitor'"></VersionTrendPanel>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import VersionTrendPanel from "./VersionTrendPanel";
import VersionDetailPanel from './VersionDetailPanel';
import {timestampToDate, timestampToDatetime} from "../../../libs/utils";
import axios from 'ts-axios-new';
import User from "../../../base/User";

export default {
    name: "VersionPanel",
    components: {User, VersionTrendPanel, VersionDetailPanel},
    data() {
        return {
            loading: false, note_list: [], tab_panel: 'setting', firstEdit: true, version: {},
            metricList: [],
        }
    },
    methods: {
        init_metrics() {
            let metric_ids = [];
            this.metricList.forEach(m => {
                if (['日活', '平均时长', '次留'].includes(m.name)) {
                    metric_ids.push(m.id);
                }
            });
            axios.put('/api/v1/pm/version/' + this.$route.params.versionId, {metrics: metric_ids}).then(resp => {
                this.version.metrics = resp.data.data.metrics;
            }).catch(err => {
                this.$message.error(err.response.data.status.message);
            })
        },
        init() {
            this.loading = true;
            let params = {
                contain_preset: 1,
                product_id: this.$route.params.productId,
            };
            axios.all([
                axios.get(`/api/v1/pm/version/${this.$route.params.versionId}`).then(res => {
                    this.version = res.data.data;
                }),
                axios.get('/api/v1/metric', {withCredentials: true, params: params}).then(resp => {
                    let metricList = [];
                    const start = timestampToDate(this.version.c_time);
                    resp.data.data.models.forEach(m => {
                        if (m.effective_date && m.effective_date <= start) {
                            metricList.push(m);
                        }
                    });
                    this.metricList = metricList;
                })
            ]).then(_ => {
                this.loading = false;
                this.firstEdit = !this.version.content;
                if (this.version.metrics.length === 0) {
                    this.init_metrics();
                }
            }).catch(err => {
                this.loading = false;
            });
        },
        timestampToDatetime(timestamp) {
            return timestampToDatetime(timestamp);
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>
.compact ::v-deep(td) {
    padding: 2px 0;
    font-size: 12px;
}

.compact ::v-deep(th) {
    padding: 2px 0;
    background-color: #EBEEF5;
}
</style>

<template>
    <el-dialog v-model="dialog_opened" :title="editing?'复制看板': '新增看板'">
        <el-form :model="form" ref="form">
            <el-form-item label="看板名称" prop="name" :rules="[{required: true, message: '请输入看板名称'}]">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item prop="public">
                <el-radio-group v-model="form.public">
                    <el-radio :label="false">私有</el-radio>
                    <el-radio :label="true">公开</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text @click="reset" :loading="loading">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../../libs/utils";

export default {
    name: "DashboardEditor",
    emits: ["submit"],
    data() {
        return {
            loading: false, dialog_opened: false, editing: null,
            form: {
                product_id: this.$route.params.productId,
                name: '',
                public: false,
            }
        }
    },
    methods: {
        open(item) {
            this.dialog_opened = true;
            if (item) {
                this.editing = item;
                this.$nextTick(_ => {
                    update(this.form, item);
                })
            }
        },
        reset() {
            this.$refs.form.resetFields();
            this.editing = null;
            this.dialog_opened = this.loading = false;

        },
        submit() {
            this.loading = true;
            this.form.product_id = this.$route.params.productId;
            if (this.editing) {
                axios.post(`/api/v1/growth/dashboard/(\w+)/copy`, this.form).then(res => {
                    this.reset();
                    this.$emit('submit', res.data.data);
                });
            } else {
                axios.post(`/api/v1/growth/dashboard`, this.form).then(res => {
                    this.reset();
                    this.$emit('submit', res.data.data);
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
<template>
    <el-container style="height: 100%">
        <el-main style="padding: 0">
            <el-scrollbar>
                <el-tabs v-model="tab">
                    <el-tab-pane name="current" label="当前瀑布流">
                        <template v-if="active_unit">
                            <div style="font-size: 10px;color: var(--el-text-color-placeholder)">
                                最近更新时间：{{ (new Date(active_unit.update + ' UTC')).toLocaleString() }}
                            </div>
                            <div style="margin-top: 5px">
                                <el-select v-model="query.country" filterable :disabled="loading"
                                           clearable placeholder="全部国家">
                                    <el-option v-for="c in $root.country_list" :value="c.code.toLowerCase()"
                                               :label="`${c.en_name}(${c.code})`" :key="c.code"></el-option>
                                </el-select>
                                <el-select v-model="query.network" filterable :disabled="loading" clearable
                                           placeholder="全部平台">
                                    <el-option v-for="n in network_list" :value="n"></el-option>
                                </el-select>
                                <el-switch v-model="query.all" :inactive-value="true" :active-value="false"
                                           active-text="仅显示可用"></el-switch>
                                <b style="line-height: 32px;font-size: 12px;margin-left: 10px;color: var(--el-text-color-secondary)">
                                    共{{ dataFilter.length }}层</b>
                            </div>
                            <el-table :data="dataFilter" v-loading="loading">
                                <el-table-column label="Network" width="250" prop="network"></el-table-column>
                                <el-table-column label="ID" width="350" prop="ad_network_ad_unit_id"></el-table-column>
                                <el-table-column label="可用" width="60">
                                    <template #default="scope">
                                        <el-icon class="color-error" v-if="scope.row.disabled">
                                            <CloseBold/>
                                        </el-icon>
                                        <el-icon class="color-success" v-else>
                                            <Select/>
                                        </el-icon>
                                    </template>
                                </el-table-column>
                                <el-table-column label="CPM" width="80" prop="cpm"></el-table-column>
                                <el-table-column label="填充率" width="80"></el-table-column>
                                <el-table-column label="触达率" width="80"></el-table-column>
                                <el-table-column label="国家" width="150" show-overflow-tooltip>
                                    <template #default="scope">
                                        <span v-if="scope.row.countries"
                                              :class="scope.row.countries.type === 'EXCLUDE' ? 'color-error':''">
                                            {{ scope.row.countries.values.join(',').toUpperCase() }}
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane name="schedule" label="定时计划">
                        <h3>Admob配置</h3>
                        <el-button type="primary" :loading="loading" @click="openDialog()">新建计划</el-button>
                        <el-table :data="schedule_list" v-loading="loading" style="margin-top: 10px">
                            <el-table-column label="计划名称" prop="name" width="100"></el-table-column>
                            <el-table-column label="活跃" prop="active" width="100">
                                <template #default="scope">
                                    <el-switch v-model="scope.row.active" :disabled="loading"
                                               @click="update(scope.row)"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="国家" prop="countries.values" width="100" show-overflow-tooltip>
                                <template #default="scope">
                                        <span v-if="scope.row.countries"
                                              :class="scope.row.countries.type === 'EXCLUDE' ? 'color-error':''">
                                            {{ scope.row.countries.values.join(',') }}
                                        </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="层数" prop="ad_units" width="100">
                                <template #default="scope">{{ scope.row.ad_units.length }}</template>
                            </el-table-column>
                            <el-table-column label="周期" prop="interval_day" width="100"></el-table-column>
                            <el-table-column label="上次成功" prop="last_success" width="180"></el-table-column>
                            <el-table-column label="操作" width="200">
                                <template #default="scope">
                                    <el-button size="small" type="success" :disabled="loading"
                                               @click="openDialog(scope.row)">编辑
                                    </el-button>
                                    <el-button size="small" type="danger" :disabled="loading"
                                               @click="remove(scope.row)">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-scrollbar>
        </el-main>
        <el-aside>
            <el-scrollbar>
                <h3 style="margin: 0;padding: 10px">Max广告id</h3>
                <div class="unit-list">
                    <div class="unit" :title="`${d.name} (${d.ad_format})`" v-for="d in data"
                         @click="d.disabled?'':active_unit=d"
                         :class="active_unit && active_unit.id === d.id?'active': d.disabled?'disabled': ''">
                        {{ d.name }} ({{ d.ad_format }})
                    </div>
                </div>
            </el-scrollbar>
        </el-aside>
    </el-container>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100">
            <el-form-item label="Max广告">
                <el-input disabled v-model="active_unit.name"></el-input>
            </el-form-item>
            <el-form-item label="广告格式">
                <el-input disabled v-model="active_unit.ad_format"></el-input>
            </el-form-item>
            <el-form-item label="计划名称" prop="name" :rules="[{required: true, message: '请输入计划名称'}]">
                <el-input :disabled="loading" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item prop="interval_day" label="周期天数">
                <el-input-number v-model="form.interval_day" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="国家" prop="countries.values">
                <el-select v-model="form.countries.type" :disabled="loading">
                    <el-option value="INCLUDE" label="包含"></el-option>
                    <el-option value="EXCLUDE" label="排除"></el-option>
                </el-select>
                <country-selector v-model="form.countries.values" :disabled="loading"></country-selector>
            </el-form-item>
            <el-form-item label="广告单元" prop="ad_units">
                <template v-for="(u, i) in form.ad_units">
                    <el-form-item label-width="0" :prop="`ad_units.${i}`"
                                  :rules="[{required: true, message: '请选择app'}, {validator, trigger: 'blur'}]">
                        <el-select v-model="u.app_id" :disabled="loading" placeholder="请选择app" filterable>
                            <el-option v-for="app in app_list" :value="app.app_id"
                                       :label="`ca-app-pub-${app.account}~${app.app_id}`"></el-option>
                        </el-select>
                        <el-select v-model="u.format" :disabled="loading" placeholder="广告格式" filterable>
                            <el-option value="banner" label="横幅广告"
                                       :disabled="active_unit.ad_format !== 'BANNER'"></el-option>
                            <el-option value="native" label="原生广告"
                                       :disabled="active_unit.ad_format !== 'BANNER'"></el-option>
                            <el-option value="interstitial" label="插屏广告"
                                       :disabled="active_unit.ad_format !== 'INTER'"></el-option>
                            <el-option value="reward" label="激励广告"
                                       :disabled="active_unit.ad_format !== 'REWARD'"></el-option>
                        </el-select>
                        <el-input-number v-model="u.price" :disabled="loading"></el-input-number>
                        <el-icon style="margin: 8px;cursor: pointer;" class="color-error" @click="removeUnit(i)">
                            <Delete/>
                        </el-icon>
                    </el-form-item>
                </template>
                <div>
                    <el-button type="success" :disabled="loading" @click="addUnit">新增一层</el-button>
                </div>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button text type="primary" :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from 'ts-axios-new';
import {update} from "../../libs/utils";
import CountrySelector from "../../base/CountrySelector";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Waterfall",
    components: {CountrySelector},
    data() {
        return {
            loading: false, data: [], active_unit: null, tab: 'schedule', network_list: [], waterfall: [],
            schedule_list: [], editing: null, dialog_opened: false, app_list: [],
            query: {
                country: 'us',
                network: null,
                all: false,
            },
            form: {
                name: '',
                interval_day: 2,
                active: false,
                countries: {type: 'INCLUDE', values: []},
                ad_units: [],
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/api/v1/iaa/${this.$route.params.productId}/mediation/max/unit`).then(res => {
                    this.data = res.data.data.waterfall;
                    this.active_unit = null;
                    this.data.forEach(d => {
                        if (!this.active_unit && !d.disabled) {
                            this.active_unit = d;
                        }
                    })
                }),
                axios.get(`/api/v1/admob/app`, {params: {product_id: this.$route.params.productId}}).then(res => {
                    this.app_list = res.data.data.appList;
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/api/v1/admob/max/${this.active_unit.id}/schedule/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/api/v1/admob/max/${this.active_unit.id}/schedule`, this.form).then(res => {
                            this.schedule_list.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        addUnit() {
            this.form.ad_units.push({app_id: '', price: 0, format: ''})
        },
        removeUnit(i) {
            this.form.ad_units.splice(i, 1);
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.ad_units = [];
            this.form.countries.values = [];
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        validator(rule, value, callback) {
            if (!value.app_id) {
                callback('请选择app!')
            } else {
                let valid = true;
                this.form.ad_units.forEach(u => {
                    if (u !== value && u.price === value.price || u.price <= 0) {
                        valid = false;
                    }
                });
                if (valid) {
                    callback();
                } else {
                    callback('底价需要大于0且不能重复！');
                }
            }
        },
        update(row) {
            this.loading = true;
            axios.put(`/api/v1/admob/max/${this.active_unit.id}/schedule/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning'
            }).then(_ => {
                this.loading = true;
                axios.delete(`/api/v1/admob/max/${this.active_unit.id}/schedule/${row.id}`).then(res => {
                    this.schedule_list.splice(this.schedule_list.indexOf(row), 1);
                    this.loading = false;
                });
            }).catch(_ => {
            });
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
            }
        },
        active_unit(unit) {
            // this.tab = 'current';
            this.network_list = [];
            this.waterfall = [];
            if (unit) {
                unit.ad_network_settings.forEach(n => {
                    for (let key in n) {
                        this.network_list.push(key);
                        n[key].ad_network_ad_units.forEach(u => {
                            u.network = key;
                            this.waterfall.push(u);
                        })
                    }
                });
                this.loading = true;
                axios.get(`/api/v1/admob/max/${unit.id}/schedule`).then(res => {
                    this.schedule_list = res.data.data.scheduleList;
                    this.loading = false;
                });
            } else {
                this.form.unit_id = null;
                this.form.active = false;
            }
        },
    },
    computed: {
        dataFilter() {
            return this.waterfall.filter(w => {
                return (!this.query.country || !w.countries || !w.countries.values.length || w.countries.type === 'INCLUDE' && w.countries.values.includes(this.query.country) || w.countries === 'EXCLUDE' && !w.countries.values.inclues(this.query.country))
                    && (!this.query.network || w.network === this.query.network)
                    && (this.query.all || !w.disabled);
            }).sort(function (a, b) {
                return parseFloat(b.cpm || 0) - parseFloat(a.cpm || 0)
            })
        }
    },
}
</script>

<style scoped>
.unit-list {
    color: var(--el-text-color-regular);
    font-size: 16px;
    background-color: var(--el-bg-color);
}

.unit-list .unit {
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border: 1px solid var(--el-border-color-light);
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.unit-list .unit.active {
    border-left: 3px solid var(--el-color-success);
}

.unit-list .unit.disabled {
    cursor: not-allowed;
    color: var(--el-text-color-placeholder);
}

.unit-list .unit + .unit {
    border-top: 0;
}

h3 {
    color: var(--el-text-color-regular);
}

.el-tabs ::v-deep(.el-tabs__header) {
    margin-bottom: 5px;
}

.el-form-item .el-form-item {
    margin-bottom: 18px;
}

.el-form-item ::v-deep(.el-form-item__content) {
    display: block;
}
</style>